import { LaunchGameRequest, LaunchGameResponse, ListGameResponse } from "src/types";
import { useAxiosInterceptors } from "./apiConfig";

const useGameService = () => {
  const { axiosInstance } = useAxiosInterceptors();

  // Get a list of all games
  const getGames = async (): Promise<ListGameResponse> => {
    try {
      const response = await axiosInstance.get("/list_games");
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Starts a game
  const launchGame = async (gameData: LaunchGameRequest): Promise<LaunchGameResponse> => {
    try {
      const response = await axiosInstance.post("/launch_game", gameData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  return { getGames, launchGame };
};

export default useGameService;

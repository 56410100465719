import FormAddCard from "./account/paymentMethods/formAddCard/FormAddCard.component";
import AccountProfileStats from "./account/profile/accountProfileStats/AccountProfileStats.component";
import FormEditProfile from "./account/profile/formEditProfile/FormEditProfile.component";
import TransactionModal from "./account/profile/transactionModal/TransactionModal.component";
import FAQ from "./faqSection/FAQ.component";
import Filter from "./filter/Filter.component";
import Footer from "./footer/Footer.component";
import FormRegistrationStep1, {
  FormRegistrationStep1Props,
} from "./formRegistration/formRegistrationStep1/FormRegistrationStep1.component";
import FormRegistrationStep2, {
  FormRegistrationStep2Props,
} from "./formRegistration/formRegistrationStep2/FormRegistrationStep2.component";
import FormRegistrationStep3, {
  FormRegistrationStep3Props,
} from "./formRegistration/formRegistrationStep3/FormRegistrationStep3.component";
import FormRegistrationStep3Part2, {
  FormRegistrationStep3Part2Props,
} from "./formRegistration/formRegistrationStep3Part2/FormRegistrationStep3Part2.component";
import GameList, { GameListProps } from "./gameList/GameList.component";
import Header from "./header/Header.component";
import LoginHeader, {
  LoginHeaderProps,
} from "./loginHeader/LoginHeader.component";
import MobileMenu, { MobileMenuProps } from "./mobileMenu/MobileMenu.component";
import Slider, { SliderProps } from "./slider/Slider.component";
import SplideSlider, {
  SplideSliderProps,
} from "./slider/splide-slider/SplideSlider.component";
import ProfileSlider, {
  ProfileSliderProps,
} from "./slider/splide-slider/ProfileSlider.component";
import { WalletTables } from "./account/wallet/walletTables/WalletTables.component";
import ChangePasswordModal from "./account/profile/changePasswordModal/ChangePasswordModal.component";

export {
  AccountProfileStats,
  FAQ,
  Filter,
  Footer,
  FormAddCard,
  FormEditProfile,
  FormRegistrationStep1,
  FormRegistrationStep2,
  FormRegistrationStep3,
  FormRegistrationStep3Part2,
  GameList,
  Header,
  LoginHeader,
  MobileMenu,
  TransactionModal,
  Slider,
  SplideSlider,
  ProfileSlider,
  WalletTables,
  ChangePasswordModal,
};

export type {
  FormRegistrationStep1Props,
  FormRegistrationStep2Props,
  FormRegistrationStep3Part2Props,
  FormRegistrationStep3Props,
  GameListProps,
  LoginHeaderProps,
  MobileMenuProps,
  SliderProps,
  SplideSliderProps,
  ProfileSliderProps,
};

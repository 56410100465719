import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WalletTable } from "src/components/molecules";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "src/services/notificationsService";
import useTransactionService from "src/services/transactionService";

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export const WalletTables = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTable, setSelectedTable] = useState<string>(
    t("table_transactions")
  );
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { playerData } = useAuth();
  const { getTransactions } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();

  const tables = [
    t("table_transactions"),
    t("table_deposits"),
    t("table_withdrawals"),
    t("table_wins"),
    t("table_wagers"),
  ];

  const handleSelectedTableClick = (table: string) => {
    if (selectedTable !== table) {
      setSelectedTable(table);
    }
  };

  useEffect(() => {
    const getUserTransactions = async () => {
      if (!playerData?.id) return;
      try {
        setLoading(true);
        const { result } = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification(t("error_get-transactions"));
        console.error("Error getting transactions: ", error);
      } finally {
        setLoading(false);
      }
    };
    getUserTransactions();
  }, []);

  return loading ? (
    <p>{t("text_loading")}</p>
  ) : transactions.length ? (
    <div className="bg-greyscale-100">
      <div className="flex md:flex-row flex-col justify-between pb-5">
        <div className="flex items-center gap-11 border-b border-greyscale-200 md:w-full w-[calc(100vw-50px)] overflow-x-auto">
          {tables.map((table) => (
            <button
              key={table}
              className={`flex items-center leading-[22px] pb-2.5 border-b-4 ${
                selectedTable === table
                  ? "border-tertiary-400 text-tertiary-400"
                  : "border-none text-greyscale-200"
              }`}
              onClick={() => handleSelectedTableClick(table)}
            >
              {table}
            </button>
          ))}
        </div>
      </div>
      {selectedTable === t("table_transactions") ? (
        <div className="h-96 overflow-y-auto pr-2">
          <WalletTable key="1" items={transactions} />
        </div>
      ) : selectedTable === t("table_deposits") ? (
        <div className="h-96 overflow-y-auto pr-2">
          <WalletTable
            key="2"
            items={transactions.filter(
              (transaction) => transaction.method === "deposit"
            )}
          />
        </div>
      ) : selectedTable === t("table_withdrawals") ? (
        <div className="h-96 overflow-y-auto pr-2">
          <WalletTable
            key="3"
            items={transactions.filter(
              (transaction) => transaction.method === "withdraw"
            )}
          />
        </div>
      ) : selectedTable === t("table_wins") ? (
        <div className="h-96 overflow-y-auto pr-2">
          <WalletTable
            key="4"
            items={transactions.filter(
              (transaction) => transaction.method === "win"
            )}
          />
        </div>
      ) : selectedTable === t("table_wagers") ? (
        <div className="h-96 overflow-y-auto pr-2">
          <WalletTable
            key="5"
            items={transactions.filter(
              (transaction) => transaction.method === "wager"
            )}
          />
        </div>
      ) : (
        <p>{t("text_no-transactions")}</p>
      )}
    </div>
  ) : (
    <p>{t("text_no-transactions")}</p>
  );
};

export default WalletTables;

import React, { useState } from "react";
import { ReactComponent as VisaIcon } from "src/assets/icons/payment-methods/light/visa.svg";
import { ReactComponent as MasterCardIcon } from "src/assets/icons/payment-methods/light/mastercard.svg";
/* import { ReactComponent as MaestroIcon } from "src/assets/icons/payment-methods/light/maestro.svg"; */

interface ProviderProps {
  id: string;
  name: string;
}

interface PaymentProviderSelectorProps {
  onSelect: (value: string) => void;
  providers: ProviderProps[];
}

const PaymentProviderSelector: React.FC<PaymentProviderSelectorProps> = ({
  onSelect,
  providers,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <div className="flex flex-col gap-2.5">
      <label className="border border-primary-200 rounded-[10px] px-6 py-3.5 flex gap-3.5 items-center">
        <div className="grid place-items-center">
          <input
            type="radio"
            id="cashbulls-visa"
            name="single-select-visa"
            /* value={
              providers.find((provider) => provider.name === "cashbulls")?.id
            }
            checked={
              selectedValue ===
              providers.find((provider) => provider.name === "cashbulls")?.id
            } */
            value={"cashbulls-visa"}
            checked={selectedValue === "cashbulls-visa"}
            onChange={handleChange}
            className="peer col-start-1 row-start-1 appearance-none shrink-0 w-6 h-6 border-2 border-primary-300 rounded-full"
          />

          <div className="col-start-1 row-start-1 w-3 h-3 rounded-full peer-checked:bg-primary-300" />
        </div>
        <VisaIcon className="mx-auto" />
        {/* <MasterCardIcon />
        <MaestroIcon /> */}
      </label>
      <label className="border border-primary-200 rounded-[10px] px-6 py-3.5 flex gap-3.5 items-center">
        <div className="grid place-items-center">
          <input
            type="radio"
            id="cashbulls-mastercard"
            name="single-select-mastercard"
            /* value={
              providers.find((provider) => provider.name === "cashbulls")?.id
            }
            checked={
              selectedValue ===
              providers.find((provider) => provider.name === "cashbulls")?.id
            } */
            value={"cashbulls-mastercard"}
            checked={selectedValue === "cashbulls-mastercard"}
            onChange={handleChange}
            className="peer col-start-1 row-start-1 appearance-none shrink-0 w-6 h-6 border-2 border-primary-300 rounded-full"
          />

          <div className="col-start-1 row-start-1 w-3 h-3 rounded-full peer-checked:bg-primary-300" />
        </div>
        {/* <VisaIcon /> */}
        <MasterCardIcon className="mx-auto" />
        {/* <MaestroIcon /> */}
      </label>
    </div>
  );
};

export default PaymentProviderSelector;

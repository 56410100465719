export const PokerIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_59_4208)">
          <path d="M11.0127 1.25V3.36094C12.4649 3.47233 13.7868 4.04836 14.8309 4.94415L16.2804 3.49462C14.8603 2.23133 13.0293 1.36816 11.0127 1.25V1.25Z" />
          <path d="M9.98732 1.25C7.97071 1.36816 6.1397 2.23133 4.7196 3.49462L6.16913 4.94415C7.21326 4.04836 8.53509 3.47233 9.98732 3.36094V1.25Z" />
          <path d="M3.86094 9.48719C3.97233 8.03497 4.54836 6.71313 5.44415 5.66901L3.99462 4.21948C2.73133 5.63958 1.86816 7.47059 1.75 9.48719H3.86094Z" />
          <path d="M10.5 7.64893L8.68757 9.46138C8.48779 9.66163 8.48779 9.98658 8.68757 10.1869C8.88232 10.3816 9.21879 10.3806 9.41255 10.1869L10.5 9.09938L11.5875 10.1868C11.7822 10.3806 12.1192 10.3806 12.3119 10.1868C12.5122 9.98658 12.5122 9.66163 12.3125 9.46134L10.5 7.64893Z" />
          <path d="M10.5 4.36035C7.39031 4.36035 4.86036 6.8903 4.86036 10C4.86036 13.1097 7.39031 15.6397 10.5 15.6397C13.6097 15.6397 16.1397 13.1097 16.1397 10C16.1397 6.8903 13.6097 4.36035 10.5 4.36035ZM13.0374 10.9118C12.5066 11.4435 11.6501 11.5303 11.0127 11.0352V12.5635H12.0381V13.5889H8.96192V12.5635H9.98732V11.0093C9.39413 11.4668 8.4991 11.4488 7.96206 10.9118C7.36276 10.3119 7.36276 9.3361 7.96258 8.73631L10.5 6.19885L13.0374 8.73628C13.6373 9.3361 13.6373 10.3119 13.0374 10.9118Z" />
          <path d="M14.8309 15.0559C13.7868 15.9517 12.4649 16.5277 11.0127 16.6391V18.7501C13.0293 18.6319 14.8603 17.7687 16.2804 16.5054L14.8309 15.0559Z" />
          <path d="M15.5559 14.3309L17.0054 15.7804C18.2687 14.3603 19.1319 12.5293 19.25 10.5127H17.1391C17.0277 11.9649 16.4517 13.2868 15.5559 14.3309Z" />
          <path d="M15.5559 5.66901C16.4517 6.7131 17.0277 8.03497 17.1391 9.48719H19.25C19.1319 7.47059 18.2687 5.63958 17.0054 4.21948L15.5559 5.66901Z" />
          <path d="M5.44415 14.3309C4.54836 13.2868 3.97233 11.9649 3.86094 10.5127H1.75C1.86816 12.5293 2.73133 14.3603 3.99462 15.7804L5.44415 14.3309Z" />
          <path d="M4.7196 16.5054C6.1397 17.7687 7.97071 18.6319 9.98732 18.7501V16.6391C8.53509 16.5277 7.21326 15.9517 6.16913 15.0559L4.7196 16.5054Z" />
        </g>
        <defs>
          <clipPath id="clip0_59_4208">
            <rect
              width="17.5"
              height="17.5"
              fill="white"
              transform="translate(1.75 1.25)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default PokerIcon;

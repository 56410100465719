import { Footer, Header } from "src/components/organisms";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import { ReactComponent as HomeIcon } from "src/assets/icons/home/full.svg";
import { Link } from "react-router-dom";
import { NotFoundIcon } from "src/assets/svg";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t } = useTranslation();

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "404 Page",
  });

  return (
    <div className="font-main w-full">
      <Header />
      <div className="flex flex-col md:gap-20 gap-10 justify-center items-center xl:px-60 md:px-24 pt-40 pb-80 px-6 bg-custom-gradient">
        <div className="w-full">
          <NotFoundIcon />
        </div>
        <h1 className="md:text-[90px] text-5xl leading-[100px] font-bold text-greyscale-100 md:px-20 px-6 break-words text-center">
          {t("page_not-found_wrong-url")}!
        </h1>
        <p className="text-xl text-greyscale-100 xl:px-60 md:px-32 break-words text-center">
          {t("page_not-found_wrong-url2")}
        </p>
        <div className="flex flex-col-reverse md:flex-row w-full xl:px-60 md:px-40 px-14 gap-10">
          <Link
            to={"/"}
            className="flex items-center w-full px-6 py-3.5 gap-2 border rounded-xl border-secondary-200 text-secondary-200 whitespace-nowrap"
          >
            <ArrowLeftIcon className="text-secondary-200" />
            <p className="mx-auto">{t("button_go-back")}</p>
          </Link>
          <Link
            to={"/"}
            className="flex items-center w-full px-6 py-3.5 gap-2 rounded-xl text-primary-400 bg-secondary-200 whitespace-nowrap xl:justify-normal justify-center"
          >
            <p className="mx-auto hidden xl:block">{t("button_return-home")}</p>
            <HomeIcon className="text-primary-400" />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

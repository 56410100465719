import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import { ReactComponent as UserIcon } from "src/assets/icons/user/roundal.svg";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
import { ReactComponent as LiveIcon } from "src/assets/icons/nav/Live.svg";
import { ReactComponent as DiceIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as ChipIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { ReactComponent as PromotionIcon } from "src/assets/icons/nav/Promos.svg";
import { ReactComponent as LogoutIcon } from "src/assets/icons/security/Logout.svg";
import { ReactComponent as SquareUserIcon } from "src/assets/icons/account/user.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { ReactComponent as PencilIcon } from "src/assets/icons/edit/Edit 2.svg";
import { ReactComponent as CloudIcon } from "src/assets/icons/cloud/-2.svg";
import { ReactComponent as AddPaymentCardIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as GiftIcon } from "src/assets/icons/shopping/Gift.svg";
import { ReactComponent as MoneyDollarIcon } from "src/assets/icons/money&payment/Money Dollar.svg";
import { ReactComponent as DepositIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as WithdrawIcon } from "src/assets/icons/money&payment/Favourite Payment Card.svg";
import { ReactComponent as LockIcon } from "src/assets/icons/security/Lock 1.svg";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as NewGamesIcon } from "src/assets/icons/nav/newGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
import { ReactComponent as LiveNowIcon } from "src/assets/icons/nav/liveNOW.svg";
import { ReactComponent as FavouritesIcon } from "src/assets/icons/nav/favourtites.svg";
import { useTranslation } from "react-i18next";
import { BrotherBets3Logo as BrotherBetsLogo3 } from "src/assets/svg";
import { Button, CountrySelector, NavLink } from "src/components/atoms";
import {
  Balance,
  GameModal,
  InputDate,
  InputSelect,
  InputText,
  NavBar,
  SearchField,
  Select,
} from "src/components/molecules";
import {
  ChangePasswordModal,
  TransactionModal,
  WalletTables,
} from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import usePlayerSessionService from "src/services/playerSessionService";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import usePlayerService from "src/services/playerService";
import PhoneInput from "react-phone-input-2";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { GameResponse, UpdatePlayerRequest } from "src/types";
import useNotificationsService from "src/services/notificationsService";
import useTransactionService from "src/services/transactionService";
import { useGames } from "src/context/GamesContext";
import { GameProps } from "src/components/molecules/gameModal/GameModal.component";
import useGameService from "src/services/gameService";

export interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

interface PreferredWallet {
  currency_code: string;
  balance: number | string;
}

type SubMenuState =
  | "main"
  | "browseGames"
  | "myAccount"
  | "customerProfile"
  | "wallet"
  | "searchGames";

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState<SubMenuState>("main");
  const [preferredWallet, setPreferredWallet] = useState<PreferredWallet>({
    currency_code: "",
    balance: 0,
  });
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isTransactionModalOpen, setTransactionModalOpen] =
    useState<boolean>(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<
    "deposit" | "withdraw"
  >("deposit");
  const [gameModalData, setGameModalData] = useState<GameProps | undefined>(
    undefined
  );
  const [isGameModalOpen, setGameModalOpen] = useState<boolean>(false);
  const [isWalletLoading, setWalletLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<GameResponse[]>([]);
  const { token, playerData, setPlayerData, logout } = useAuth();
  const { games } = useGames();
  const { logout: serverLogout } = usePlayerSessionService();
  const { isNicknameTaken, updatePlayer } = usePlayerService();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();
  const { getBalances, getTransactions, getCurrencyById } =
    useTransactionService();
  const { launchGame } = useGameService();

  const handleTransactionMoldal = (type: "deposit" | "withdraw") => {
    setTransactionType(type);
    // Add begin checkout event
    ReactGA.event("begin_checkout", {
      currency: "",
      value: 0,
      items: [
        {
          item_id: type,
          item_name: playerData?.id || "",
        },
      ],
    });
    setTransactionModalOpen(true);
  };

  const editSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, t("error_max_20-chars"))
      .test("username", t("error_user-already-exists"), async (value) => {
        if (!value || playerData?.nickname === value) return true;
        try {
          const exists = await isNicknameTaken(value);
          if (exists) {
            return false;
          } else {
            return true;
          }
        } catch (error) {
          addErrorNotification(t("error_user-check"));
          console.error("Error checking username: ", error);
        }
        return true;
      }),
    firstName: Yup.string()
      .max(15, t("error_max_15-chars"))
      .required(t("error_required")),
    lastName: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    gender: Yup.string().required(t("error_required")),
    birthDate: Yup.date().required(t("error_required")),
    phoneNumber: Yup.string().max(20, t("error_max_20-chars")),
    country: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    address1: Yup.string().max(20, t("error_max_20-chars")),
    address2: Yup.string().max(20, t("error_max_20-chars")),
    zipCode: Yup.string().max(20, t("error_max_20-chars")),
  });

  const switchToSubMenu = (option: SubMenuState) => {
    if (
      !Cookies.get("token") &&
      (option === "myAccount" ||
        option === "wallet" ||
        option === "customerProfile")
    ) {
      setMenuState("main");
      onClose();
      navigate("/");
    } else {
      setMenuState(option);
    }
  };

  const switchToMainMenu = () => {
    setMenuState("main");
  };

  const handleLogout = async () => {
    try {
      // Call server to logout
      if (playerData?.id) {
        await serverLogout(playerData?.id);
      }
      logout();
      setMenuState("main");
      onClose();
      navigate("/");
    } catch (error) {
      addErrorNotification(t("error_logout"));
      console.error("Error logging out: ", error);
    }
  };

  const handleSearch = (query: string) => {
    if (query.trim() === "") {
      setSearchResults([]);
    } else {
      const results = games.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    }
  };

  const handleSetGameModalData = (game: GameResponse) => {
    setGameModalData({
      name: game.name,
      description: (
        <div className="break-words flex flex-col">
          <span>
            {game.has_mobile
              ? t("text_playable-on-mobile")
              : t("text_playable-on-desktop")}
          </span>
          <span>
            {game.has_demo
              ? t("text_game-has-demo")
              : t("text_no-game-description")}
          </span>
        </div>
      ),
      image: game.image,
      bets: {
        minimum: "2",
        maximum: "250",
      },
      game_id: game.id,
      game_name: game.name,
      is_demo: game.has_demo,
      is_mobile: game.has_mobile,
      provider_id: game.provider_id,
    });
    setGameModalOpen(true);
  };

  const handleFavoriteToggle = (id: string | number): void => {
    alert(
      "The favourites service is still under development. Thank for your comprehension."
    );
  };

  const handlePlayGame = async (game: GameProps): Promise<void> => {
    try {
      if (!playerData) {
        return addErrorNotification(t("error_please-login"));
      } else {
        const { result: currency } = await getCurrencyById(
          playerData.currency_id
        );
        if (!currency) {
          return addErrorNotification(t("error_get-currency"));
        }
        const {
          result: { play_for_real: url },
        } = await launchGame({
          game_id: game.game_id,
          game_name: game.game_name,
          is_demo: game.is_demo,
          is_mobile: game.is_mobile,
          player_id: playerData.id,
          provider_id: game.provider_id,
          user_currency: currency.alphabetic_code,
          user_language: "en",
        });
        // Open the external URL in a new window centered on the screen
        const width = 1024;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          url,
          "_blank",
          `width=${width},height=${height},top=${top},left=${left}`
        );
      }
    } catch (error: any) {
      addErrorNotification("error_launch-game");
      console.log("Failed to launch game: ", error);
    }
  };

  useEffect(() => {
    const setWalletValues = async (): Promise<void> => {
      if (playerData?.id) {
        try {
          const { result: wallets } = await getBalances(playerData.id);
          const preferredWallet = wallets.find(
            (wallet) => wallet.currency_id === playerData.currency_id
          );
          if (!preferredWallet) {
            addErrorNotification(t("error_find-preferred-wallet"));
            return;
          }
          setPreferredWallet({
            currency_code: preferredWallet.alphabetic_code,
            balance: preferredWallet.amount,
          });
        } catch (error) {
          addErrorNotification(t("error_get-wallet"));
          console.error("Error getting wallets: ", error);
        } finally {
          setWalletLoading(false);
        }
        return;
      }
    };
    if (menuState === "wallet" && !isTransactionModalOpen) {
      setWalletValues();
    }
  }, [menuState, isTransactionModalOpen]);

  useEffect(() => {
    const getUserTransactions = async () => {
      if (!playerData?.id) return;
      try {
        const { result } = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification(t("error_get-transactions"));
        console.error("Error getting transactions: ", error);
      }
    };
    if (menuState === "wallet") {
      getUserTransactions();
    }
  }, [menuState]);

  return (
    <div
      className={`fixed flex flex-col inset-0 bg-opacity-75 z-50 w-screen overflow-y-scroll ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <TransactionModal
        isOpen={isTransactionModalOpen}
        transactionType={transactionType}
        balance={preferredWallet.balance.toString()}
        onClose={() => setTransactionModalOpen(false)}
        currencyPrefix={preferredWallet.currency_code === "EUR" ? "€" : "$"}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
      <GameModal
        isModalOpen={isGameModalOpen}
        onRequestClose={() => setGameModalOpen(false)}
        game={gameModalData}
        isFavourite={false}
        onClickFavourite={() => handleFavoriteToggle(0)}
        onClickPlay={() =>
          gameModalData ? handlePlayGame(gameModalData) : console.warn("Error")
        }
      />
      {/** Menu header */}
      <section className="flex flex-col gap-6 relative bg-greyscale-400 py-4 px-6 md:px-60 lg:px-80 xl:px-96">
        <div className="flex">
          <Link
            to={"/"}
            className="mx-auto self-center"
            onClick={() => {
              onClose();
              setTimeout(switchToMainMenu, 300);
            }}
          >
            <BrotherBetsLogo3 />
          </Link>
          <Button
            onClick={() => {
              onClose();
              setTimeout(switchToMainMenu, 300);
            }}
            className="flex justify-center items-center p-2 gap-4 absolute right-1 top-1"
          >
            <CloseIcon className="text-greyscale-100 w-[34px] h-[34px]" />
          </Button>
        </div>
        {token ? (
          <div className="flex justify-between items-center">
            <div className="flex gap-3.5 items-center">
              <span className="rounded-full min-w-[50px] min-h-[50px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100 font-semibold">
                {playerData?.first_name?.charAt(0).toUpperCase()}
                {playerData?.last_name?.charAt(0).toUpperCase()}
              </span>
              <div className="flex flex-col gap-2 text-greyscale-100">
                <span className="text-xl leading-6 font-semibold">
                  {playerData?.first_name} {playerData?.last_name}
                </span>
                {playerData?.nickname && (
                  <span className="text-sm leading-6">
                    {t("text_player-id")}:{" "}
                    <span className="font-semibold">
                      {playerData?.nickname}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <Select className="text-sm justify-self-end" />
          </div>
        ) : (
          <div className="flex gap-6 justify-between items-center">
            <Select className="text-sm" />
            <div className="flex gap-4">
              <Button
                className="flex justify-center items-center px-6 py-2 bg-transparent text-sm text-white border border-white rounded hover:text-greyscale-400 hover:bg-greyscale-100 transition duration-300"
                key={"login-button"}
                onClick={() => navigate("/login")}
                type="button"
              >
                {t("button_header_login")}
              </Button>
              <Button
                className="flex justify-center items-center px-6 py-2 gap-4 bg-secondary-200 text-sm text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
                key={"sign-up-button"}
                onClick={() => navigate("/register")}
                type="button"
              >
                {t("button_header_signup")}
                <ArrowRightIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300" />
              </Button>
            </div>
          </div>
        )}
        <div className="flex">
          <SearchField
            onSearch={handleSearch}
            results={searchResults}
            onSearchResultItemClick={handleSetGameModalData}
            isMobile={true}
          />
        </div>
        {menuState === "browseGames" && (
          <Button
            type="button"
            onClick={() => setMenuState("main")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_browse-games")}
            </span>
          </Button>
        )}
        {menuState === "myAccount" && (
          <Button
            type="button"
            onClick={() => setMenuState("main")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("link_header_my-account")}
            </span>
          </Button>
        )}
        {menuState === "customerProfile" && (
          <Button
            type="button"
            onClick={() => setMenuState("myAccount")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_customer-profile")}
            </span>
          </Button>
        )}
        {menuState === "wallet" && (
          <Button
            type="button"
            onClick={() => setMenuState("myAccount")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_wallet")}
            </span>
          </Button>
        )}
      </section>
      {/** Menu content */}
      <section
        className={`flex flex-col justify-between bg-greyscale-100 md:px-60 lg:px-80 xl:px-96 pt-3.5 pb-[34px] ${
          menuState === "customerProfile" || menuState === "wallet"
            ? "px-3.5 h-fit"
            : "px-10 h-full"
        }`}
      >
        <div className="relative flex flex-col gap-5 h-full justify-between">
          <NavBar
            className={`${
              menuState === "main"
                ? "animate-slide-in-left"
                : "animate-slide-in-right"
            } transition-transform duration-300 ease-in-out ${
              menuState === "main" ? "translate-x-0" : "-translate-x-full"
            }`}
            orientation="vertical"
          >
            {menuState === "main" && (
              <>
                {token ? (
                  <Button
                    className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                    type="button"
                    onClick={() => switchToSubMenu("myAccount")}
                  >
                    <div className="flex gap-2">
                      <UserIcon className="text-primary-400" />
                      {t("link_header_my-account")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </Button>
                ) : (
                  <Button
                    className="flex justify-between items-center gap-2 group text-primary-400/40 px-2.5 py-3.5"
                    type="button"
                    onClick={() => switchToSubMenu("myAccount")}
                    isDisabled={true}
                  >
                    <div className="flex gap-2">
                      <UserIcon className="text-primary-400/40" />
                      {t("link_header_my-account")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-400/30" />
                  </Button>
                )}
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("browseGames")}
                >
                  <div className="flex gap-2">
                    <ControllerIcon className="text-primary-400" />
                    {t("button_browse-games")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
                <Link
                  to={"/games/live"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <LiveIcon className="text-primary-400" />
                      {t("link_live-now")}!
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/casino"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <DiceIcon className="text-primary-400" />
                      {t("link_casino-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/poker"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <ChipIcon className="text-primary-400" />
                      {t("link_poker-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/slots"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <SlotsIcon className="text-primary-400" />
                      {t("button_header_slots")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
              </>
            )}
            {menuState === "browseGames" && (
              <>
                <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                  <div className="flex gap-2 items-center">
                    <HotGamesIcon />
                    {t("link_hot-games")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </NavLink>
                <Link
                  to={"/games/live"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <NewGamesIcon />
                      {t("link_new-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/casino"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <PokerGamesIcon />
                      {t("link_poker-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/poker"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <CasinoGamesIcon />
                      {t("link_casino-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/slots"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <SlotsColouredIcon />
                      {t("button_header_slots")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/live"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <LiveNowIcon />
                      {t("link_live-now")}!
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/favourites"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <FavouritesIcon />
                      {t("link_favourites")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
              </>
            )}
            {menuState === "myAccount" && (
              <>
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("customerProfile")}
                >
                  <div className="flex gap-2">
                    <SquareUserIcon className="text-primary-400" />
                    {t("button_customer-profile")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("wallet")}
                >
                  <div className="flex gap-2">
                    <WalletIcon className="text-primary-400" />
                    {t("button_wallet")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
              </>
            )}
            {menuState === "customerProfile" && playerData && (
              <Formik
                validationSchema={editSchema}
                initialValues={{
                  username: playerData.nickname,
                  firstName: playerData.first_name,
                  lastName: playerData.last_name,
                  gender: playerData.gender,
                  birthDate: playerData.date_of_birth,
                  phoneNumber: playerData.contact_number,
                  country: playerData.country,
                  address1: playerData.address_1,
                  address2: playerData.address_2,
                  zipCode: playerData.post_code,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const formatedValues: UpdatePlayerRequest = {
                      nickname: playerData.nickname,
                      first_name: values.firstName,
                      last_name: values.lastName,
                      gender: values.gender,
                      date_of_birth: values.birthDate,
                      contact_number: values.phoneNumber,
                      country: values.country,
                      address_1: values.address1,
                      address_2: values.address2,
                      post_code: values.zipCode,
                    };
                    const newPlayerData = await updatePlayer(
                      playerData.id,
                      formatedValues
                    );
                    setPlayerData(newPlayerData);
                    addSuccessNotification(t("success_player-updated"));
                    setSubmitting(false);
                  } catch (error: any) {
                    addErrorNotification(error);
                    console.error("Error submitting update player: ", error);
                  }
                }}
              >
                {({ setFieldValue, values }) => (
                  <Form className="flex flex-col items-center w-full">
                    <div className="flex flex-col gap-10 w-full">
                      <div className="p-5 flex flex-col gap-2.5 border rounded-[10px] border-primary-400 text-primary-400">
                        <div className="flex justify-between items-end">
                          <span className="text-lg">
                            {t("text_player-profile")}
                          </span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>{t("text_edit")}</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-2.5">
                          <span className="rounded-full min-w-[74px] min-h-[74px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100 font-semibold">
                            {playerData?.first_name?.charAt(0).toUpperCase()}
                            {playerData?.last_name?.charAt(0).toUpperCase()}
                          </span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>{t("text_edit")}</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                          <span className="text-lg leading-6 font-semibold">
                            {playerData?.first_name} {playerData?.last_name}
                          </span>
                          <span className="text-sm leading-6 border-b border-b-primary-400 pb-4">
                            {t("text_player-id")}:{" "}
                            <span className="font-semibold">
                              {playerData?.nickname}
                            </span>
                          </span>
                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_ISSUER}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI_REGISTER}&scope=openid email profile&state=sss`}
                            className="underline text-sm text-secondary-300"
                          >
                            {t("link_register-miracl")}
                          </a>
                        </div>
                        <div className="flex justify-between items-end">
                          <span className="text-lg">{t("text_player-id")}</span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>{t("text_edit")}</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                        </div>
                        <InputText
                          name="username"
                          label="Username"
                          type="text"
                          placeholder={playerData?.nickname}
                          disabled={true}
                        />
                      </div>
                      <div className="flex flex-col gap-6 text-primary-300">
                        <div className="flex justify-between items-end">
                          <span className="text-lg">
                            {t("text_personal-details")}
                          </span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>Edit</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                        </div>
                        <InputText
                          name="firstName"
                          label={t("text_first-name")}
                          type="text"
                          placeholder={playerData?.first_name}
                        />
                        <InputText
                          name="lastName"
                          label={t("text_last-name")}
                          type="text"
                          placeholder={playerData?.last_name}
                        />
                        <InputSelect name="gender" label={t("text_gender")}>
                          <option value="male">{t("option_male")}</option>
                          <option value="female">{t("option_female")}</option>
                          <option value="other">{t("option_other")}</option>
                        </InputSelect>
                      </div>
                      <div className="flex flex-col gap-6 text-primary-300">
                        <div className="flex justify-between items-end">
                          <span className="text-lg">
                            {t("text_date-of-birth")}
                          </span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>{t("text_edit")}</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                        </div>
                        <InputDate
                          initialDate={values.birthDate}
                          setValue={(date: string) =>
                            setFieldValue("birthDate", date)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-6 text-primary-300">
                        <div className="flex justify-between items-end">
                          <span className="text-lg">
                            {t("text_contact-details")}
                          </span>
                          <button className="flex gap-1 text-[10px] items-center">
                            <span>{t("text_edit")}</span>
                            <PencilIcon className="max-w-3.5" />
                          </button>
                        </div>
                        <InputText
                          name="address1"
                          label={t("text_address-1")}
                          type="text"
                          placeholder={playerData?.address_1}
                        />
                        <InputText
                          name="address2"
                          label={t("text_address-2")}
                          type="text"
                          placeholder={playerData?.address_2}
                        />
                        <div className="flex gap-5">
                          <div className="flex-1">
                            <InputText
                              name="zipCode"
                              label={t("text_zip-code")}
                              type="text"
                              placeholder={playerData?.post_code}
                            />
                          </div>
                          <div className="flex-1 w-1/2">
                            <CountrySelector
                              onSelect={(country) =>
                                setFieldValue("country", country.code)
                              }
                              defaultCountry={values.country}
                            />
                          </div>
                        </div>
                        <PhoneInput
                          country={"pt"}
                          value={playerData?.contact_number}
                          placeholder={playerData?.contact_number}
                          onChange={(phone) =>
                            setFieldValue("phoneNumber", phone)
                          }
                          containerClass="!w-full !text-sm"
                          inputClass="!w-full react-tel-input !text-sm !border-primary-400 !rounded focus:!shadow-none focus-visible:!outline-1 !outline-black"
                          specialLabel={t("placeholder_contact-number")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-10 items-center">
                      <div className="flex flex-col gap-3.5 justify-center mt-10 w-full">
                        <span className="text-primary-100 text-xs leading-[14px] mx-auto">
                          {t("text_save-changes")}
                        </span>
                        <button className="flex w-full items-center px-6 py-4 rounded text-greyscale-300 bg-secondary-200 hover:bg-secondary-300 hover:text-greyscale-100 transition duration-300 disabled:opacity-40 disabled:cursor-not-allowed">
                          <span className="mx-auto">{t("button_save")}</span>
                          <CloudIcon />
                        </button>
                      </div>
                      <div className="flex flex-col gap-3.5 justify-center w-fit">
                        <button
                          type="button"
                          onClick={() => setChangePasswordModalOpen(true)}
                          className="bg-primary-400 text-greyscale-100 text-sm flex px-6 py-4 gap-[34px] items-center rounded hover:bg-primary-100 hover:text-primary-400 transition duration-300 disabled:opacity-40 disabled:cursor-not-allowed"
                        >
                          <span className="mx-auto">
                            {t("text_change-password")}
                          </span>
                          <LockIcon />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {menuState === "wallet" && (
              <>
                <div className="w-full flex flex-col gap-10 items-center">
                  <div className="w-full flex flex-col items-center gap-6">
                    <div className="w-full flex flex-col gap-3.5">
                      <div className="flex flex-col gap-3.5 pt-4">
                        <Balance
                          selectedCurrency={preferredWallet.currency_code}
                          balance={preferredWallet.balance}
                          isLoading={isWalletLoading}
                        />
                        <div className="flex w-full gap-4">
                          <Button
                            className="w-full flex justify-center flex-grow items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-primary-400 bg-secondary-200 hover:text-greyscale-100 hover:bg-secondary-300 transition duration-300 text-sm"
                            onClick={() => handleTransactionMoldal("withdraw")}
                          >
                            <span>{t("text_withdraw")}</span>
                            <WithdrawIcon />
                          </Button>
                          <Button
                            className="w-full flex justify-center  flex-grow items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-sm"
                            onClick={() => handleTransactionMoldal("deposit")}
                          >
                            <span>{t("text_deposit")}</span>
                            <DepositIcon />
                          </Button>
                        </div>
                      </div>
                      <h2 className="text-lg leading-6 font-bold text-primary-400 self-center pt-4">
                        {t("text_wallet-overview")}
                      </h2>
                      <div className="flex gap-3.5">
                        <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
                          <AddPaymentCardIcon className="w-11 h-11" />
                          <span className="text-primary-400 text-xl leading-[26px] font-bold">
                            {preferredWallet.currency_code === "EUR"
                              ? "€"
                              : "$"}
                            {transactions
                              .filter(
                                (transaction) =>
                                  transaction.method === "deposit"
                              )
                              .reduce((sum, curr) => sum + curr.value, 0)}
                          </span>
                          <span className="text-primary-100 leading-[22px] font-bold">
                            {t("text_deposits")}
                          </span>
                        </div>
                        <div
                          className={`rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px] `}
                        >
                          <MoneyDollarIcon className="w-11 h-11" />
                          <span className="text-primary-400 text-xl leading-[26px] font-bold">
                            {preferredWallet.currency_code === "EUR"
                              ? "€"
                              : "$"}
                            {transactions
                              .filter(
                                (transaction) =>
                                  transaction.method === "withdraw"
                              )
                              .reduce(
                                (sum, curr) => sum + Math.abs(curr.value),
                                0
                              )}
                          </span>
                          <span className="text-primary-100 leading-[22px] font-bold">
                            {t("text_withdrawals")}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-3.5">
                        <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
                          <GiftIcon className="w-11 h-11" />
                          <span className="text-primary-400 text-xl leading-[26px] font-bold">
                            {preferredWallet.currency_code === "EUR"
                              ? "€"
                              : "$"}
                            {transactions
                              .filter(
                                (transaction) => transaction.method === "win"
                              )
                              .reduce((sum, curr) => sum + curr.value, 0)}
                          </span>
                          <span className="text-primary-100 leading-[22px] font-bold">
                            {t("text_winnings")}
                          </span>
                        </div>
                        <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
                          <ControllerIcon className="w-11 h-11" />
                          <span className="text-primary-400 text-xl leading-[26px] font-bold">
                            {preferredWallet.currency_code === "EUR"
                              ? "€"
                              : "$"}
                            {transactions
                              .filter(
                                (transaction) => transaction.method === "wager"
                              )
                              .reduce((sum, curr) => sum + curr.value, 0)}
                          </span>
                          <span className="text-primary-100 leading-[22px] font-bold">
                            {t("text-total-bets")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <h2 className="text-lg leading-6 font-bold text-primary-400 pt-8">
                      {t("text_transaction-history")}
                    </h2>
                    <div className="flex flex-col gap-3.5">
                      <WalletTables />
                    </div>
                  </div>
                </div>
              </>
            )}
          </NavBar>
          {/* Log out section */}
          {menuState !== "customerProfile" && (
            <div className="flex flex-col gap-4">
              <Link
                to={"/under-construction"}
                className={`flex w-full gap-2 justify-center items-center bg-primary-400 text-tertiary-400 border rounded-md py-1.5 px-5 ${
                  menuState === "wallet" && "hidden"
                }`}
              >
                <PromotionIcon className="text-tertiary-400" />
                {t("button_promotions")}
              </Link>
              {token && (
                <Button
                  className="flex w-full gap-2 justify-center items-center text-greyscale-200 underline"
                  onClick={handleLogout}
                >
                  {t("button_header_logout")}
                  <LogoutIcon className="text-greyscale-200" />
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default MobileMenu;

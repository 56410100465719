import * as React from "react"
import { SVGProps } from "react"
const VisaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#0C1727"
        d="M0 6a6 6 0 0 1 6-6h60a6 6 0 0 1 6 6v36a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
      />
      <path
        fill="#3362AB"
        d="M61.966 15.053h-3.772c-1.14 0-2.018.35-2.544 1.491l-7.193 16.403h5.088s.877-2.193 1.052-2.719h6.228c.176.614.614 2.632.614 2.632h4.562l-4.035-17.807ZM56 26.544c.439-1.053 1.93-5 1.93-5 0 .088.438-1.053.614-1.667l.35 1.58s.965 4.297 1.141 5.175h-4.035v-.088ZM48.805 27.07c0 3.684-3.334 6.14-8.509 6.14-2.193 0-4.298-.438-5.439-.965l.702-4.035.614.263c1.58.702 2.632.965 4.562.965 1.403 0 2.894-.526 2.894-1.754 0-.79-.614-1.316-2.543-2.193-1.843-.877-4.299-2.28-4.299-4.825 0-3.509 3.421-5.877 8.246-5.877 1.842 0 3.42.35 4.386.79l-.702 3.86-.35-.352c-.878-.35-2.018-.701-3.685-.701-1.842.087-2.72.877-2.72 1.579 0 .789 1.053 1.403 2.72 2.192 2.807 1.316 4.123 2.808 4.123 4.913Z"
      />
      <path
        fill="#F9B50B"
        d="m6 15.228.088-.351h7.544c1.052 0 1.842.35 2.105 1.491l1.667 7.895C15.737 20.053 11.877 16.63 6 15.228Z"
      />
      <path
        fill="#3362AB"
        d="M28.015 15.053 20.384 32.86h-5.176l-4.386-14.913c3.158 2.018 5.79 5.176 6.755 7.369l.526 1.842 4.737-12.193h5.175v.088ZM30.035 14.965h4.825L31.79 32.86h-4.825l3.07-17.895Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h72v48H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default VisaIcon

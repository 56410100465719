export const termsAndContitions = [
    {
        title: "1. General",
        content: [
            '1.1 These general terms and conditions defined in this text as "Terms and Conditions" and regulate the usage of games and play provided through the brand Brother Bets Casino which is managed by Pink Sands Enterprises SRL (the "Website").',
            "1.2 Pink Sands Enterprises SRL, is a company registered in Costa Rica on the 5th of March 2024, with registered address at 15 Montes de Oca, Las Yosas, Avenidas 8 y 10, Calle 39, LY Center, San Pedro, Provincia de San Jose, Costa Rica and registered company number 3-102-899096.",
            '1.3 We will refer to Pink Sands Enterprises SRL, as "Our Company", "We", "Us", "The Company", and we shall refer to the player as "You" or "the player".',
            "1.4 The current version of this agreement is valid until a new version is published on the Websites.",
            "1.5 These Terms and Conditions also apply to all platforms and devices that enable the  playing of games provided by our company ‘Games’ are defined as Casino and other games available on the Website.",
            '1.6 "Games", "the Games" or "Game" refers to any of the gaming products offered at the Websites.',
            "1.7 Our Company has the right to revise these Terms and Conditions, and they shall be effective from the time they become available on this website. If the Terms and Conditions change, both you and us shall abide to the changes. If you do not agree to the change(s) of these Terms and Conditions, you will not be unable to log in to your account and if there is a positive balance on your account, you will be able to withdraw it. For details, please contact us on support@brotherbetscasino.com, chat is available if required.",
            "1.8 You must fully read and understand these Terms and Conditions before registering. If you do not agree to any part of these Terms and Conditions, you should not continue the registration or usage of our website.",
            "1.9 These Terms and Conditions can be applied when confirming and registering necessary information in the registration process of our website. By registering your account, you agree to read, understand, and accept these Terms and Conditions. By using our website, you agree to accept the current Terms and Conditions.",
            "1.10 You understand and agree to be bound by these Terms and Conditions which will be revised from time to time.",
            "1.11 Although these Terms and Conditions are published in multiple languages, they are all based on the same principle and are translated for the purpose of giving information to our players. Here, only those versions written in English shall establish a legal relationship between you and the Company. If there are differences between the English version and the non-English version of these Terms and Conditions, the English version shall prevail.",
        ],
    },
    {
        title: "2. Your obligations",
        content: [
            "2.1 You may only open one account on each of the Websites provided by us. The Company has the right to terminate any duplicate accounts.",
            "2.2 You are over 18 years of age or comply with a higher minimum legal age, stipulated in the jurisdiction of your residence under the laws applicable to you. It is your sole responsibility to know the age at which gambling is legal in your country of residence.",
            "2.3 You are solely responsible for the account details such as username and password or any other means to access your account at the Websites. In the case there is suspicion that such information should have been compromised you must inform the Company and take proper action to prohibit or prevent any unauthorised access to any part of your account or funds.",
            "2.4 You may use the Games on your own behalf and not on the behalf of any other person or company.",
            "2.5 You may participate in the Games strictly in your personal non-professional capacity for recreational and entertainment reasons only.",
            "2.6 You must be residing in a jurisdiction where gambling is legal. You are not allowed to access the Website and/or register an account from a jurisdiction that prohibits online gambling and/or any other jurisdiction that the Tobique Gaming Commission deems online gambling illegal. Brother Bets Casino prohibits persons located in (including temporary visitors) in UK, USA, Ontario, New Brunswick, and FATF-sanctioned countries.",
            "2.7 You cannot transfer, sell, or pledge your balance or your Account to another person. This prohibition includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial, or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation and/or association in any way shape or form.",
            "2.8 You agree that all information that you give us, such as but not limited to, address and email, during the term of validity of this agreement is complete, true, correct, and that you will immediately notify the Company of any changes of such information or change such details.",
            "2.9 You may only participate in any Game if you have enough funds on your account for such participation.",
            "2.10 You may not use funds that are tainted or associated with any illegality or originate from any illegal activity or source.",
            "2.11 You are solely responsible for reporting and accounting for any taxes or other fees applicable to you under the relevant laws for any winnings that you receive from the Websites.",
            "2.12 In relation to deposits and withdrawals of funds into and from your Account, you shall only use such credit cards and other financial instruments that are valid and lawfully belong to you.",
            "2.13 You understand that by playing Games provided by us, you take the risk of losing money deposited into your account.",
            "2.14 You shall not be involved in any fraudulent, collusive, fixing, or other unlawful activity in relation to your or third parties’ participation in any of the Games and shall not use any software-assisted methods or techniques or hardware devices for your participation in any of the Games. We hereby reserve the right to invalidate any betting in the event of such behaviour.",
            "2.15 You may only use the software for your own personal, recreational use in accordance with rules and terms and conditions we have established and in accordance with all applicable laws, rules, and regulations.",
            "2.16 You shall be courteous to other players using the Websites as well as to support personnel employed by the Company and avoid rude or obscene comments.",
        ],
    },
    {
        title: "3. Your account",
        content: [
            '3.1 For you to be able to deposit money and place bets, you must first register personally with us and open an account ("Account").',
            "3.2 You are permitted to open only one account and register it personally. We reserve the right to close any multiple Accounts opened in the same name or in any way referable to the same person.",
            "3.3 You must enter all mandatory information requested into your registration form all of which must be true and correct. It is your sole responsibility to ensure that the information you provide is true, complete, and correct. You are hereby notified that we carry out verification procedures and that your Account may be blocked for access or closed if you are found to supply false or misleading information. You are required to update and keep up to date the mandatory information provided in the registration form in the event such information changes.",
            "3.4 If you notice that you have more than one registered Account, you must notify us immediately. Failure to do so may lead to your Account being blocked for access.",
            "3.5 As part of the registration process, you will have to choose your username and password for your login into the Website. It is your sole and exclusive responsibility to ensure that your login details are kept securely. You must not disclose your login details to anyone. We are not responsible for any abuse or misuse of your Account by third parties due to your disclosure, whether intentional or accidental, whether active or passive, of your login details to any third party.",
            "3.6 You are not allowed to transfer funds from your Account to other players or to receive money from other players into your Account, or to transfer, sell and/or acquire, user accounts.",
            "3.7 The Company reserves the right to refuse or close an Account at its sole discretion, but any contractual obligations already made by the Company shall, without prejudice to any rights available at law to the Company, be honoured accordingly.",
            "3.8 The Company prohibits player collusion and do not allow any kind of robots and programmed devices to participate in gameplay.",
            "3.9 If you wish to close your account, you may do so at any time, by contacting customer support in written form. The effective closure of the Account will correspond to the termination of the Terms and Conditions. In case the reason behind the closure of the Account is related to concerns about possible gambling addiction the player shall indicate it.",
            "3.10 In the case a player account is closed, related information about the account is saved for a minimum of six (6) years from the time the account is closed. Related information includes player name, address, phone, email and account transactional details.",
        ],
    },
    {
        title: "4. Deposits into your account",
        content: [
            "4.1 You may be charged assigned fees for processing deposits. If such fees occur, they’ll be visible during the deposit process. Deposits can be made with Credit Card, Bank Transfer.",
            "4.2 The Company shall assign minimum deposit amounts and maximum deposit amounts as specified on the Websites.",
            "4.3 Depositing funds into your account is made by using any of the methods specified on the Websites, as may be amended from time to time. Details in respect to the timings for withdrawals in respect to the method utilized are available on the relevant pages of the Websites, as may be amended from time to time.",
            "4.4 Deposited amounts are available on the Account within a reasonable amount of time after the confirmation of the deposit on all available deposit options unless extra verification procedures are needed as stated in",
            "4.5 The Company reserves the right to use additional procedures and means to verify the identity of the customer in order to validate the payment to your account (Know Your Customer).",
            "4.6 If the Company mistakenly credits Your Account with winnings that do not belong to you, whether due to a technical or human error or otherwise, the amount will remain property of the Company and the amount will be transferred from Your Account. If prior to the Company becoming aware of the error You have withdrawn funds that do not belong to you, without prejudice to other remedies and actions that may be available at law, the mistakenly paid amount will constitute a debt owed by You to the In the event of an incorrect crediting, you are obliged to notify the Company immediately by email.",
            "4.7 You shall only use valid credit cards or other financial means legally belonging to you.",
            "4.8 The minimum deposit amount for the Player varies depending on the selected deposit method. This specific amount will be displayed on the Company in the relevant sections during the deposit process. Ensure to check the stipulated minimum for each method before proceeding with your transaction.",
            "4.9 It is prohibited by law to deposit funds obtained from fraudulent means.",
            "4.10 The Company accepts deposits in United States Dollar (USD), Euro (EUR), Cryptocurrencies (USDT/USDC/BTC/ETH/DOGE/LTC/TRX/DAI/XRP/ADA). Any funds withdrawn from the Websites will also be in these respective currencies. Ensure that your selected method of deposit or withdrawal supports these currencies to avoid any complications or additional conversions. Please note that any exchange premiums are paid by You.",
            "4.11 The Company will perform security checks on random occasions and at our sole discretion. You accept that we may demand additional information in the event of a security check and that we may suspend and/or terminate your Account, registration or withhold your payment until we receive the appropriate information that we have requested.",
            "4.12 The Company will only accept colour copies of identification documents.",
            "4.13 Deposits made to your account should be commensurate with game play. Therefore, if in the Company’s discretion, there is suspicion of abuse (for instance, where a deposited amount has not been used for an appropriate level of gameplay and the user then makes a withdrawal request in relation to that deposited amount or where the Company suspects that you are abusing exchange rate fluctuations), we reserve the right in its absolute discretion to cancel the respective deposit(s) in part or in full, to retract any costs that may have resulted in conjunction therewith and to close the gaming account indefinitely. The Company further reserves the right to request and obtain satisfactory proof of deposit and additional copies of personal identification in such instances prior to processing any withdrawal request, the processing of such request to be entirely at the Company’ s discretion.",
            "4.14 The Company reserves the right to terminate an account, ban a player and retain all funds where that player is reasonably suspected of engaging in fraudulent activities which include, but are not limited to, use of stolen or falsified credit cards or account numbers, forgery, and submission of data or documents which are forged, stolen or otherwise misappropriated.",
        ],
    },
    {
        title: "5. Withdrawals",
        content: [
            "5.1 Available withdrawal options are to be clearly stated on the Websites and may differ depending on the market from which the player originates.",
            "5.2 Withdrawals will be made to your bank account or other withdrawal methods available to choose from ‘The Website’ and/or chosen by the Company. The company shall credit you back using the same method as you have previously deposited with if the method allows this to be made.",
            '5.3 Funds marked as "Bonus money" or stuck in an aborted game will not be possible to withdraw.',
            "5.4 The verification team employed by the Company will carry out additional verification procedures for any single or cumulative deposits or withdrawals, and further reserves the right to carry out such verification procedures also in case of lower pay-outs or random security checks. Such verifications may for example include Copy of National ID card, Passport, Personal Account Number Card, Proof of residence and/or self-photos to confirm authenticity.",
            "5.5 All transactions shall be checked in order to prevent money laundering. If the player becomes aware of any suspicious activity relating to any of the Games of the Websites, the player must report this to the Company immediately. The Company may suspend, block, or close an Account and withhold funds if requested to do so in compliance with the Prevention of Money Laundering Regulations.",
            "5.6 The time for the withdrawal to be finalized may vary due to circumstances but a withdrawal attempt should be approved or denied within three (3) days. The player shall be informed about reasons for any delay if the time for the money to arrive at the player's account exceeds ten (10) days.",
            "5.7 Be advised that our products are consumed instantly during gameplay. Thus, we cannot provide refunds, returns of goods, or cancellation of your service when playing. If you play a game with real money, the money will be drawn from your player account instantly.",
            "5.8 Players shall not treat the Company as a banking institution nor expect any interest.",
            "5.9 We may impose a fee on the processing of the withdrawal, which will be stated when you request a withdrawal.",
        ],
    },
    {
        title: "6. Bonuses & Rewards Scheme Conditions",
        content: [
            "6.1 The bonus and rewards conditions defined are valid until a new version is made available on the Websites.",
            "6.2 The Company reserves the right to withhold and remove any bonus money awarded to you if it has not been used within 60 days from the date awarded. Individual campaigns may have a shorter expiration time and are, if so, stated to the player related to the time when receiving the campaign offer.",
            "6.3 If you are eligible for a bonus, wagering requirements may apply before you are eligible to make any cash-outs. The Company has the right to choose which games and how much a game contributes to the wagering requirements.",
            "6.4 The welcome bonus is subject to 25x deposit + bonus wagering requirements. Funds derived from bonuses are not withdrawable until the associated wagering requirement is complete.",
            "6.5 Unless otherwise specified, bonus funds can be wagered on slot games only.",
            "6.6 Welcome bonuses at the Company can only be received once per household, IP address, and/or credit card number. We reserve the right to close your account and confiscate any existing funds if evidence of bonus abuse/fraud is found.",
            "6.7 Individually, bonus campaigns can have specific details and terms, in this case such information shall be made available to the user.",
            "6.8 Game rounds and game features triggered with bonus funds must be played through whilst a bonus is active. Players are not permitted to delay completion of game rounds or game features until after the wagering requirement for that bonus has been met, and/or they have deposited new funds. If such activity is identified, the player agrees that their bonus and/or any associated winnings may be voided at the discretion of Casino Management.",
            "6.9 The Company reserves the right to close your account and not to issue refunds of any deposits, remittance of balances or winnings generated on the account, should we suspect bonus abuse has occurred. Abuse includes but is not limited to, cash stashing, bonus stashing or multi account usage.",
            "6.10 The wagering requirement may be deemed void if your account balance is or reaches 0.00 EUR/USD and all game rounds are closed and settled.",
            "6.11 Cashback bonuses differ and are not subject to wagering requirements. Such bonuses are credited directly to the player's real money/cash account balance.",
        ],
    },
    {
        title: "7. Paused and Inactive accounts",
        content: [
            "7.1 A paused account is an account that has not been accessed for 12 months, that has a real money balance.",
            "7.2 An inactive account is an account that has not been accessed for 30 months, that has a real money balance.",
            "7.3 The Company will inform you by email that your account will become inactive one month prior. When an account is deactivated, The Company may impose a fee to your account, such fee shall be a monthly fee of $5.",
        ],
    },
    {
        title: "8. Responsible Gaming",
        content: [
            "8.1 You may at your discretion choose to limit your ability to access your Account (log in) for a definite or indefinite time in which your account will not be accessible. You may choose to self-exclude for a period of 7 days, 30 days, 90 days and permanent. No variations to these time frames will be available.",
            "8.2 All limitations mentioned above are administrated and initiated in the ‘Security’ section of the Websites. Alternatively this can be requested by contacting customer support on live chat or via email at support@queen-casino.com.",
            "8.3 A self-exclusion request would be valid for the Company only and does not include other sites operated by us.",
            "8.4 We reserve the right at our sole discretion to block or close your Account on any platform owned by the Company if we consider that your continued use of our Services is detrimental to your health and wellbeing.",
            "8.5 We reserve the right at our sole discretion to block or close your Account on any platform owned by the Company if we consider that you have a gambling addiction and that your continued use of our Services is detrimental to your health and wellbeing.",
            "8.6 Upon closing your account you acknowledge the following:",
            "• You should not attempt or proceed to open any new accounts during your self-exclusion timeframe or forever if a permanent self-exclusion has been selected.",
            "• If you succeed in opening an account during a self-exclusion, we will endeavour to close any and all such accounts at our earliest detection.",
            "• The Company is only responsible for taking reasonable steps to prevent you from gambling on any of our products. It is also the players responsibility to refrain from breaching these agreed terms.",
        ],
    },
    {
        title: "9. Privacy Policy",
        content: [
            "9.1 You acknowledge and accept that we collect and use your personal data. For you to access the processing of personal data in compliance with data protection regulations, you are requested to access our Privacy Policy.",
        ],
    },
    {
        title: "10. Exceptional Circumstances & Aborted Games",
        content: [
            "10.1 Unexpected technical problems or circumstances outside the control of the Company such as technical problems with third party providers, allows the Company to cancel bets and give refunds to players.",
            "10.2 The Company has the right to limit, cancel and refuse bets in the case they are too large or if we see that the betting pattern of the player takes place in such a way that the system is being abused.",
            "10.3 If a refund is decided upon, the amount of the refund shall be returned to the player’s account, the player shall be informed, and the procedure finalized within 48 hours after the decision has been made.",
            '10.4 In the case that a game is "stuck" in a state where it is not being finished, for example a connection loss while playing an active blackjack hand, the Company has the right to "clean up" such bets at a regular basis and refund the bet/wager to the player’s account. This is performed on a monthly basis. If the game has been aborted or miscarried on the server, the player shall be refunded.',
            "10.5 If a bonus campaign has in any way been misconfigured, the Company has the right to alter player's balances and account details to correct the mistake.",
            "10.6 If a game contains a bug or misconfiguration that causes incorrect behaviour or pay out, the Company has the right to remove the game and alter player's balances and account details to correct the mistake.",
            "10.7 The Company is not liable for any downtime, server disruptions, lagging, or any technical or political disturbance to the game play. Refunds may be given solely at the discretion of the management.",
            "10.8 The Company shall accept no liability for any damages or losses which are deemed or alleged to, have arisen out of, or in connection with Websites or its content, including without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of the site or its content or any errors or omissions in content.",
            "10.9 The Company may remove any game from the Websites at any time we see fit.",
        ],
    },
    {
        title: "11. Complaints",
        content: [
            "11.1 You may contact our customer service according to the instructions located on the Websites to give us any complaints regarding our services. Should you want to send us a complaint through email you may do so addressing the Company you may do so addressing support@brotherbetscasino.com.",
            "11.2 Complaints are handled in the support department and escalated in the organization of the Company in the case that support personnel do not solve the case immediately. The player shall be informed about the state of the complaint to a reasonable level.",
            "11.3 If, for some reason, you are not satisfied with the resolution of your complaint by Pink Sands Enterprises SRL., you may bring unresolved disputes to Gaming Services Provider N.V Tobique Gaming Commission.",
        ],
    },
    {
        title: "12. Limitation of Liability",
        content: [
            "12.1 You enter the Websites and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever, whether express or implied.",
            "12.2 Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers:",
            "• do not warrant that the software, Games and the Websites are fit for their purpose.",
            "• do not warrant that the software, Games and the Websites are free from errors.",
            "• do not warrant that the software, Games and the Websites will be accessible without interruptions.",
            "• shall not be liable for any loss, costs, expenses, or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in the Games.",
            "12.3 You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such malfunctioning shall be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such funds.",
            "12.4 You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.",
            "12.5 To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed $1500.",
        ],
    },
    {
        title: "13. Breaches, Penalties and Termination",
        content: [
            "13.1 If you breach any provision of these Terms and Conditions or we have a reasonable ground to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Account, or withhold payment of your winnings and apply such funds on account of any damages due by you.",
            "13.2 If the Company suspects that you are engaged in illegal and/or fraudulent activities when using any of the Websites; or in breach of this Agreement; or that you are having problems with creditors or otherwise detrimental to our business, we may freeze or terminate your account or cancel any stakes at our absolute discretion.",
            "13.3 You acknowledge that the Company shall be the final decision-maker of whether you have violated the Company's rules, terms or conditions in a manner that results in suspension or permanent barring from participation in our site(s).",
        ],
    },
    {
        title: "14. Severability",
        content: [
            "14.1 If any provision of these Terms and Conditions is held to be illegal or unenforceable, such provision shall be severed from these Terms and Conditions and all other provisions shall remain in force unaffected by such severance.",
        ],
    },
    {
        title: "15. Assignment",
        content: [
            "15.1 We reserve the right to assign or otherwise lawfully transfer this agreement. You shall not assign or otherwise transfer this agreement.",
        ],
    },
    {
        title: "16. Entire Agreement",
        content: [
            "16.1 These Terms and Conditions jointly with our Privacy Policy constitute the entire agreement between you and the Company with respect to the Websites and save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the Websites.",
        ],
    },
    {
        title: "17. Applicable law and jurisdiction",
        content: [
            "17.1 These Terms and Conditions shall be governed by the Tobique Laws.",
            "17.2 The parties agree that any dispute, controversy, or claim arising out of or in connection with these Terms and Conditions, or the breach, termination, or invalidity thereof, shall be submitted to the exclusive jurisdiction of the Tobique Laws.",
        ],
    },
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkdownContainer_MarkdownContainer__CDcN- p, .MarkdownContainer_MarkdownContainer__CDcN- ul, .MarkdownContainer_MarkdownContainer__CDcN- ol, .MarkdownContainer_MarkdownContainer__CDcN- blockquote {
  margin-top: 1em;
  margin-bottom: 1em;
}
.MarkdownContainer_MarkdownContainer__CDcN- h1, .MarkdownContainer_MarkdownContainer__CDcN- h2, .MarkdownContainer_MarkdownContainer__CDcN- h3 {
  font-weight: bold;
}
.MarkdownContainer_MarkdownContainer__CDcN- h1:not(:first-child), .MarkdownContainer_MarkdownContainer__CDcN- h2:not(:first-child), .MarkdownContainer_MarkdownContainer__CDcN- h3:not(:first-child) {
  margin-top: 2em;
}
.MarkdownContainer_MarkdownContainer__CDcN- h1 a, .MarkdownContainer_MarkdownContainer__CDcN- h2 a, .MarkdownContainer_MarkdownContainer__CDcN- h3 a {
  color: unset;
}
.MarkdownContainer_MarkdownContainer__CDcN- a {
  color: #c7fe70;
}
.MarkdownContainer_MarkdownContainer__CDcN- h1 {
  font-size: 2em;
  color: #c7fe70;
}
.MarkdownContainer_MarkdownContainer__CDcN- h2 {
  font-size: 1.25em;
}
.MarkdownContainer_MarkdownContainer__CDcN- ul, .MarkdownContainer_MarkdownContainer__CDcN- ol {
  padding-left: 1em;
}
.MarkdownContainer_MarkdownContainer__CDcN- ul {
  list-style: circle;
}
.MarkdownContainer_MarkdownContainer__CDcN- ol {
  list-style: decimal;
}
.MarkdownContainer_MarkdownContainer__CDcN- blockquote {
  font-style: italic;
  position: relative;
  padding-left: 2em;
  border-left: rgba(128, 128, 128, 0.6) 2px solid;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}
.MarkdownContainer_MarkdownContainer__CDcN- blockquote::before {
  content: '"';
  color: rgba(128, 128, 128, 0.6);
  position: absolute;
  font-size: 5em;
  top: -3rem;
  left: 0;
}
.MarkdownContainer_MarkdownContainer__CDcN- code {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 2px;
  padding: 0 3px;
  margin: 0 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/container/MarkdownContainer.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAEI;EACE,eAAA;AAAN;AAEI;EAAI,YAAA;AACR;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,cAAA;EACA,cAAA;AADJ;AAIE;EAAK,iBAAA;AADP;AAGE;EACE,iBAAA;AADJ;AAIE;EAAK,kBAAA;AADP;AAEE;EAAK,mBAAA;AACP;AACE;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,+CAAA;EACA,kBAAA;EACA,qBAAA;AACJ;AACI;EACE,YAAA;EACA,+BAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,OAAA;AACN;AAGE;EACE,oCAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;AADJ","sourcesContent":[".MarkdownContainer :global {\n  p, ul, ol, blockquote {\n    margin-top: 1em;\n    margin-bottom: 1em;\n  }\n\n  h1, h2, h3 {\n    font-weight: bold;\n    &:not(:first-child) {\n      margin-top: 2em;\n    }\n    a { color: unset; }\n  }\n\n  a {\n    color: #c7fe70;\n  }\n\n  h1 {\n    font-size: 2em;\n    color: #c7fe70;\n  }\n\n  h2 { font-size:1.25em; }\n\n  ul, ol {\n    padding-left: 1em;\n  }\n\n  ul { list-style: circle; }\n  ol { list-style: decimal; }\n\n  blockquote {\n    font-style: italic;\n    position: relative;\n    padding-left: 2em;\n    border-left: rgba(128,128,128,0.6) 2px solid;\n    margin-top: 2.5rem;\n    margin-bottom: 0.5rem;\n\n    &::before {\n      content:\"\\\"\";\n      color: rgba(128,128,128,0.6);\n      position: absolute;\n      font-size: 5em;\n      top:-3rem;\n      left:0;\n    }\n  }\n\n  code {\n    background: rgba(128,128,128,0.6);\n    border-radius: 2px;\n    padding: 0 3px;\n    margin: 0 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MarkdownContainer": `MarkdownContainer_MarkdownContainer__CDcN-`
};
export default ___CSS_LOADER_EXPORT___;

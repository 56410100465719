import {
    LoginResponse,
    PlayerRegisterResponse,
    PlayerRequest,
    PlayerResponse,
} from "src/types";
import { useAxiosInterceptors } from "src/services/apiConfig";
import { isAxiosError } from "axios";
import { CustomError } from "src/errors/CustomError";
import { UpdatePlayerRequest } from "src/types/Player";
import { useTranslation } from "react-i18next";

const usePlayerService = () => {
    const { t } = useTranslation();
    
    const { axiosInstance, axiosPrivateInstance, axiosLocationInstance } =
        useAxiosInterceptors();

    // Register a new player
    const registerPlayer = async (
        playerData: PlayerRequest
    ): Promise<PlayerRegisterResponse> => {
        try {
            const response = await axiosInstance.post("/players", playerData);
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    // Update player data
    const updatePlayer = async (
        playerId: string,
        newPlayerData: UpdatePlayerRequest
    ): Promise<PlayerResponse> => {
        try {
            const response = await axiosPrivateInstance.put(
                `/players/${playerId}`,
                newPlayerData
            );
            return response.data.result;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    // Player password validation. Returns an authorization token.
    const login = async (
        email: string,
        password: string
    ): Promise<LoginResponse> => {
        try {
            const response = await axiosInstance.post("/login", {
                email: email,
                password: password,
            });
            return response.data;
        } catch (error: any) {
            if (
                isAxiosError(error) &&
                (error.response?.status === 404 ||
                    error.response?.status === 422)
            ) {
                throw new CustomError(
                    "Invalid",
                    t("error_invalid-user-pwd")
                );
            } else {
                throw new Error(error);
            }
        }
    };

    // Check if an email is available for player registration
    const isEmailTaken = async (email: string): Promise<boolean> => {
        try {
            const response = await axiosInstance.get(
                `/players/exists/${email}`
            );
            if (response.status === 200) {
                return true; // Email is taken
            } else if (response.status === 404) {
                return false; // Email is not taken
            } else {
                // Handle other statuses if needed
                throw new Error(`Unexpected status code: ${response.status}`);
            }
        } catch (error: any) {
            if (isAxiosError(error) && error.response?.status === 404) {
                return false; // Email is not taken
            } else {
                // Handle other errors (network errors, unexpected status codes)
                throw new Error(error);
            }
        }
    };

    // Check if an nickname is available for player registration
    const isNicknameTaken = async (nickname: string): Promise<boolean> => {
        try {
            const response = await axiosInstance.get(
                `/players/exists/nickname/${nickname}`
            );
            if (response.status === 200) {
                return true; // nickname is taken
            } else if (response.status === 404) {
                return false; // nickname is not taken
            } else {
                // Handle other statuses if needed
                throw new Error(`Unexpected status code: ${response.status}`);
            }
        } catch (error: any) {
            if (isAxiosError(error) && error.response?.status === 404) {
                return false; // nickname is not taken
            } else {
                throw new Error(error);
            }
        }
    };

    // Locate user location continent using a geo-location API
    const getUserLocationContinent = async (): Promise<string> => {
        try {
            const response = await axiosLocationInstance.get("/json");
            return response.data.continent_code;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    // Get player data by client id
    const getPlayerData = async (
        playerId: string,
        token: string
    ): Promise<any> => {
        try {
            const response = await axiosInstance.get(`/players/${playerId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    return {
        registerPlayer,
        updatePlayer,
        login,
        isEmailTaken,
        isNicknameTaken,
        getUserLocationContinent,
        getPlayerData,
    };
};

export default usePlayerService;

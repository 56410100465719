export const LiveIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.7119 9.52148H3.28809C2.43995 9.52148 1.75 10.2114 1.75 11.0596V17.2119C1.75 18.0601 2.43995 18.75 3.28809 18.75H17.7119C18.5601 18.75 19.25 18.0601 19.25 17.2119V11.0596C19.25 10.2114 18.5601 9.52148 17.7119 9.52148ZM6.39844 16.6992H4.34766C4.06428 16.6992 3.83496 16.4699 3.83496 16.1865V12.085C3.83496 11.8016 4.06428 11.5723 4.34766 11.5723C4.63104 11.5723 4.86036 11.8016 4.86036 12.085V15.6738H6.39844C6.68183 15.6738 6.91114 15.9031 6.91114 16.1865C6.91114 16.4699 6.68183 16.6992 6.39844 16.6992ZM8.96192 16.1865C8.96192 16.4699 8.73261 16.6992 8.44923 16.6992C8.16584 16.6992 7.93653 16.4699 7.93653 16.1865V12.085C7.93653 11.8016 8.16584 11.5723 8.44923 11.5723C8.73261 11.5723 8.96192 11.8016 8.96192 12.085V16.1865ZM13.048 12.2091L12.0226 16.3107C11.9655 16.539 11.7607 16.6992 11.5254 16.6992C11.2901 16.6992 11.0853 16.539 11.0282 16.3107L10.0028 12.2091C9.93376 11.9348 10.101 11.6564 10.3758 11.5878C10.6497 11.5167 10.9286 11.6859 10.9972 11.9608L11.5254 14.0727L12.0536 11.9608C12.1217 11.686 12.4001 11.5162 12.675 11.5878C12.9498 11.6564 13.1171 11.9348 13.048 12.2091ZM16.6524 13.6231C16.9357 13.6231 17.1651 13.8524 17.1651 14.1357C17.1651 14.4191 16.9357 14.6484 16.6524 14.6484H15.1143V15.6738H16.6524C16.9357 15.6738 17.1651 15.9031 17.1651 16.1865C17.1651 16.4699 16.9357 16.6992 16.6524 16.6992H14.6016C14.3182 16.6992 14.0889 16.4699 14.0889 16.1865V12.085C14.0889 11.8016 14.3182 11.5723 14.6016 11.5723H16.6524C16.9357 11.5723 17.1651 11.8016 17.1651 12.085C17.1651 12.3683 16.9357 12.5977 16.6524 12.5977H15.1143V13.6231H16.6524Z" />
        <path d="M5.98777 8.22299C4.49173 6.23027 4.49173 3.4475 5.98777 1.45478C6.15751 1.22899 6.47945 1.18241 6.70576 1.35266C6.93206 1.5229 6.97762 1.84433 6.80788 2.07063C5.58374 3.70036 5.58374 5.97745 6.80788 7.60714C6.97762 7.83344 6.93206 8.15487 6.70576 8.32512C6.48041 8.49417 6.15833 8.45035 5.98777 8.22299Z" />
        <path d="M14.2942 8.32514C14.0679 8.15493 14.0223 7.83346 14.1921 7.60716C15.4162 5.97744 15.4162 3.70035 14.1921 2.07066C14.0223 1.84435 14.0679 1.52293 14.2942 1.35268C14.5205 1.18294 14.8429 1.22902 15.0122 1.45481C16.5082 3.44752 16.5082 6.2303 15.0122 8.22301C14.8419 8.45003 14.52 8.49447 14.2942 8.32514Z" />
        <path d="M7.82473 7.23163C6.60308 5.86779 6.60308 3.81 7.82473 2.44613C8.01398 2.23534 8.33944 2.21832 8.54872 2.40607C8.75951 2.59481 8.77752 2.91928 8.58878 3.13006C7.71659 4.10439 7.71659 5.57337 8.58878 6.54769C8.77752 6.75848 8.75951 7.08291 8.54872 7.27169C8.33866 7.46019 8.01368 7.44303 7.82473 7.23163Z" />
        <path d="M12.4513 7.27158C12.2405 7.08284 12.2225 6.75837 12.4113 6.54759C13.2835 5.57326 13.2835 4.10428 12.4113 3.12996C12.2225 2.91917 12.2405 2.59474 12.4513 2.40596C12.6616 2.2177 12.9865 2.23472 13.1753 2.44602C14.397 3.80986 14.397 5.86765 13.1753 7.23153C12.9864 7.44286 12.6614 7.46012 12.4513 7.27158Z" />
        <path d="M10.5 6.37696C9.65188 6.37696 8.96193 5.68701 8.96193 4.83887C8.96193 3.99073 9.65188 3.30078 10.5 3.30078C11.3482 3.30078 12.0381 3.99073 12.0381 4.83887C12.0381 5.68701 11.3482 6.37696 10.5 6.37696Z" />
      </svg>
    </>
  );
};

export default LiveIcon;

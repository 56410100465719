import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import { GameResponse } from "src/types";
import useGamesService from "src/services/gamesService";
//import useTransactionService from "src/services/transactionService";
import useNotificationsService from "src/services/notificationsService";
import { useAuth } from "src/context/AuthContext";
import { useScroll } from "src/context/ScrollContext";
import { FavouriteButton } from "src/components/molecules";
import Favourifier from "src/components/organisms/favourifier/Favourifier.component";

export interface GameModalProps {
  className?: string;
  selectedGame: GameResponse | null;
  onClose?: () => void;
  onClickPlay?: () => void;
  bets?: { minimum: string; maximum: string };
}

ReactModal.setAppElement("#root");

const GameModal = ({
  className = "",
  selectedGame,
  onClose = () => {},
  onClickPlay = () => {},
}: GameModalProps) => {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();
  const navigate = useNavigate();
  const { launchGame } = useGamesService();
  //const { getCurrencyById } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();
  const { setSkipNext } = useScroll();

  const [isGameLoading, setGameLoading] = useState<boolean>(false);
  const [game, setGame] = useState<GameResponse | null>(selectedGame);
  useEffect(() => {
    setGame(selectedGame);
  }, [selectedGame]);

  const doClose = () => {
    onClose();
    setSkipNext(true);
    setGame(null);
  };

  const doClickPlay = (game: GameResponse) => () => {
    if (!token) {
      navigate(`/login?gameTypeId=casino&gameId=${game?.game_id}`);
      return;
    }
    onClickPlay();
    game ? handlePlayGame(game) : console.warn("Error");
  };

  const handlePlayGame = async (game: GameResponse): Promise<void> => {
    try {
      if (!playerData || !token) {
        return addErrorNotification(t("error_please-login"));
      } else if (!game) {
        return addErrorNotification(t("error_launch-game"));
      }
      /* const currency = await getCurrencyById(playerData.currency_id);
      if (!currency) {
        return addErrorNotification(t("error_get-currency"));
      } */
      setGameLoading(true);
      const {
        result: { play_for_real: url },
      } = await launchGame(token, {
        game_id: game.game_id,
        game_name: game.name,
        is_demo: game.has_demo,
        is_mobile: game.has_mobile,
        player_id: playerData.id,
        provider_id: game.provider_id,
        user_currency: "EUR", //NOTE: Only working with euros (for now...)
        user_language: "en",
      });
      // Open the external URL in a new window centered on the screen
      const width = 1024;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      window.open(
        url,
        "_blank",
        `rel=noreferrer,width=${width},height=${height},top=${top},left=${left}`
      );
    } catch (error: any) {
      addErrorNotification(t("error_launch-game"));
      console.log("Failed to launch game: ", error);
    } finally {
      setGameLoading(false);
    }
  };

  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={!!game}
      onRequestClose={doClose}
      className={`${className} p-[34px] rounded-10 bg-primary-400 lg:w-[849px] w-10/12 absolute max-h-fit lg:top-1/2 !top-20`}
      style={{
        overlay: {
          zIndex: 50,
          backgroundColor: "#1A1A1ACC",
          overflowY: "auto",
        },
        content: {},
      }}
    >
      {game ? (
        <div className="flex flex-col">
          <Favourifier onLoad={(games) => setGame(games[0])} games={[game]} />
          <div className="flex gap-1 justify-end items-center w-full text-greyscale-100">
            <FavouriteButton game={game} />
            <ClearIcon onClick={doClose} className="cursor-pointer w-8 h-8" />
          </div>
          <div className="flex lg:flex-row flex-col gap-11">
            <div className="flex lg:hidden justify-between items-center w-full text-greyscale-100">
              <div className="text-[44px] leading-[48px] font-semibold">
                {game.name}
              </div>
              {/* <div className="flex gap-1 items-center">
                <div
                  className="cursor-pointer select-none"
                  onClick={onClickFavourite}
                >
                  {isFavourite ? <HeartIcon /> : <HeartEmptyIcon />}
                </div>
                <ClearIcon
                  onClick={() => {
                    onRequestClose();
                  }}
                  className="cursor-pointer w-8 h-8"
                />
              </div> */}
            </div>
            <div className="lg:flex-1 self-center">
              <img src={game.image} alt="" className="rounded-10 w-[432px]" />
            </div>
            <div className="lg:flex-1 flex flex-col gap-6 text-greyscale-100 h-full">
              <div className="lg:flex hidden justify-between items-center w-full">
                <div className="lg:text-[44px] leading-[48px] text-[40px] font-semibold">
                  {game.name}
                </div>
              </div>
              <div className="flex flex-col gap-11 h-full">
                <button
                  type="button"
                  className={
                    "flex lg:hidden md:w-5/12 w-full self-center " +
                    "rounded w-[188px] text-greyscale-400 justify-between " +
                    "py-3 px-6 bg-secondary-200 opacity-100 cursor-pointer"
                  }
                  onClick={doClickPlay(game)}
                >
                  {isGameLoading ? (
                    <MoonLoader
                      size={16}
                      color="#2a2a2a"
                      loading={isGameLoading}
                      speedMultiplier={0.7}
                      className="mx-auto"
                    />
                  ) : (
                    <>
                      <span className="whitespace-nowrap mx-auto">
                        {t("button_play-now")}
                      </span>
                      <ArrowRightIcon />
                    </>
                  )}
                </button>
                <div className="break-words">
                  <div className="break-words flex flex-col">
                    <span>
                      {game.has_mobile
                        ? t("text_playable-on-mobile")
                        : t("text_playable-on-desktop")}
                    </span>
                    <span>
                      {game.has_demo
                        ? t("text_game-has-demo")
                        : t("text_no-game-description")}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className={
                    "lg:flex hidden rounded w-[188px] text-greyscale-400 py-3 px-6 " +
                    "justify-between bg-secondary-200 opacity-100 cursor-pointer"
                  }
                  onClick={doClickPlay(game)}
                >
                  {isGameLoading ? (
                    <MoonLoader
                      size={16}
                      color="#2a2a2a"
                      loading={isGameLoading}
                      speedMultiplier={0.7}
                      className="mx-auto"
                    />
                  ) : (
                    <>
                      <span className="whitespace-nowrap mx-auto">
                        {token ? t("button_play-now") : t("button_play-login")}
                      </span>
                      <ArrowRightIcon />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No game info to show</p>
      )}
    </ReactModal>
  );
};

export default GameModal;

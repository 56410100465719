export const initialValues = {
  username: "",
  firstName: "",
  lastName: "",
  gender: "male",
  birthDate: "",
  phoneNumber: "",
  country: "PT",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
  email: "",
  password: "",
  currencyId: "",
  offerEmail: false,
  offerSms: false,
  offerBonus: false,
  twitter: "",
  instagram: "",
  documentNumber: "",
  code: "",
  areaCode: "+351",
  dailyLimit: "",
  weeklyLimit: "",
  monthlyLimit: "",
};

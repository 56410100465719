// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputDate_InputDate__\\+DhOg :disabled {
  opacity: 0.6;
  appearance: textfield;
  --tw-border-opacity: 0.1;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/inputFields/inputDate/InputDate.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,qBAAA;EACA,wBAAA;AAAJ","sourcesContent":[".InputDate {\n  :disabled {\n    opacity: 0.6;\n    appearance: textfield;\n    --tw-border-opacity: 0.1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputDate": `InputDate_InputDate__+DhOg`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef } from "react";
import { useAuth } from "src/context/AuthContext";
import usePlayerSessionService from "src/services/playerSessionService";
import Cookies from "js-cookie";

export default function CheckTokens() {
  const { playerData, logout, setToken, setRefreshToken, setPlayerData } =
    useAuth();
  const { refresh } = usePlayerSessionService();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const checkTokens = () => {
      const token = Cookies.get("token");
      const refreshToken = Cookies.get("refreshToken");
      if (!token && !refreshToken && playerData) {
        logout();
      }
      if (!token && refreshToken && playerData) {
        refresh(playerData.id)
          .then((res) => {
            setToken(res.token);
            setRefreshToken(res.refresh_token);
            setPlayerData(res.player);
          })
          .catch((err) => console.error(err));
      }
    };

    checkTokens();

    // Set up the interval to run checkTokens every second
    intervalRef.current = setInterval(checkTokens, 1000);

    // Cleanup interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [Cookies.get("token"), Cookies.get("refreshToken")]);

  return <></>;
}

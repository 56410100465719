import { Link } from "react-router-dom";
import { /* useEffect, */ useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  GameCard2,
  /* MultipleSelectDropdownOptionProps, */
  NavBar,
  Table,
} from "src/components/molecules";
// import { getNumberOfItemsPerWidth } from "src/utils/sizeFunctions";
import {
  FAQ,
  Filter,
  Footer,
  GameList,
  Header,
  SplideSlider,
} from "src/components/organisms";
import { NavLink } from "src/components/atoms";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as NewGamesIcon } from "src/assets/icons/nav/newGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
import { ReactComponent as LiveNowIcon } from "src/assets/icons/nav/liveNOW.svg";
import { ReactComponent as FavouritesIcon } from "src/assets/icons/nav/favourtites.svg";
import { ReactComponent as SkeletonGrid } from "src/assets/icons/loaders/All Slot Games.svg";
import { ReactComponent as SkeletonSlider } from "src/assets/icons/loaders/Most Popular.svg";
import { ReactComponent as SkeletonNavBar } from "src/assets/icons/loaders/tabs_filterGames.svg";
import { ReactComponent as SkeletonFilter } from "src/assets/icons/loaders/filter_quickFilter.svg";
import GameModal, {
  GameProps,
} from "src/components/molecules/gameModal/GameModal.component";
import ReactGA from "react-ga4";
import useGameService from "src/services/gameService";
import useNotificationsService from "src/services/notificationsService";
import { useAuth } from "src/context/AuthContext";
import useTransactionService from "src/services/transactionService";
import { tempGames } from "src/utils/tempGames";
import { GameResponse } from "src/types";

export default function GamesPage() {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [sliderGameCards, setSliderGameCards] = useState<JSX.Element[]>([]);
  const [gameModalData, setGameModalData] = useState<GameProps | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { gameTypeId } = useParams<{ gameTypeId: string }>();
  const { playerData } = useAuth();

  const { getGames, launchGame } = useGameService();
  const { addErrorNotification } = useNotificationsService();
  const { getCurrencyById } = useTransactionService();

  const capitalizeWords = (str: string): string => {
    return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
  };

  const formattedGameTypeId = capitalizeWords(
    gameTypeId?.replace(/-/g, " ") || ""
  );

  const handleFavoriteToggle = (id: string | number): void => {
    alert(
      "The favourites service is still under development. Thank for your comprehension."
    );
    console.log(`Favourite toggle for game with id: ${id}`);
  };

  const handlePlayGame = async (game: GameProps): Promise<void> => {
    try {
      if (!playerData) {
        return addErrorNotification(t("error_please-login"));
      } else {
        const { result: currency } = await getCurrencyById(
          playerData.currency_id
        );
        if (!currency) {
          return addErrorNotification(t("error_get-currency"));
        }
        const {
          result: { play_for_real: url },
        } = await launchGame({
          game_id: game.game_id,
          game_name: game.game_name,
          is_demo: game.is_demo,
          is_mobile: game.is_mobile,
          player_id: playerData.id,
          provider_id: game.provider_id,
          user_currency: currency.alphabetic_code,
          user_language: "en",
        });
        // Open the external URL in a new window centered on the screen
        const width = 1024;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          url,
          "_blank",
          `width=${width},height=${height},top=${top},left=${left}`
        );
      }
    } catch (error: any) {
      addErrorNotification(t("error_launch-game"));
      console.log("Failed to launch game: ", error);
    }
  };

  const handleSetGameModalData = (game: GameResponse) => {
    setGameModalData({
      name: game.name,
      description: (
        <div className="break-words flex flex-col">
          <span>
            {game.has_mobile
              ? t("text_playable-on-mobile")
              : t("text_playable-on-desktop")}
          </span>
          <span>
            {game.has_demo
              ? t("text_game-has-demo")
              : t("text_no-game-description")}
          </span>
        </div>
      ),
      image: game.image,
      bets: {
        minimum: "2",
        maximum: "250",
      },
      game_id: game.id,
      game_name: game.name,
      is_demo: game.has_demo,
      is_mobile: game.has_mobile,
      provider_id: game.provider_id,
    });
    setModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchGames = async () => {
      let gameType = "";
      switch (gameTypeId) {
        case "poker":
          gameType = "table-games";
          break;
        case "casino":
          gameType = "live-casino-table";
          break;
        case "slots":
          gameType = "video-slots";
          break;
        default:
          gameType = "";
          break;
      }
      try {
        const { result: games } = await getGames();
        const filteredGames = games.filter((game) => game.type === gameType);
        const isMobile = window.innerWidth < 1024;
        setSliderGameCards(
          filteredGames
            .filter((game) => (isMobile ? game.has_mobile : !game.has_mobile))
            .filter((game) => tempGames.includes(game.id))
            .map((game, index) => (
              <GameCard2
                id={game.id}
                key={`game-card-${index}`}
                name={game.name}
                image={game.image}
                isFavourite={false}
                onClickFavourite={() => handleFavoriteToggle(game.id)}
                isHot={false}
                playersOnline={0}
                openModal={() => {
                  setGameModalData({
                    name: game.name,
                    description: (
                      <div className="break-words flex flex-col">
                        <span>
                          {game.has_mobile
                            ? t("text_playable-on-mobile")
                            : t("text_playable-on-desktop")}
                        </span>
                        <span>
                          {game.has_demo
                            ? t("text_game-has-demo")
                            : t("text_no-game-description")}
                        </span>
                      </div>
                    ),
                    image: game.image,
                    bets: {
                      minimum: "2",
                      maximum: "250",
                    },
                    game_id: game.id,
                    game_name: game.name,
                    is_demo: game.has_demo,
                    is_mobile: game.has_mobile,
                    provider_id: game.provider_id,
                  });
                  setModalOpen(true);
                }}
              />
            ))
        );
      } catch (error: any) {
        addErrorNotification(t("error_fetching-games"));
        console.log("Failed to fetch games: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, [gameTypeId]);

  const tableItems = [
    {
      player: {
        name: "Smake",
        image: "/images/table/pfp1.png",
        lastPlayed: new Date(),
      },
      game: {
        name: "Smake",
        image: "/images/table/sugar_rush.png",
      },
      bet: 15,
      win: 125,
    },
    {
      player: {
        name: "Smake",
        image: "/images/table/pfp1.png",
        lastPlayed: new Date(),
      },
      game: {
        name: "Smake",
        image: "/images/table/sugar_rush.png",
      },
      bet: 15,
      win: 125,
    },
    {
      player: {
        name: "Smake",
        image: "/images/table/pfp1.png",
        lastPlayed: new Date(),
      },
      game: {
        name: "Smake",
        image: "/images/table/sugar_rush.png",
      },
      bet: 15,
      win: 125,
    },
    {
      player: {
        name: "Smake",
        image: "/images/table/pfp1.png",
        lastPlayed: new Date(),
      },
      game: {
        name: "Smake",
        image: "/images/table/sugar_rush.png",
      },
      bet: 15,
      win: 125,
    },
  ];

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Games Page",
  });

  return (
    <div className="font-main w-full">
      <Header onSearchResultItemClick={handleSetGameModalData} />
      {/* Main section */}
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient w-full">
        {/* NavBar section*/}
        <div className="flex gap-10 md:py-12 py-6 w-full">
          <div className="hidden lg:block">
            {isLoading ? (
              <SkeletonNavBar />
            ) : (
              <NavBar
                orientation="vertical"
                className="justify-center items-center text-primary-400 gap-3.5 text-base sticky top-32 left-0 shadow-md"
              >
                {/* <Link to={"/games/hot"}>
                <NavLink
                  className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                    gameTypeId === "hot" ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <HotGamesIcon />
                  {t("link_hot-games")}
                </NavLink>
              </Link>
              <Link to={"/games/new"}>
                <NavLink
                  className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                    gameTypeId === "new" ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <NewGamesIcon />
                  {t("link_new-games")}
                </NavLink>
              </Link> */}
                <Link to={"/games/casino"}>
                  <NavLink
                    className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                      gameTypeId === "casino" ? "opacity-100" : "opacity-30"
                    }`}
                  >
                    <CasinoGamesIcon />
                    {t("link_casino-games")}
                  </NavLink>
                </Link>
                <Link to={"/games/poker"}>
                  <NavLink
                    className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                      gameTypeId === "poker" ? "opacity-100" : "opacity-30"
                    }`}
                  >
                    <PokerGamesIcon />
                    {t("link_poker-games")}
                  </NavLink>
                </Link>
                <Link to={"/games/slots"}>
                  <NavLink
                    className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                      gameTypeId === "slots" ? "opacity-100" : "opacity-30"
                    }`}
                  >
                    <SlotsColouredIcon />
                    {t("button_header_slots")}
                  </NavLink>
                </Link>
                {/* <Link to={"/games/live"}>
                <NavLink
                  className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                    gameTypeId === "live" ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <LiveNowIcon />
                  {t("link_live-now")}!
                </NavLink>
              </Link>
              <Link to={"/games/favourites"}>
                <NavLink
                  className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
                    gameTypeId === "favourites" ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <FavouritesIcon />
                  {t("link_favourites")}
                </NavLink>
              </Link> */}
              </NavBar>
            )}
          </div>
          <div className="w-full flex flex-col gap-16">
            <div className="flex flex-col gap-4">
              <h1 className="text-[34px] text-greyscale-100 font-bold">
                <p className="capitalize">{gameTypeId?.replace(/-/g, " ")}</p>
              </h1>
              {isLoading ? <SkeletonFilter /> : <Filter />}
            </div>
            <GameModal
              isModalOpen={isModalOpen}
              onRequestClose={() => setModalOpen(false)}
              game={gameModalData}
              isFavourite={false}
              onClickFavourite={() => handleFavoriteToggle(0)}
              onClickPlay={() =>
                gameModalData
                  ? handlePlayGame(gameModalData)
                  : console.warn("Error")
              }
            />
            {isLoading ? (
              <SkeletonGrid />
            ) : (
              <GameList
                title={`${t("text_all-games-of")} ${formattedGameTypeId}`}
                buttonTitle={`View ${t(
                  "text_all-games-of"
                )} ${formattedGameTypeId}`}
                rows={3}
                items={sliderGameCards.slice(0, 15)}
                className="lg:block hidden"
                gameType={gameTypeId}
              />
            )}

            {/* <GameList
              title="Most Popular"
              buttonTitle="View All Popular Games"
              items={sliderGameCards.slice(0, 5)}
              className="lg:block hidden"
            /> */}
            {/* <GameList
              title="Recently Played By You"
              buttonTitle="Recently Played Games"
              items={sliderGameCards.slice(0, 5)}
              className="lg:block hidden"
            /> */}
            <div className="flex flex-col gap-10 lg:hidden md:w-[calc(100vw-54px)] w-[calc(100vw-32px)]">
              {isLoading ? (
                <SkeletonSlider />
              ) : (
                <SplideSlider
                  sliderHeaderContent={`${t(
                    "text_all-games-of"
                  )} ${formattedGameTypeId}`}
                  contentList={sliderGameCards}
                  className="lg:hidden block"
                />
              )}

              {/* <SplideSlider
                sliderHeaderContent={"Most Popular"}
                contentList={sliderGameCards}
                className="lg:hidden block"
              /> */}
              {/* <SplideSlider
                sliderHeaderContent={"Recently Played By You"}
                contentList={sliderGameCards}
                className="lg:hidden block"
              /> */}
            </div>
            {/* <Table items={tableItems} title="Winners Board" /> */}
            <div className="md:px-[173px]">
              <FAQ />
            </div>
            <section className="flex flex-col md:px-64 md:py-20 text-sm items-center text-center text-greyscale-100 gap-8">
              <b>{t("text_gambling-aware-2")}</b>
              <p>{t("text-copyright")}</p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

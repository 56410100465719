import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Grid } from "@splidejs/splide-extension-grid";
import "@splidejs/react-splide/css";
import { useTranslation } from "react-i18next";
import { ReactComponent as RightChevronIcon } from "src/assets/icons/arrows/Chevron Right.svg";
import { ReactComponent as LeftChevronIcon } from "src/assets/icons/arrows/Chevron Left.svg";
import { useRef } from "react";

export interface SplideSliderProps {
  sliderId?: string;
  className?: string;
  sliderHeaderContent?: JSX.Element | string;
  contentList?: Array<JSX.Element>;
  hasArrows?: boolean;
}

export const SplideSlider = ({
  sliderId = "splide-slider",
  className = "",
  sliderHeaderContent,
  contentList,
  hasArrows = true,
}: SplideSliderProps) => {
  const { t } = useTranslation();
  const buttonLeftRef = useRef<HTMLButtonElement>(null);
  const buttonRightRef = useRef<HTMLButtonElement>(null);

  const handleLeftClick = () => {
    if (buttonLeftRef.current) {
      buttonLeftRef.current.click();
    }
  };

  const handleRightClick = () => {
    if (buttonRightRef.current) {
      buttonRightRef.current.click();
    }
  };

  return contentList ? (
    <>
      <Splide
        id={sliderId}
        hasTrack={false}
        tag="section"
        extensions={{ Grid }}
        options={{
          pagination: false,
          autoWidth: true,
          arrows: hasArrows,
          grid: {
            rows: 2,
            cols: 5,
            gap: {
              row: "1rem",
              col: "1rem",
            },
          },
          breakpoints: {
            1900: {
              grid: {
                rows: 2,
                cols: 4,
              },
            },
            1536: {
              grid: {
                rows: 2,
                cols: 3,
              },
            },
            1280: {
              grid: {
                rows: 2,
                cols: 2,
              },
            },
            768: {
              grid: false,
              perPage: 2,
              gap: "4rem",
            },
          },
        }}
        className={className}
      >
        <span className="md:hidden block text-[28px] font-bold text-greyscale-100">
          {sliderHeaderContent}
        </span>
        <div
          className={`${
            hasArrows
              ? "md:flex flex-row justify-between items-center md:visible invisible md:z-0 -z-10"
              : "hidden"
          }`}
        >
          <span className="text-[28px] font-bold">{sliderHeaderContent}</span>
          <div className="splide__arrows flex flex-row gap-2">
            <button className="rounded-[4px] bg-[#2A253A] px-2 h-6 text-xs text-greyscale-100 whitespace-nowrap">
              {t("button_slider")}
            </button>
            <button
              className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] splide__arrow--prev !left-auto group"
              ref={buttonLeftRef}
            >
              <RightChevronIcon className="text-greyscale-100" />
            </button>
            <button
              className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] relative splide__arrow--next !right-auto"
              ref={buttonRightRef}
            >
              <RightChevronIcon className="text-greyscale-100" />
            </button>
          </div>
        </div>
        <SplideTrack>
          {contentList.map((content, index) => (
            <SplideSlide key={index}>{content}</SplideSlide>
          ))}
        </SplideTrack>
        <div
          className={`${
            hasArrows
              ? "flex flex-row justify-between gap-2 md:hidden pt-4"
              : "hidden"
          }`}
        >
          <button
            className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] !left-auto group"
            onClick={handleLeftClick}
          >
            <LeftChevronIcon className="text-greyscale-100" />
          </button>
          <button className="rounded-[4px] bg-[#2A253A] px-2 h-6 text-xs text-greyscale-100 whitespace-nowrap">
            {t("button_slider")}
          </button>
          <button
            className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] relative !right-auto"
            onClick={handleRightClick}
          >
            <RightChevronIcon className="text-greyscale-100" />
          </button>
        </div>
      </Splide>
    </>
  ) : (
    <p>No items</p>
  );
};

export default SplideSlider;

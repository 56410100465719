export interface ButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  className?: string;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

export const Button = ({
  type = "button",
  onClick,
  className = "bg-transparent text-black border border-black rounded",
  isDisabled = false,
  children,
}: ButtonProps) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      console.warn(`No click handler defined for button.`);
    }
  };

  return (
    <button
      type={type}
      className={`${className} ${isDisabled && "cursor-not-allowed"}`}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;

import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { InputText } from "src/components/molecules";
import useNotificationsService from "src/services/notificationsService";
import * as Yup from "yup";

interface ForgotPwdModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ForgotPwdModal({
  isOpen,
  onClose,
}: ForgotPwdModalProps) {
  const { t } = useTranslation();
  const { addInfoNotification } = useNotificationsService();

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    isOpen && (
      <div
        className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
        onClick={handleOverlayClick}
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="bg-greyscale-100 px-6 pt-10 md:pb-28 pb-10 rounded-[20px] shadow-lg relative overflow-hidden max-w-3xl w-full md:h-fit h-screen"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between pb-12 items-center">
            <h1 className="text-[40px] leading-[48px] text-primary-400 mx-auto">
              {t("text_forgot-password")}
            </h1>
            <CloseIcon
              className="text-greyscale-300 cursor-pointer"
              onClick={handleOnClose}
            />
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(t("error_invalid-email"))
                .required(t("error_required")),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              alert("Service is currently unavailable");
              console.log(values);
              setSubmitting(false);
              handleOnClose();
              addInfoNotification(t("info_email-sent"));
            }}
          >
            {({ isSubmitting }) => (
              <Form className="md:px-48 sm:px-36">
                <div className="flex flex-col gap-5">
                  <InputText name="email" label="Email address" type="email" />
                </div>
                <div className="mt-12" />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex rounded justify-center w-full py-3 px-6 mb-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 mt-2 transition duration-300"
                >
                  {isSubmitting ? t("button_submitting") : t("button_submit")}
                </button>
              </Form>
            )}
          </Formik>
        </motion.div>
      </div>
    )
  );
}

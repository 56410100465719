import { createBrowserRouter } from "react-router-dom";
import { PrivateRoute } from "src/components/atoms";
import AccountPage from "src/pages/AccountPage";
import VerificationPage from "src/pages/VerificationPage";
import AllGamesPage from "src/pages/AllGamesPage";
import GamesPage from "src/pages/GamesPage";
import HomePage from "src/pages/HomePage";
import LoginPage from "src/pages/LoginPage";
import MiraclLoginRedirectPage from "src/pages/MiraclLoginRedirectPage";
import MiraclRegisterRedirectPage from "src/pages/MiraclRegisterRedirectPage";
import NotFoundPage from "src/pages/NotFoundPage";
import RedirectClosePage from "src/pages/RedirectClosePage";
import RegisterPage from "src/pages/RegisterPage";
import ResetPasswordPage from "src/pages/ResetPasswordPage";
import TermsAndConditionsPage from "src/pages/TermsAndConditionsPage";
import UnderConstructionPage from "src/pages/UnderConstructionPage";
import WithdrawalPage from "src/pages/WithdrawalPage";
import MarkdownPage from "src/pages/MarkdownPage";
import { ScrollProvider } from "src/context/ScrollContext";

/* const mcl = new MIRACLTrust({
  projectId: "19dfb79e-cf95-40c8-a260-3c7dd5bbe198",
  seed: "asdf",
  userStorage: localStorage,
  deviceName: navigator.userAgent,
  cors: true,
}); */

const router = createBrowserRouter([
  {
    element: <ScrollProvider />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/games/:gameTypeId?/:gameId?",
        element: <GamesPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/all-games/:gameTypeId?/:gameId?",
        element: <AllGamesPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/register",
        element: <RegisterPage /* mcl={mcl}  */ />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/login",
        element: <LoginPage /* mcl={mcl}  */ />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/reset-password/:email/:resetToken",
        element: <ResetPasswordPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/affiliates",
        element: <MarkdownPage mdNamespace="affiliates" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/faqs",
        element: <MarkdownPage mdNamespace="faqs" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/contact-us",
        element: <MarkdownPage mdNamespace="contact-us" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage itemId="terms-and-conditions" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/bonus-terms",
        element: <TermsAndConditionsPage itemId="bonus-terms" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/cookie-policy",
        element: <TermsAndConditionsPage itemId="cookie-policy" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/banking",
        element: <TermsAndConditionsPage itemId="banking" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/responsible-gaming",
        element: <TermsAndConditionsPage itemId="responsible-gaming" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/privacy",
        element: <TermsAndConditionsPage itemId="privacy" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/aml-and-kyc",
        element: <MarkdownPage mdNamespace="aml-and-kyc" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/under-construction",
        element: <UnderConstructionPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/account",
        element: <PrivateRoute minWidth={1024} fallbackPath="/" />,
        children: [
          {
            path: "",
            element: <AccountPage />,
            errorElement: <NotFoundPage />,
          },
        ],
        errorElement: <NotFoundPage />,
      },
      {
        path: "/about-us",
        element: <MarkdownPage mdNamespace="about-us" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/redirect-close",
        element: <RedirectClosePage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/withdrawal",
        element: <WithdrawalPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/miracl-login-redirect",
        element: <MiraclLoginRedirectPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/miracl-register-redirect",
        element: <MiraclRegisterRedirectPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/verification/:email/:verificationToken",
        element: <VerificationPage />,
        errorElement: <NotFoundPage />,
      },
    ],
  },
]);

export default router;

import moment from "moment";

export const getDurationToNow = (startDate: Date) => {
    const minutes = moment.duration(moment().diff(startDate)).asMinutes();

    const minutesInYear = 525600; // 365 days
    const minutesInMonth = 43800; // 30.44 days
    const minutesInDay = 1440; // 24 hours
    const minutesInHour = 60; // 1 hour

    const years = Math.floor(minutes / minutesInYear);
    const months = Math.floor((minutes % minutesInYear) / minutesInMonth);
    const days = Math.floor((minutes % minutesInMonth) / minutesInDay);
    const hours = Math.floor((minutes % minutesInDay) / minutesInHour);
    const mins = Math.floor( minutes % minutesInHour);

    if (years > 0) {
        return `${years}year${years > 1 ? "s" : ""} ago`;
    }

    if (months > 0) {
        return `${months}month${months > 1 ? "s" : ""} ago`;
    }

    if (days > 0) {
        return `${days}day${days > 1 ? "s" : ""} ago`;
    }

    if (hours > 0) {
        return `${hours}hr${hours > 1 ? "s" : ""} ago`;
    }

    if (minutes > 1) {
        return `${mins}min${mins > 1 ? "s" : ""} ago`;
    }

    return `< 1 min ago`;
};

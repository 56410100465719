import BrotherBets2Logo from "./BrotherBets2Logo";
import BrotherBets3Logo from "./BrotherBets3Logo";
import BrotherBets4Logo from "./BrotherBets4Logo";
import BrotherBetsLogo from "./BrotherBetsLogo";
import BrotherBetsMobileLogo from "./BrotherBetsMobileLogo";
import BurgerIcon from "./BurgerIcon";
import MastercardIcon from "./cards/MastercardIcon";
import VisaIcon from "./cards/VisaIcon";
import CasinoGamesIcon from "./CasinoGamesIcon";
import CasinoIcon from "./CasinoIcon";
import CelebrationIcon from "./CelebrationIcon";
import EditIcon from "./EditIcon";
import FavouritesIcon from "./FavouritesIcon";
import HeartEmptyIcon from "./HeartEmptyIcon";
import HeartIcon from "./HeartIcon";
import Home2Icon from "./Home2Icon";
import HotGamesIcon from "./HotGamesIcon";
import LiveIcon from "./LiveIcon";
import LiveNowIcon from "./LiveNowIcon";
import NewGamesIcon from "./NewGamesIcon";
import NotFoundIcon from "./NotFoundLogo";
import PeopleIcon from "./PeopleIcon";
import PokerGamesIcon from "./PokerGamesIcon";
import PokerIcon from "./PokerIcon";
import PromotionIcon from "./PromotionIcon";
import RightArrowIcon from "./RightArrowIcon";
import SimpleFlameIcon from "./SimpleFlameIcon";
import SlotsColouredIcon from "./SlotsColouredIcon";
import SlotsIcon from "./SlotsIcon";
import UserSquareIcon from "./UserSquareIcon";
import WalletIcon from "./WalletIcon";

export {
    BrotherBets2Logo,
    BrotherBets3Logo,
    BrotherBets4Logo,
    BrotherBetsLogo,
    BrotherBetsMobileLogo,
    BurgerIcon,
    CasinoGamesIcon,
    CasinoIcon,
    CelebrationIcon,
    EditIcon,
    FavouritesIcon,
    HeartEmptyIcon,
    HeartIcon,
    Home2Icon,
    HotGamesIcon,
    LiveIcon,
    LiveNowIcon,
    MastercardIcon,
    NewGamesIcon,
    NotFoundIcon,
    PeopleIcon,
    PokerGamesIcon,
    PokerIcon,
    PromotionIcon,
    RightArrowIcon,
    SimpleFlameIcon,
    SlotsColouredIcon,
    SlotsIcon,
    UserSquareIcon,
    VisaIcon,
    WalletIcon,
};

import { useAxiosInterceptors } from "./apiConfig";
import { FavouriteRequest } from "src/types/Favourite";
import { GameResponse } from "src/types";
import { GameUid } from "src/types/Game";
import { GameType } from "src/types/GameType";

const useFavouritesService = () => {
  const { axiosHelperInstance } = useAxiosInterceptors();

  const getFavourites = async (
    playerId: string,
    token: string,
    gameTypeId?: string
  ): Promise<GameResponse[]> => {
    // @ts-ignore
    const gameType = GameType[gameTypeId]?.type;
    try {
      const response = await axiosHelperInstance.get(
        `/favourites/${playerId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { gameType },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // gives back a copy of the game list, adding is_favorite to every game
  // according to the player's favorites
  const favourify = async (
    playerId: string,
    token: string,
    games: GameResponse[]
  ): Promise<GameResponse[]> => {
    try {
      const restRequest = games.map((game) => ({
        provider_id: game.provider_id,
        game_id: game.game_id,
      }));
      // this would have been a GET were it not for axios
      const response = await axiosHelperInstance.post(
        `/favourites/${playerId}/verify`,
        restRequest,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const toKey = (uid: GameUid) => uid.provider_id + "." + uid.game_id;
      // { 'someprovider.somegameid': true,
      //   'someprovider.othergameid': false, ... }
      const mappedFavourites = response.data.reduce(
        (acc: { [key: string]: boolean }, uid: GameUid) => {
          return {
            ...acc,
            [toKey(uid)]: uid.is_favourite,
          };
        },
        {}
      );
      return games.map((game) => ({
        ...game,
        is_favourite: mappedFavourites[toKey(game)],
      }));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const addFavourite = async (
    token: string,
    request: FavouriteRequest
  ): Promise<void> => {
    try {
      await axiosHelperInstance.post("/favourites", request, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const deleteFavourite = async (
    token: string,
    request: FavouriteRequest
  ): Promise<void> => {
    try {
      const restRequest = {
        headers: { Authorization: `Bearer ${token}` },
        data: { ...request },
      };
      await axiosHelperInstance.delete("/favourites", restRequest);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { getFavourites, favourify, addFavourite, deleteFavourite };
};

export default useFavouritesService;

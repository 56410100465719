// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TCLink_TCLink__8bc2e {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TCLink_TCLink__8bc2e:after {
  box-sizing: border-box;
  display: block;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/link/TCLink.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,MAAA;EACA,QAAA;AACF","sourcesContent":[".TCLink :global {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.TCLink:after {\n  box-sizing: border-box;\n  display: block;\n  top: 0;\n  right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TCLink": `TCLink_TCLink__8bc2e`
};
export default ___CSS_LOADER_EXPORT___;

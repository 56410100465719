import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as UserIcon } from "src/assets/icons/user/single.svg";
import { ReactComponent as BrotherBetsLogo } from "src/assets/icons/logos/BB_logo_inline_dark.svg";
import { ReactComponent as BrotherBetsMenuLogo } from "src/assets/icons/logos/BB_logo_small.svg";
import { ReactComponent as MenuLogo } from "src/assets/icons/menu/Menu.svg";
import { ReactComponent as LiveIcon } from "src/assets/icons/nav/Live.svg";
import { ReactComponent as CasinoIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as PokerIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { ReactComponent as PromotionIcon } from "src/assets/icons/nav/Promos.svg";
import { ReactComponent as RegisterIcon } from "src/assets/icons/user/add.svg";
import { ReactComponent as HeaderSkel } from "src/assets/icons/loaders/Navigation_Desktop.svg";
import { Button, NavLink } from "src/components/atoms";
import { NavBar, SearchField, Select } from "src/components/molecules";
import { MobileMenu } from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import usePlayerSessionService from "src/services/playerSessionService";
import { GameResponse } from "src/types";
import { useGames } from "src/context/GamesContext";
import useNotificationsService from "src/services/notificationsService";

interface HeaderProps {
  onSearchResultItemClick?: (game: GameResponse) => void;
}

export const Header = ({ onSearchResultItemClick }: HeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<GameResponse[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, playerData, logout } = useAuth();
  const { games, isLoading } = useGames();
  const { logout: serverLogout } = usePlayerSessionService();
  const { addErrorNotification } = useNotificationsService();

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      // Call server to logout
      if (playerData?.id) {
        await serverLogout(playerData?.id);
      }
      logout();
      navigate("/");
    } catch (error) {
      addErrorNotification(t("error_logout"));
      console.error("Error logging out: ", error);
    }
  };

  const handleSearch = (query: string) => {
    if (query.trim() === "") {
      setSearchResults([]);
    } else {
      const results = games.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    }
  };

  return isLoading ? (
    <HeaderSkel />
  ) : (
    <header className="flex lg:justify-between items-center bg-greyscale-400 py-4 px-6 xl:px-[84px] px-12 sticky top-0 left-0 shadow-md z-50 w-full">
      <div className="lg:hidden">
        <Button className="self-start" onClick={openMenu}>
          <MenuLogo />
        </Button>
      </div>
      <div className="lg:pt-2 flex justify-center w-full lg:w-auto">
        <Link to={"/"} className="hidden 2xl:block w-[222px] h-[30px]">
          <BrotherBetsLogo />
        </Link>
        <Link to={"/"} className="2xl:hidden">
          <BrotherBetsMenuLogo />
        </Link>
      </div>
      <NavBar className="justify-center items-center py-4 xl:gap-8 gap-4 text-sm leading-6 hidden lg:flex">
        {/* <Link to={"/games/live"}>
          <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
            <LiveIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
            {t("button_header_live")}
          </NavLink>
        </Link> */}
        <Link to={"/games/casino"}>
          <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
            <CasinoIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
            {t("button_header_casino")}
          </NavLink>
        </Link>
        <Link to={"/games/poker"}>
          <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
            <PokerIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
            {t("button_header_poker")}
          </NavLink>
        </Link>
        <Link to={"/games/slots"}>
          <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
            <SlotsIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
            {t("button_header_slots")}
          </NavLink>
        </Link>
        <span className="bg-greyscale-100 w-px h-6"></span>
        <Link to={"/under-construction"}>
          <NavLink className="gap-2 group text-tertiary-400 hover:text-secondary-200 transition duration-300">
            <PromotionIcon className="text-tertiary-400 group-hover:text-secondary-200 transition duration-300" />
            {t("button_header_promotions")}
          </NavLink>
        </Link>
      </NavBar>
      <div className="lg:flex xl:gap-4 gap-2 justify-center items-center hidden">
        <SearchField
          onSearch={handleSearch}
          results={searchResults}
          onSearchResultItemClick={onSearchResultItemClick}
        />
        <div className="flex items-center gap-3.5">
          <Select />
          {!token && (
            <Button
              className="px-5 py-3.5 bg-transparent text-sm leading-6 text-greyscale-100 border border-greyscale-100 rounded hover:text-greyscale-400 hover:bg-greyscale-100 transition duration-300"
              key={"login-button"}
              onClick={() => navigate("/login")}
              type="button"
            >
              <span className="whitespace-nowrap">
                {t("button_header_login")}
              </span>
            </Button>
          )}
          {!token && (
            <>
              <Button
                className="flex justify-center items-center px-5 py-3.5 gap-4 bg-secondary-200 text-sm leading-6 text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
                key={"sign-up-button"}
                onClick={() => navigate("/register")}
                type="button"
              >
                <span className="whitespace-nowrap xl:block hidden">
                  {t("button_header_signup")}
                </span>
                <ArrowRightIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300 xl:block hidden" />
                <RegisterIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300 xl:hidden block" />
              </Button>
            </>
          )}
          {token && (
            <>
              <Button
                className="px-5 py-3.5 bg-transparent text-sm leading-6 text-greyscale-100 border border-greyscale-100 rounded hover:text-greyscale-400 hover:bg-greyscale-100 transition duration-300"
                key={"logout-button"}
                onClick={handleLogout}
                type="button"
              >
                {t("button_header_logout")}
              </Button>
              <Link
                to={"/account"}
                className="flex items-center gap-3.5 px-3.5 py-1 text-greyscale-100 font-semibold group"
              >
                <span className="rounded-full min-w-[34px] min-h-[34px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center">
                  {playerData?.first_name?.charAt(0).toUpperCase()}
                  {playerData?.last_name?.charAt(0).toUpperCase()}
                </span>
                <span className="whitespace-nowrap text-sm leading-6 group-hover:underline hidden xl:block">
                  {t("link_header_my-account")}
                </span>
              </Link>
            </>
          )}
        </div>
      </div>
      {!token && (
        <Button
          className="flex justify-center items-center p-2 gap-4 bg-secondary-200 text-sm text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group lg:hidden transition duration-300"
          key={"sign-up-button"}
          onClick={() => navigate("/register")}
          type="button"
        >
          <UserIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300" />
        </Button>
      )}

      <MobileMenu isOpen={isMenuOpen} onClose={closeMenu} />
    </header>
  );
};

export default Header;

export const PromotionIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_59_4232"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <rect x="0.5" width="20" height="20" />
        </mask>
        <g mask="url(#mask0_59_4232)">
          <path d="M9.16699 12.271L10.5 11.271L11.771 12.25C11.9377 12.3753 12.1077 12.3787 12.281 12.26C12.455 12.142 12.5073 11.986 12.438 11.792L11.938 10.188L13.25 9.14599C13.4307 9.00733 13.4827 8.83366 13.406 8.62499C13.33 8.41633 13.1807 8.31199 12.958 8.31199H11.438L10.917 6.70799C10.847 6.51399 10.708 6.41699 10.5 6.41699C10.292 6.41699 10.153 6.51399 10.083 6.70799L9.56199 8.31199H8.02099C7.79899 8.31199 7.64966 8.41633 7.57299 8.62499C7.49633 8.83366 7.54833 9.00733 7.72899 9.14599L8.99999 10.188L8.49999 11.792C8.43066 11.986 8.48266 12.1457 8.65599 12.271C8.82999 12.3957 9.00033 12.3957 9.16699 12.271ZM10.5 18.271C10.4167 18.271 10.3403 18.2673 10.271 18.26C10.2017 18.2533 10.132 18.236 10.062 18.208C8.24266 17.6387 6.74966 16.5137 5.58299 14.833C4.41633 13.1523 3.83299 11.2983 3.83299 9.27099V5.35399C3.83299 4.99266 3.93733 4.66299 4.14599 4.36499C4.35399 4.06633 4.63166 3.85433 4.97899 3.72899L9.89599 1.89599C10.0907 1.82666 10.292 1.79199 10.5 1.79199C10.708 1.79199 10.9093 1.82666 11.104 1.89599L16.021 3.72899C16.3683 3.85433 16.646 4.06633 16.854 4.36499C17.0627 4.66299 17.167 4.99266 17.167 5.35399V9.27099C17.167 11.2983 16.5837 13.1523 15.417 14.833C14.2503 16.5137 12.7573 17.6387 10.938 18.208C10.896 18.222 10.75 18.243 10.5 18.271Z" />
        </g>
      </svg>
    </>
  );
};

export default PromotionIcon;

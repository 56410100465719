import { createBrowserRouter } from "react-router-dom";
import { PrivateRoute } from "src/components/atoms";
import AboutUsPage from "src/pages/AboutUsPage";
import AccountPage from "src/pages/AccountPage";
import ActivateAccountPage from "src/pages/ActivateAccountPage";
import AllGamesPage from "src/pages/AllGamesPage";
import GamesPage from "src/pages/GamesPage";
import HomePage from "src/pages/HomePage";
import LoginPage from "src/pages/LoginPage";
import MiraclLoginRedirectPage from "src/pages/MiraclLoginRedirectPage";
import MiraclRegisterRedirectPage from "src/pages/MiraclRegisterRedirectPage";
import NotFoundPage from "src/pages/NotFoundPage";
import RedirectClosePage from "src/pages/RedirectClosePage";
import RegisterPage from "src/pages/RegisterPage";
import ResponsibleGamingPage from "src/pages/ResponsibleGamingPage";
import TermsAndContitionsPage from "src/pages/TermsAndContitionsPage";
import UnderConstructionPage from "src/pages/UnderConstructionPage";
import WithdrawalPage from "src/pages/WithdrawalPage";

/* const mcl = new MIRACLTrust({
  projectId: "19dfb79e-cf95-40c8-a260-3c7dd5bbe198",
  seed: "asdf",
  userStorage: localStorage,
  deviceName: navigator.userAgent,
  cors: true,
}); */

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/games/:gameTypeId",
    element: <GamesPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/register",
    element: <RegisterPage /* mcl={mcl}  */ />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/login",
    element: <LoginPage /* mcl={mcl}  */ />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndContitionsPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/under-construction",
    element: <UnderConstructionPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/account",
    element: <PrivateRoute minWidth={1024} fallbackPath="/" />,
    children: [
      {
        path: "",
        element: <AccountPage />,
        errorElement: <NotFoundPage />,
      },
    ],
    errorElement: <NotFoundPage />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/responsible-gaming",
    element: <ResponsibleGamingPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/redirect-close",
    element: <RedirectClosePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/withdrawal",
    element: <WithdrawalPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/miracl-login-redirect",
    element: <MiraclLoginRedirectPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/miracl-register-redirect",
    element: <MiraclRegisterRedirectPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/activate-account",
    element: <ActivateAccountPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/all-games/:gameTypeId",
    element: <AllGamesPage />,
    errorElement: <NotFoundPage />,
  },
]);

export default router;

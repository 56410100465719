import {
  ChangePasswordPlayer,
  PaymentTransactionLimitRequest,
  PaymentTransactionLimitResponse,
  StatusesResponse,
  UpdatePlayerStatusRequest,
} from "src/types";
import { useAxiosInterceptors } from "./apiConfig";

const useBackOfficeService = () => {
  const { axiosBackOfficeInstance } = useAxiosInterceptors();

  // Get a list with all possible statuses
  const getStatuses = async (): Promise<StatusesResponse> => {
    try {
      const response = await axiosBackOfficeInstance.get("/players/status");
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Update a player's status
  const updatePlayerStatus = async (
    playerId: string,
    updateRequest: UpdatePlayerStatusRequest
  ): Promise<void> => {
    try {
      await axiosBackOfficeInstance.put(
        `/players/${playerId}/status`,
        updateRequest
      );
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Set a player's transaction limits
  const setTransactionLimits = async (
    limitsData: PaymentTransactionLimitRequest
  ): Promise<PaymentTransactionLimitResponse> => {
    try {
      const result = await axiosBackOfficeInstance.post(
        "/transaction-limit",
        limitsData
      );
      return result.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Update the user's password
  const updatePassword = async (changePasswordData: ChangePasswordPlayer): Promise<void> => {
    try {
      await axiosBackOfficeInstance.patch("/change-password", changePasswordData);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  return { getStatuses, updatePlayerStatus, setTransactionLimits, updatePassword };
};

export default useBackOfficeService;

export interface OptionProps {
  value: string;
  label: string;
  flagSrc: string;
}

export const Option = ({ value, label, flagSrc }: OptionProps) => {
  return (
    <option value={value} className="">
      {flagSrc}
      {label}
    </option>
  );
};

export default Option;

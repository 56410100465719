import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const miraclRegister = () => {};

export const getToken = (
    code: string,
    isLogin: boolean | null,
    onSuccess: (item: any) => void,
    miraclEmail: string | null,
    miraclPlayerId: string | null
) => {
    axios
        .post(
            `${process.env.REACT_APP_ENDPOINT_API_URL}/miracl/token`,
            {
                grant_type: "authorization_code",
                code,
                redirect_uri: isLogin
                    ? process.env.REACT_APP_REDIRECT_URI_LOGIN
                    : process.env.REACT_APP_REDIRECT_URI_REGISTER,
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
            },
            {
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                },
            }
        )
        .then((res) => {
            if (isLogin) {
                const decodedToken = jwtDecode(res.data.access_token);
                if (decodedToken.sub === miraclEmail && miraclPlayerId) {
                    onSuccess(res.data.access_token);
                }
            } else {
                onSuccess(null);
            }
        })
        .catch((err) => console.error(err));
};


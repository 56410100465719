export const CasinoIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.30371 14.3066C9.16679 14.3066 9.03803 14.36 8.9412 14.4568C8.84437 14.5537 8.79102 14.6824 8.79102 14.8193C8.79102 14.9563 8.84437 15.085 8.9412 15.1819C9.03803 15.2787 9.16679 15.3321 9.30371 15.3321C9.44067 15.3321 9.56939 15.2787 9.66625 15.1819C9.86617 14.982 9.86617 14.6567 9.66625 14.4568C9.56939 14.36 9.44067 14.3066 9.30371 14.3066Z" />
        <path d="M11.6963 7.26562H3.28809C2.43999 7.26562 1.75 7.95561 1.75 8.80371V17.2119C1.75 18.06 2.43999 18.75 3.28809 18.75H11.6963C12.5444 18.75 13.2344 18.06 13.2344 17.2119V8.80371C13.2344 7.95561 12.5444 7.26562 11.6963 7.26562ZM6.76823 12.2839C6.4777 12.5744 6.09147 12.7344 5.68063 12.7344C5.26979 12.7344 4.88356 12.5743 4.59303 12.2839C3.99335 11.6841 3.99335 10.7084 4.59303 10.1087C4.88353 9.81816 5.26976 9.65813 5.6806 9.65813C6.09144 9.65813 6.47767 9.81816 6.76819 10.1087C7.36794 10.7084 7.36794 11.6841 6.76823 12.2839ZM10.3913 15.9069C10.1008 16.1974 9.71455 16.3575 9.30371 16.3575C8.89287 16.3575 8.50664 16.1974 8.21611 15.9069C7.61643 15.3072 7.61643 14.3315 8.21611 13.7317C8.50664 13.4413 8.89287 13.2812 9.30371 13.2812C9.71455 13.2812 10.1008 13.4412 10.3913 13.7318C10.991 14.3315 10.991 15.3072 10.3913 15.9069Z" />
        <path d="M5.68042 10.6836C5.54349 10.6836 5.41474 10.7369 5.31791 10.8338C5.11799 11.0337 5.11799 11.359 5.31791 11.5589C5.41474 11.6557 5.54349 11.7091 5.68042 11.7091C5.81737 11.7091 5.94609 11.6557 6.04296 11.5589C6.13976 11.462 6.19311 11.3332 6.19311 11.1963C6.19311 11.0594 6.13976 10.9306 6.04293 10.8338C5.94609 10.7369 5.81734 10.6836 5.68042 10.6836Z" />
        <path d="M15.6609 4.32617C15.524 4.32617 15.3952 4.37953 15.2984 4.47636C15.0985 4.67627 15.0985 5.00153 15.2984 5.20145C15.3952 5.29828 15.524 5.35163 15.6609 5.35163C15.7978 5.35163 15.9266 5.29828 16.0234 5.20145C16.1202 5.10458 16.1736 4.97583 16.1736 4.83887C16.1736 4.70194 16.1202 4.57319 16.0234 4.47636C15.9266 4.37953 15.7978 4.32617 15.6609 4.32617Z" />
        <path d="M11.717 5.20141C11.917 5.00149 11.917 4.67624 11.717 4.47632C11.6202 4.37953 11.4915 4.32617 11.3545 4.32617C11.2176 4.32617 11.0888 4.37953 10.992 4.47636C10.8952 4.57319 10.8418 4.70194 10.8418 4.83887C10.8418 4.97583 10.8952 5.10458 10.992 5.20141C11.0888 5.29824 11.2176 5.3516 11.3545 5.3516C11.4915 5.3516 11.6202 5.29824 11.717 5.20141Z" />
        <path d="M16.0234 9.50799C16.1203 9.41116 16.1736 9.28241 16.1736 9.14548C16.1736 9.00853 16.1203 8.87977 16.0234 8.78294C15.8235 8.58309 15.4983 8.58309 15.2984 8.78294C15.0985 8.98286 15.0985 9.30811 15.2984 9.50803C15.3952 9.60486 15.524 9.65821 15.6609 9.65821C15.7978 9.65818 15.9266 9.60482 16.0234 9.50799Z" />
        <path d="M17.7119 1.25H9.30371C8.45561 1.25 7.76562 1.93999 7.76562 2.78809V6.24023H10.7195C10.5532 6.16477 10.3999 6.05949 10.2669 5.92647C9.66721 5.32675 9.66721 4.35099 10.2669 3.75127C10.5574 3.46081 10.9437 3.30078 11.3545 3.30078C11.7653 3.30078 12.1516 3.46078 12.4421 3.7513C13.0418 4.35102 13.0418 5.32678 12.4421 5.9265C12.301 6.06752 12.1373 6.17752 11.9592 6.25367C13.1638 6.37695 14.1241 7.33774 14.2465 8.54258C14.3214 8.36642 14.4301 8.20133 14.5735 8.05791C15.1732 7.45823 16.149 7.45826 16.7487 8.05788C17.3484 8.65763 17.3484 9.63339 16.7487 10.2331C16.4582 10.5236 16.0719 10.6836 15.6611 10.6836C15.2503 10.6836 14.864 10.5236 14.5735 10.2331C14.4384 10.098 14.334 9.94364 14.2597 9.77893V12.7344H17.7119C18.56 12.7344 19.25 12.0444 19.25 11.1963V2.78809C19.25 1.93999 18.56 1.25 17.7119 1.25ZM16.7487 5.92647C16.4582 6.21696 16.0719 6.37699 15.6611 6.37699C15.2503 6.37699 14.864 6.21696 14.5735 5.92647C13.9738 5.32675 13.9738 4.35099 14.5735 3.75127C14.864 3.46081 15.2503 3.30078 15.6611 3.30078C16.072 3.30078 16.4582 3.46078 16.7487 3.7513C17.3484 4.35102 17.3484 5.32675 16.7487 5.92647Z" />
      </svg>
    </>
  );
};

export default CasinoIcon;

import { useTranslation } from "react-i18next";
import Button from "../../atoms/button/Button.component";

export interface BannerProps {
  className?: string;
  image?: string;
  background?: string;
  size?: string;
  buttonOnClick?: () => void;
  text?: JSX.Element;
  content?: JSX.Element;
}

const Banner = ({
  className = "",
  image,
  background,
  size,
  buttonOnClick,
  text,
  content,
}: BannerProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`rounded-[20px] flex relative md:justify-between 2xl:px-10 md:px-6 items-center overflow-hidden ${className} ${size}`}
    >
      <div className="lg:h-full lg:flex-1 flex flex-col justify-between xl:items-start items-center lg:py-20 py-6 z-[2] gap-3.5">
        {text}
        {buttonOnClick && (
          <Button
            className="flex justify-center items-center px-8 py-3.5 bg-secondary-200 text-greyscale-300 rounded-10 shadow-[0_20px_30px_#6f953480] hover:bg-secondary-300 hover:text-greyscale-100 group transition duration-300 whitespace-nowrap"
            onClick={buttonOnClick}
          >
            {t("button_claim")}
          </Button>
        )}
      </div>
      <img
        src={image}
        alt=""
        className="lg:flex-1 z-[2] xl:w-full xl:h-auto xl:object-scale-down object-contain"
      />
      <img
        src={background}
        alt=""
        className="absolute w-full h-full right-0 rounded-[20px]"
      />
    </div>
  );
};

export default Banner;

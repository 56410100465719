import { createContext, ReactNode, useContext, useState } from "react";
import Cookies from "js-cookie";
import { PlayerResponse } from "src/types";
import { jwtDecode } from "jwt-decode";

interface AuthContextProps {
  token: string | null;
  refreshToken: string | null;
  playerData: PlayerResponse | null;
  hasMiraclAuth: boolean | null;
  miraclPlayerId: string | null;
  miraclEmail: string | null;
  setToken: (token: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  setPlayerData: (playerData: PlayerResponse | null) => void;
  setHasMiraclAuth: (hasMiraclAuth: boolean | null) => void;
  setMiraclPlayerId: (miraclPlayerId: string | null) => void;
  setMiraclEmail: (miraclEmail: string | null) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    () => Cookies.get("token") || null
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    () => Cookies.get("refreshToken") || null
  );
  const [playerData, setPlayerData] = useState<PlayerResponse | null>(() => {
    const storedData = Cookies.get("playerData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [hasMiraclAuth, setHasMiraclAuth] = useState<boolean | null>(
    () => Boolean(Cookies.get("hasMiraclAuth")) || null
  );
  const [miraclPlayerId, setMiraclPlayerId] = useState<string | null>(
    () => Cookies.get("miraclPlayerId") || null
  );
  const [miraclEmail, setMiraclEmail] = useState<string | null>(
    () => Cookies.get("miraclEmail") || null
  );

  const handleSetToken = (newToken: string | null) => {
    if (newToken) {
      const decodedToken = jwtDecode(newToken);
      // Convert Epoch to Date by multiplying by 1000 to convert it to milliseconds
      let tokenExpiry;
      if (decodedToken?.exp) {
        tokenExpiry = new Date(decodedToken.exp * 1000);
      } else {
        tokenExpiry = null;
      }

      if (tokenExpiry) {
        Cookies.set("token", newToken, { expires: tokenExpiry });
        setToken(newToken);
        return;
      }
    }

    Cookies.remove("token");
    setToken(null);
  };

  const handleSetRefreshToken = (newRefreshToken: string | null) => {
    if (newRefreshToken) {
      const decodedToken = jwtDecode(newRefreshToken);
      // Convert Epoch to Date by multiplying by 1000 to convert it to milliseconds
      let tokenExpiry;
      if (decodedToken?.exp) {
        tokenExpiry = new Date(decodedToken.exp * 1000);
      } else {
        tokenExpiry = null;
      }

      if (tokenExpiry) {
        Cookies.set("refreshToken", newRefreshToken, {
          expires: tokenExpiry,
        });
        setRefreshToken(newRefreshToken);
        return;
      }
    }

    Cookies.remove("refreshToken");
    setRefreshToken(null);
  };

  const handleSetPlayerData = (newPlayerData: PlayerResponse | null) => {
    if (newPlayerData) {
      Cookies.set("playerData", JSON.stringify(newPlayerData), {
        expires: parseInt(process.env.REACT_APP_COOKIE_TIME || "1"),
      }); // Set expiry time as needed
      setPlayerData(newPlayerData);
    } else {
      Cookies.remove("playerData");
      setPlayerData(null);
    }
  };

  const handleSetMiraclPlayerId = (miraclPlayerId: string | null) => {
    if (miraclPlayerId) {
      Cookies.set("miraclPlayerId", miraclPlayerId);
      setMiraclPlayerId(miraclPlayerId);
    } else {
      Cookies.remove("miraclPlayerId");
      setMiraclPlayerId(null);
    }
  };

  const handleSetMiraclEmail = (miraclEmail: string | null) => {
    if (miraclEmail) {
      Cookies.set("miraclEmail", miraclEmail);
      setMiraclEmail(miraclEmail);
    } else {
      Cookies.remove("miraclEmail");
      setMiraclEmail(null);
    }
  };

  const handleSetMiraclAuth = (hasMiraclAuth: boolean | null) => {
    if (hasMiraclAuth) {
      Cookies.set("hasMiraclAuth", hasMiraclAuth.toString());
      setHasMiraclAuth(hasMiraclAuth);
    } else {
      Cookies.remove("hasMiraclAuth");
      setHasMiraclAuth(null);
    }
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    Cookies.remove("playerData");
    setToken(null);
    setRefreshToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        playerData,
        hasMiraclAuth,
        miraclPlayerId,
        miraclEmail,
        setToken: handleSetToken,
        setRefreshToken: handleSetRefreshToken,
        setPlayerData: handleSetPlayerData,
        setHasMiraclAuth: handleSetMiraclAuth,
        setMiraclPlayerId: handleSetMiraclPlayerId,
        setMiraclEmail: handleSetMiraclEmail,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

import React, { useEffect, useState, useCallback } from "react";
import { ReactComponent as UserSquareIcon } from "src/assets/icons/account/user.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { Footer, Header, TransactionModal } from "src/components/organisms";
import { ReactComponent as EditIcon } from "src/assets/icons/edit/Edit.svg";
import { ReactComponent as DepositIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as WithdrawIcon } from "src/assets/icons/money&payment/Favourite Payment Card.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { useAuth } from "src/context/AuthContext";
import ProfilePage from "./Account/ProfilePage";
import WalletPage from "./Account/WalletPage";
import ReactGA from "react-ga4";
import useTransactionService from "src/services/transactionService";
import { Button } from "src/components/atoms";
import { GameResponse } from "src/types";
import GameModal, {
  GameProps,
} from "src/components/molecules/gameModal/GameModal.component";
import useGameService from "src/services/gameService";
import useNotificationsService from "src/services/notificationsService";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Balance } from "src/components/molecules";

export default function AccountPage() {
  const { getBalances, getCurrencies, getCurrencyById } =
    useTransactionService();
  const { launchGame } = useGameService();
  const { addErrorNotification } = useNotificationsService();
  const { playerData } = useAuth();
  const [amount, setAmount] = useState<number>(0);
  const [selectedSection, setSelectedSection] = useState({
    id: 0,
    component: <ProfilePage isEditing={false} />,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [walletPrefix, setWalletPrefix] = useState<string>("$");
  const [currency, setCurrency] = useState<string>("USD");
  const [gameModalData, setGameModalData] = useState<GameProps | undefined>(
    undefined
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isWalletLoading, setWalletLoading] = useState<boolean>(true);

  const HeaderSection: React.FC = () => {
    const { t } = useTranslation();
    return (
      <div className="rounded-t-10 w-full bg-greyscale-100 flex px-16 py-[22px] justify-between text-primary-400">
        <div className="flex gap-7 items-center">
          <span className="rounded-full min-w-[76px] min-h-[76px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100">
            {playerData?.first_name?.charAt(0).toUpperCase()}
            {playerData?.last_name?.charAt(0).toUpperCase()}
          </span>
          <div>
            <div className="flex items-center justify-between">
              <div className="text-2xl font-semibold">
                {playerData?.first_name} {playerData?.last_name}
              </div>
            </div>
            <div className="flex items-center gap-[34px]">
              <span className="font-light text-base">
                {t("text_player-id")}:{" "}
                <span className="font-semibold">{playerData?.nickname}</span>
              </span>
              {isEdit === true ? (
                <Button
                  className="flex items-center gap-2.5  py-1 px-3.5 rounded-sm text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-[10px] leading-[17px]"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  <span className="hidden 2xl:block">
                    {t("button_close-edit")}
                  </span>
                  <CloseIcon className="w-3.5" />
                </Button>
              ) : (
                <Button
                  className="flex items-center gap-2.5  py-1 px-3.5 rounded-sm text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-[10px] leading-[17px]"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <span className="hidden 2xl:block">
                    {t("button_edit-profile")}
                  </span>
                  <EditIcon className="w-3.5" />
                </Button>
              )}
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex items-center gap-5">
          {/* <div className="flex border border-primary-400 py-2.5 px-6 rounded">
            <div className="hidden xl:block pr-[17px] border-r border-primary-400 font-light text-lg leading-6">
              {t("text_balance")}
            </div>
            <div className="xl:pl-[17px] font-semibold text-xl leading-6">
              {walletPrefix}
              {amount.toString()}
            </div>
          </div> */}
          <Balance
            selectedCurrency={currency}
            balance={amount}
            isLoading={isWalletLoading}
          />
          <Button
            className="flex items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-sm"
            onClick={() => handleTransactionMoldal("deposit")}
          >
            <span className="hidden xl:block">{t("text_deposit")}</span>
            <DepositIcon />
          </Button>
          <Button
            className="flex items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-primary-400 bg-secondary-200 hover:text-greyscale-100 hover:bg-secondary-300 transition duration-300 text-sm"
            onClick={() => handleTransactionMoldal("withdraw")}
          >
            <span className="hidden xl:block">{t("text_withdraw")}</span>
            <WithdrawIcon />
          </Button>
        </div>
      </div>
    );
  };

  const [isTransactionModalOpen, setTransactionModalOpen] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<
    "deposit" | "withdraw"
  >("deposit");

  const fetchBalances = useCallback(async () => {
    if (!playerData?.id) return; // Ensure playerData.id is available
    try {
      const { result } = await getBalances(playerData.id);
      const { result: currencies } = await getCurrencies();
      const balance =
        result.find((item) => item.currency_id === playerData.currency_id)
          ?.amount || 0;
      setAmount(balance);
      const currency = currencies.find(
        (item) => item.id === playerData.currency_id
      )?.alphabetic_code;
      setCurrency(currency || "USD");
      setWalletPrefix(currency === "EUR" ? "€" : "$");
    } catch (error) {
      addErrorNotification(t("error_get-balances"));
      console.error("Error getting balances: ", error);
    } finally {
      setWalletLoading(false);
    }
  }, [getBalances, getCurrencies, playerData?.currency_id, playerData?.id]);

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances]);

  useEffect(() => {
    if (isEdit) {
      setSelectedSection({
        id: 0,
        component: <ProfilePage isEditing={isEdit} />,
      });
    } else {
      if (selectedSection.id === 0) {
        setSelectedSection({
          id: 0,
          component: <ProfilePage isEditing={isEdit} />,
        });
      } else if (selectedSection.id === 2) {
        setSelectedSection({
          id: 2,
          component: <WalletPage />,
        });
      }
    }
  }, [isEdit, selectedSection.id]);

  const sections = [
    {
      title: t("text_account-profile"),
      icon: <UserSquareIcon />,
      component: <ProfilePage isEditing={isEdit} />,
      id: 0,
    },
    {
      title: t("text_wallet"),
      icon: <WalletIcon />,
      component: <WalletPage />,
      id: 2,
    },
  ];

  const handleSectionClick = (item: any) => {
    setIsEdit(false); // Reset isEdit state first
    setSelectedSection(item);
  };

  const handleTransactionMoldal = (type: "deposit" | "withdraw") => {
    setTransactionType(type);
    // Add begin checkout event
    ReactGA.event("begin_checkout", {
      currency: "",
      value: 0,
      items: [
        {
          item_id: type,
          item_name: playerData?.id || "",
        },
      ],
    });
    setTransactionModalOpen(true);
  };

  const handleSetGameModalData = (game: GameResponse) => {
    setGameModalData({
      name: game.name,
      description: (
        <div className="break-words flex flex-col">
          <span>
            {game.has_mobile
              ? t("text_playable-on-mobile")
              : t("text_playable-on-desktop")}
          </span>
          <span>
            {game.has_demo
              ? t("text_game-has-demo")
              : t("text_no-game-description")}
          </span>
        </div>
      ),
      image: game.image,
      bets: {
        minimum: "2",
        maximum: "250",
      },
      game_id: game.id,
      game_name: game.name,
      is_demo: game.has_demo,
      is_mobile: game.has_mobile,
      provider_id: game.provider_id,
    });
    setModalOpen(true);
  };

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Account Page",
  });

  const handleFavoriteToggle = (id: string | number): void => {
    alert(
      "The favourites service is still under development. Thank for your comprehension."
    );
    console.log(`Favourite toggle for game with id: ${id}`);
  };

  const handlePlayGame = async (game: GameProps): Promise<void> => {
    try {
      if (!playerData) {
        return addErrorNotification(t("error_please-login"));
      } else {
        const { result: currency } = await getCurrencyById(
          playerData.currency_id
        );
        if (!currency) {
          return addErrorNotification(t("error_get-currency"));
        }
        const {
          result: { play_for_real: url },
        } = await launchGame({
          game_id: game.game_id,
          game_name: game.game_name,
          is_demo: game.is_demo,
          is_mobile: game.is_mobile,
          player_id: playerData.id,
          provider_id: game.provider_id,
          user_currency: currency.alphabetic_code,
          user_language: "en",
        });
        const width = 1024;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          url,
          "_blank",
          `width=${width},height=${height},top=${top},left=${left}`
        );
      }
    } catch (error: any) {
      addErrorNotification(t("error_launch-game"));
      console.log("Failed to launch game: ", error);
    }
  };

  return (
    <div className="font-main w-full">
      <TransactionModal
        isOpen={isTransactionModalOpen}
        transactionType={transactionType}
        balance={amount.toString()}
        onClose={() => setTransactionModalOpen(false)}
        currencyPrefix={walletPrefix}
      />
      <GameModal
        isModalOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        game={gameModalData}
        isFavourite={false}
        onClickFavourite={() => handleFavoriteToggle(0)}
        onClickPlay={() =>
          gameModalData ? handlePlayGame(gameModalData) : console.warn("Error")
        }
      />
      <Header onSearchResultItemClick={handleSetGameModalData} />
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient">
        <div className="flex gap-10 md:py-12 py-6 w-full">
          <div className="flex flex-col gap-3.5 select-none">
            {sections.map((item, index) => {
              const selected = item.id === selectedSection.id;
              return (
                <div
                  key={`menu_${index}`}
                  className={`${
                    selected
                      ? "bg-greyscale-100 font-bold text-primary-200"
                      : "bg-none text-greyscale-100 border border-greyscale-100"
                  }
                                    flex gap-6 xl:pl-6 px-3.5 py-3.5 xl:w-56
                                    rounded cursor-pointer
                                    `}
                  onClick={() => handleSectionClick(item)}
                >
                  <div className="h-6 w-6">{item.icon}</div>
                  <span className="hidden xl:block">{item.title}</span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col flex-grow gap-4">
            <HeaderSection />
            {selectedSection.component}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

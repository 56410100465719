import { useState } from "react";
import { useTranslation } from "react-i18next";

export const AccountProfileStats = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(0);

  const dummyData = [
    {
      id: 0,
      label: t("text_last-24-hours"),
      data: {
        overallEarnings: "0",
        winnings: "0",
        referralEarnings: "0",
        bonuses: "0",
      },
    },
    {
      id: 1,
      label: t("text_last-week"),
      data: {
        overallEarnings: "0",
        winnings: "0",
        referralEarnings: "0",
        bonuses: "0",
      },
    },
    {
      id: 2,
      label: t("text_last-month"),
      data: {
        overallEarnings: "0",
        winnings: "0",
        referralEarnings: "0",
        bonuses: "0",
      },
    },
  ];

  return (
    <div className="border-2 rounded-10 border-primary-400 p-6">
      <div className="flex justify-between items-center mb-8">
        <div className="text-xl text-primary-400 font-bold">
          {t("text_my-stats")}
        </div>
        <div className="flex gap-2.5">
          {dummyData.map((item, index) => (
            <div
              key={`statsMenu${index}`}
              className={`${
                item.id === selectedOption
                  ? "bg-primary-400 text-greyscale-100"
                  : "bg-greyscale-100 text-primary-400 border border-primary-400"
              } text-xs p-2.5 cursor-pointer select-none rounded-[5px]`}
              onClick={() => setSelectedOption(item.id)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {
              dummyData.find((item) => item.id === selectedOption)?.data
                .overallEarnings
            }
          </div>
          <div className="text-sm text-greyscale-200">
            {t("text_overall-earnings")}
          </div>
        </div>
        <div className="h-[50px] w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {
              dummyData.find((item) => item.id === selectedOption)?.data
                .winnings
            }
          </div>
          <div className="text-sm text-greyscale-200">{t("text_winnings")}</div>
        </div>
        <div className="h-[50px] w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {
              dummyData.find((item) => item.id === selectedOption)?.data
                .referralEarnings
            }
          </div>
          <div className="text-sm text-greyscale-200">
            {t("text_referral-earnings")}
          </div>
        </div>
        <div className="h-[50px] w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {dummyData.find((item) => item.id === selectedOption)?.data.bonuses}
          </div>
          <div className="text-sm text-greyscale-200">{t("text_bonuses")}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfileStats;

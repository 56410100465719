import { Formik, useFormikContext } from "formik";
import { Button } from "src/components/atoms";
import { InputText } from "src/components/molecules";
import * as Yup from "yup";
import { ReactComponent as AddPaymentCardIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";

export const AddCardForm = () => {
    const Form = () => {
        const { handleBlur, handleChange, validateField } = useFormikContext();
        const handleOnBlur = (e: React.FocusEvent<any>) => {
            handleBlur(e);
            validateField(e.target.name);
        };
        const handleOnChange = (e: React.ChangeEvent<any>) => {
            handleChange(e);
            validateField(e.target.name);
        };
        return (
            <div className="flex flex-col gap-6">
                <InputText
                    name="nameOnCard"
                    label="Name on card"
                    type="text"
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                />
                <InputText
                    name="cardNumber"
                    label="Card number"
                    type="text"
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                />
                <div className="flex gap-5">
                    <InputText
                        name="expDate"
                        label="Exp. Date"
                        type="text"
                        onBlur={handleOnBlur}
                        onChange={handleOnChange}
                    />
                    <InputText
                        name="cvv"
                        label="CCV"
                        type="text"
                        onBlur={handleOnBlur}
                        onChange={handleOnChange}
                    />
                </div>
                <div className="flex justify-end mt-2.5">
                    <Button className="flex items-center gap-5 py-2.5 pr-6 pl-12 bg-secondary-200 rounded">
                        <span className="text-sm">Add card</span>
                        <AddPaymentCardIcon />
                    </Button>
                </div>
            </div>
        );
    };
    return (
        <Formik
            initialValues={{
                nameOnCard: "",
                cardNumber: "",
                expDate: "",
                cvv: "",
            }}
            validationSchema={Yup.object({
                nameOnCard: Yup.string()
                    .max(15, "Must be 15 characters or less")
                    .required("Required"),
                cardNumber: Yup.string()
                    .min(15, "Must be 15 characters or more")
                    .max(19, "Must be 19 characters or less")
                    .required("Required"),
                expDate: Yup.string()
                    .max(20, "Must be 20 characters or less")
                    .required("Required"),
                cvv: Yup.string()
                    .min(3, "Must be 3 characters or more")
                    .max(4, "Must be 4 characters or less")
                    .required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {}, 400);
            }}
        >
            <Form />
        </Formik>
    );
};

export default AddCardForm;

import { useState } from "react";
import { ReactComponent as ArrowDownRightIcon } from "src/assets/icons/arrows/Arrow Down Right.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/edit/Edit.svg";
import { ReactComponent as ThumbsUpIcon } from "src/assets/icons/emoji/Thumbs Up.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import Button from "src/components/atoms/button/Button.component";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

export enum textThemeEnum {
  white = "white",
  black = "black",
}
export interface InputDepositLimitProps {
  inputValue: string;
  setInputValue: (code: string) => void;
  onRequestClose: () => void;
  onRequestOpen: () => void;
  isOpened?: boolean;
  backgroundClass?: string;
  textTheme?: textThemeEnum;
  values: string[];
  title: string;
  description: string;
  prefix?: string;
}

export interface ColorClassesType {
  background: string;
  text: string;
  border: string;
}

export interface StringIndexObject {
  [key: string]: string;
}

const InputDepositLimit = ({
  inputValue,
  setInputValue,
  onRequestClose,
  onRequestOpen,
  isOpened = false,
  backgroundClass = "bg-[#B9FFB7]",
  textTheme = textThemeEnum.black,
  values,
  title,
  description,
  prefix = "$",
}: InputDepositLimitProps) => {
  const { t } = useTranslation();
  const [customValue, setCustomValue] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const handleCustomValue = () => {
    values.includes(customValue) && setCustomValue("");
  };

  const textColor: StringIndexObject = {
    black: "text-greyscale-400",
    white: "text-greyscale-100",
  };

  const borderColor: StringIndexObject = {
    black: "border-greyscale-400",
    white: "border-greyscale-100",
  };

  return (
    <div className={`${backgroundClass} ${textColor[textTheme]} rounded-md`}>
      {!isOpened ? (
        inputValue !== "" ? (
          <div className="flex justify-between items-center pl-5 pr-6 py-2.5">
            <div className="flex gap-2.5 items-center">
              <ThumbsUpIcon />
              <span className="first-letter:uppercase font-semibold text-sm">
                {title} {t("text_set")}!
              </span>
            </div>
            <EditIcon
              onClick={() => {
                onRequestOpen();
              }}
              className="cursor-pointer"
            />
          </div>
        ) : (
          <div className="flex justify-between items-center pl-5 pr-6 py-2.5">
            <span className="text-sm">
              {t("text_Set")} {title}
            </span>
            <ArrowDownRightIcon
              onClick={() => {
                onRequestOpen();
              }}
              className="cursor-pointer"
            />
          </div>
        )
      ) : (
        <div className="py-3.5 px-5">
          <div className="flex justify-between items-center mb-2">
            <span className="capitalize text-sm font-bold">{title}</span>
            <ClearIcon
              onClick={() => {
                onRequestClose();
              }}
              className="cursor-pointer"
            />
          </div>
          <div className="w-3/4">
            <span className="text-xs">{description}</span>
          </div>
          <div className="flex flex-col gap-2.5 mt-5">
            <div className="flex w-full flex-row gap-2.5 justify-between h-11">
              {values.map((item, index) => {
                return (
                  <Button
                    type="button"
                    className={`border w-1/3 rounded ${
                      borderColor[textTheme]
                    } ${
                      textTheme === "white"
                        ? "text-greyscale-100"
                        : "text-primary-400"
                    } text-center ${
                      value !== item && value !== ""
                        ? "opacity-20"
                        : "opacity-100"
                    }`}
                    key={`${title}_button${index}`}
                    onClick={() => {
                      setValue(item);
                      setCustomValue("");
                    }}
                  >
                    {prefix}
                    {item}
                  </Button>
                );
              })}
            </div>
            <div className="flex gap-2.5 h-11">
              <CurrencyInput
                className={`pl-5 border rounded w-[calc(66.66666667%+10px)] ${
                  borderColor[textTheme]
                } ${
                  textTheme === "white"
                    ? "text-greyscale-100"
                    : "text-primary-400"
                } bg-transparent`}
                placeholder={t("placeholder_custom-amount")}
                pattern="[0-9]*"
                inputMode="numeric"
                defaultValue={1000}
                decimalsLimit={0}
                max={99999}
                min={1}
                value={customValue}
                onValueChange={(value: string | undefined) => {
                  if (value && parseInt(value) >= 1) {
                    setValue(value || "");
                    setCustomValue(value || "");
                  } else {
                    setValue("");
                    setCustomValue("");
                  }
                }}
                onBlur={handleCustomValue}
                prefix={prefix}
              />
              <Button
                type="button"
                className="w-1/3 text-greyscale-100 bg-primary-400 disabled:opacity-40 opacity-100 rounded"
                isDisabled={value === ""}
                onClick={() => {
                  setInputValue(value);
                  onRequestClose();
                }}
              >
                {t("button_confirm")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputDepositLimit;

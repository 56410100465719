export const BrotherBets4Logo = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 608 360"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_135_139545)">
          <g clipPath="url(#clip1_135_139545)">
            <path
              d="M82.2426 326.719L83.7104 327.076C82.3625 330.794 78.8427 333.189 74.3195 333.189C68.5532 333.189 64 328.667 64 322.911C64 317.155 68.5532 312.648 74.3195 312.648C78.8427 312.648 82.3625 315.058 83.7104 318.672L82.2726 319.118C81.1043 315.995 78.1388 314.047 74.3195 314.047C69.3769 314.047 65.4978 317.929 65.4978 322.926C65.4978 327.924 69.3919 331.806 74.3195 331.806C78.1238 331.806 81.0444 329.842 82.2426 326.734V326.719Z"
              fill="white"
            />
            <path
              d="M99.7214 323.49V332.875H98.3136V330.019C97.1753 331.893 95.0185 333.202 92.2776 333.202C89.1024 333.202 87.0654 331.417 87.0654 328.948C87.0654 326.33 89.0724 324.441 93.0115 324.441L98.2986 324.471V323.549C98.2986 320.842 96.5312 319.295 93.8952 319.295C91.4389 319.295 89.6116 320.604 89.2671 322.627L87.8892 322.27C88.3086 319.741 90.705 317.986 93.8353 317.986C97.37 317.986 99.6915 319.979 99.6915 323.475L99.7214 323.49ZM98.3136 326.717V325.646H92.9965C90.001 325.646 88.4733 327.044 88.4733 328.918C88.4733 330.792 90.031 331.982 92.4274 331.982C95.6925 331.982 98.3136 329.692 98.3136 326.732V326.717Z"
              fill="white"
            />
            <path
              d="M104.964 328.533C105.174 330.704 107.211 331.984 109.937 331.984C112.393 331.984 114.071 330.883 114.071 329.009C114.071 327.462 112.872 326.837 111.285 326.48L107.69 325.707C105.354 325.201 104.066 324.13 104.066 322.167C104.066 319.668 106.252 317.973 109.413 317.973C112.573 317.973 114.61 319.638 115.149 321.899L113.801 322.256C113.412 320.412 111.794 319.192 109.368 319.192C106.941 319.192 105.503 320.323 105.503 322.078C105.503 323.595 106.612 324.16 108.199 324.517L111.734 325.29C113.651 325.737 115.478 326.599 115.478 328.92C115.478 331.627 113.172 333.203 109.832 333.203C106.492 333.203 103.916 331.627 103.526 328.89L104.964 328.533Z"
              fill="white"
            />
            <path
              d="M122.112 313.925C122.112 314.55 121.633 315.026 120.974 315.026C120.315 315.026 119.806 314.55 119.806 313.925C119.806 313.301 120.285 312.795 120.974 312.795C121.663 312.795 122.112 313.301 122.112 313.925ZM120.255 332.874V318.343H121.693V332.859H120.255V332.874Z"
              fill="white"
            />
            <path
              d="M141.119 324.233V332.875H139.651V324.441C139.651 321.378 137.794 319.37 135.038 319.37C131.533 319.37 129.212 321.72 129.212 325.602V332.875H127.744V318.343H129.212V321.437C130.35 319.265 132.447 317.986 135.218 317.986C138.753 317.986 141.104 320.515 141.104 324.218L141.119 324.233Z"
              fill="white"
            />
            <path
              d="M160.5 325.586C160.5 329.989 157.145 333.187 152.816 333.187C148.488 333.187 145.133 329.974 145.133 325.586C145.133 321.199 148.488 318.016 152.816 318.016C157.145 318.016 160.5 321.199 160.5 325.586ZM159.062 325.586C159.062 321.898 156.306 319.295 152.816 319.295C149.327 319.295 146.571 321.913 146.571 325.586C146.571 329.26 149.327 331.878 152.816 331.878C156.306 331.878 159.062 329.23 159.062 325.586Z"
              fill="white"
            />
            <path
              d="M242.637 317.958L241.14 318.315C240.63 315.667 238.444 313.957 235.283 313.957C232.288 313.957 230.116 315.563 230.116 318.121C230.116 320.293 231.614 321.126 233.681 321.602L238.084 322.643C240.6 323.178 242.997 324.309 242.997 327.551C242.997 331.032 240.151 333.218 235.852 333.218C231.554 333.218 228.259 330.987 227.81 327.313L229.427 326.956C229.787 330.05 232.453 331.894 235.972 331.894C239.492 331.894 241.499 330.199 241.499 327.626C241.499 325.395 240.211 324.591 237.755 324.026L233.112 322.955C230.446 322.331 228.499 321.022 228.499 318.166C228.499 314.805 231.314 312.678 235.343 312.678C239.372 312.678 241.978 314.879 242.637 317.988V317.958Z"
              fill="white"
            />
            <path
              d="M262.692 325.587C262.692 330.212 259.308 333.187 255.308 333.187C252.583 333.187 250.261 331.878 249.003 329.677V339.984H247.535V318.343H249.003V321.526C250.261 319.295 252.598 317.986 255.308 317.986C259.322 317.986 262.692 320.991 262.692 325.587ZM261.255 325.587C261.255 321.72 258.469 319.295 255.129 319.295C251.983 319.295 249.003 321.734 249.003 325.587C249.003 329.439 251.998 331.878 255.129 331.878C258.484 331.878 261.255 329.469 261.255 325.587Z"
              fill="white"
            />
            <path
              d="M281.01 325.586C281.01 329.989 277.655 333.187 273.326 333.187C268.998 333.187 265.643 329.974 265.643 325.586C265.643 321.199 268.998 318.016 273.326 318.016C277.655 318.016 281.01 321.199 281.01 325.586ZM279.572 325.586C279.572 321.898 276.816 319.295 273.326 319.295C269.836 319.295 267.08 321.913 267.08 325.586C267.08 329.26 269.836 331.878 273.326 331.878C276.816 331.878 279.572 329.23 279.572 325.586Z"
              fill="white"
            />
            <path
              d="M294.085 318.314L293.935 319.682C293.635 319.623 293.336 319.623 293.066 319.623C289.022 319.623 286.851 322.954 286.851 326.807V332.86H285.383V318.344H286.851V322.419C288.019 320.009 290.176 318.225 293.066 318.225C293.396 318.225 293.665 318.225 294.085 318.314Z"
              fill="white"
            />
            <path
              d="M305.632 332.043C304.584 332.846 303.625 333.203 302.427 333.203C299.821 333.203 298.054 331.567 298.054 328.77V319.593H294.849V318.344H298.054V312.975H299.522V318.359H304.988V319.608H299.522V328.696C299.522 330.659 300.72 331.849 302.517 331.849C303.446 331.849 304.314 331.492 305.063 330.868L305.632 332.057V332.043Z"
              fill="white"
            />
            <path
              d="M309.078 328.533C309.287 330.704 311.324 331.984 314.05 331.984C316.506 331.984 318.184 330.883 318.184 329.009C318.184 327.462 316.986 326.837 315.398 326.48L311.803 325.707C309.467 325.201 308.179 324.13 308.179 322.167C308.179 319.668 310.366 317.973 313.526 317.973C316.686 317.973 318.723 319.638 319.262 321.899L317.914 322.256C317.525 320.412 315.907 319.192 313.481 319.192C311.055 319.192 309.617 320.323 309.617 322.078C309.617 323.595 310.725 324.16 312.313 324.517L315.847 325.29C317.764 325.737 319.592 326.599 319.592 328.92C319.592 331.627 317.285 333.203 313.945 333.203C310.605 333.203 308.029 331.627 307.64 328.89L309.078 328.533Z"
              fill="white"
            />
            <path
              d="M339.242 325.588C339.242 330.213 335.857 333.188 331.858 333.188C329.132 333.188 326.811 331.879 325.553 329.678V332.861H324.085V310H325.553V321.527C326.811 319.296 329.147 317.987 331.858 317.987C335.872 317.987 339.242 320.992 339.242 325.588ZM337.804 325.588C337.804 321.72 335.019 319.296 331.679 319.296C328.533 319.296 325.553 321.735 325.553 325.588C325.553 329.44 328.548 331.879 331.679 331.879C335.034 331.879 337.804 329.47 337.804 325.588Z"
              fill="white"
            />
            <path
              d="M357.56 325.586C357.56 329.989 354.205 333.187 349.877 333.187C345.548 333.187 342.193 329.974 342.193 325.586C342.193 321.199 345.548 318.016 349.877 318.016C354.205 318.016 357.56 321.199 357.56 325.586ZM356.122 325.586C356.122 321.898 353.367 319.295 349.877 319.295C346.387 319.295 343.631 321.913 343.631 325.586C343.631 329.26 346.387 331.878 349.877 331.878C353.367 331.878 356.122 329.23 356.122 325.586Z"
              fill="white"
            />
            <path
              d="M375.878 325.586C375.878 329.989 372.523 333.187 368.194 333.187C363.866 333.187 360.511 329.974 360.511 325.586C360.511 321.199 363.866 318.016 368.194 318.016C372.523 318.016 375.878 321.199 375.878 325.586ZM374.44 325.586C374.44 321.898 371.684 319.295 368.194 319.295C364.704 319.295 361.949 321.913 361.949 325.586C361.949 329.26 364.704 331.878 368.194 331.878C371.684 331.878 374.44 329.23 374.44 325.586Z"
              fill="white"
            />
            <path
              d="M392.697 332.876L385.762 325.364L381.688 329.529V332.89H380.221V310H381.688V327.521L390.9 318.344H392.697L386.751 324.368L394.554 332.861H392.697V332.876Z"
              fill="white"
            />
            <path
              d="M471.614 312.975C475.718 312.975 478.369 315.444 478.369 318.879C478.369 322.315 475.703 324.814 471.614 324.814H464.679V332.861H463.151V312.975H471.614ZM471.614 323.46C474.819 323.46 476.841 321.557 476.841 318.879C476.841 316.202 474.804 314.328 471.614 314.328H464.679V323.446H471.614V323.46Z"
              fill="white"
            />
            <path
              d="M496.357 325.586C496.357 329.989 493.002 333.187 488.674 333.187C484.345 333.187 480.99 329.974 480.99 325.586C480.99 321.199 484.345 318.016 488.674 318.016C493.002 318.016 496.357 321.199 496.357 325.586ZM494.919 325.586C494.919 321.898 492.163 319.295 488.674 319.295C485.184 319.295 482.428 321.913 482.428 325.586C482.428 329.26 485.184 331.878 488.674 331.878C492.163 331.878 494.919 329.23 494.919 325.586Z"
              fill="white"
            />
            <path
              d="M513.175 332.876L506.241 325.364L502.167 329.529V332.89H500.699V310H502.167V327.521L511.378 318.344H513.175L507.229 324.368L515.033 332.861H513.175V332.876Z"
              fill="white"
            />
            <path
              d="M530.955 325.735H517.7C517.76 329.632 520.725 331.908 523.646 331.908C526.192 331.908 528.274 330.778 529.322 328.19L530.61 328.487C529.502 331.551 526.986 333.187 523.646 333.187C519.901 333.187 516.262 330.331 516.262 325.646C516.262 320.961 519.886 317.986 523.825 317.986C527.764 317.986 530.79 320.902 530.94 325.2V325.735H530.955ZM517.759 324.605H529.487C529.127 321.214 526.791 319.295 523.84 319.295C521.055 319.295 518.194 321.259 517.774 324.605H517.759Z"
              fill="white"
            />
            <path
              d="M544 318.314L543.85 319.682C543.55 319.623 543.251 319.623 542.981 319.623C538.937 319.623 536.766 322.954 536.766 326.807V332.86H535.298V318.344H536.766V322.419C537.934 320.009 540.091 318.225 542.981 318.225C543.311 318.225 543.58 318.225 544 318.314Z"
              fill="white"
            />
            <path
              d="M194.26 335.001V310.891H194.919V335.016H194.26V335.001Z"
              fill="white"
            />
            <path
              d="M428.208 335.016V310.891H428.867V335.016H428.208Z"
              fill="white"
            />
          </g>
          <path
            d="M1.6364 284.943C0.724069 284.943 0 284.204 0 283.306V228.709C0 227.797 0.73855 227.072 1.6364 227.072H26.7326C37.5792 227.072 44.5882 233.345 44.5882 243.05C44.5882 248.786 41.9236 252.958 38.506 255.175C43.3572 257.463 46.3838 262.374 46.3838 268.473C46.3838 278.482 39.0852 284.943 27.8042 284.943H1.6364ZM27.225 276.121C32.9886 276.121 36.5799 272.978 36.5799 267.908C36.5799 263.113 32.9451 260.013 27.3119 260.013H9.717V276.121H27.2105H27.225ZM26.6457 251.51C31.8301 251.51 35.1897 248.323 35.1897 243.369C35.1897 238.748 31.7287 235.88 26.1534 235.88H9.717V251.495H26.6457V251.51Z"
            fill="url(#paint0_linear_135_139545)"
          />
          <path
            d="M33.7419 255.522C39.8531 256.261 44.7333 260.983 44.7333 268.472C44.7333 277.106 38.7814 283.306 27.7901 283.306H1.63672V228.709H26.7329C37.0002 228.709 42.9376 234.489 42.9376 243.05C42.9376 249.974 38.4629 254.624 33.7274 255.276V255.522H33.7419ZM8.08093 253.161H26.646C32.7572 253.161 36.8265 249.25 36.8265 243.383C36.8265 237.922 32.6703 234.257 26.1537 234.257H8.08093V253.161ZM27.2253 277.758C34.0605 277.758 38.2167 273.76 38.2167 267.893C38.2167 262.026 33.655 258.361 27.2977 258.361H8.08093V277.758H27.2253Z"
            fill="url(#paint1_linear_135_139545)"
          />
          <path
            d="M93.3906 284.943C92.9272 284.943 92.4783 284.74 92.1597 284.392L71.7989 261.563H64.1527V283.306C64.1527 284.219 63.4142 284.943 62.5163 284.943H56.0866C55.1743 284.943 54.4502 284.204 54.4502 283.306V228.709C54.4502 227.797 55.1887 227.072 56.0866 227.072H81.3421C92.9996 227.072 100.82 233.982 100.82 244.281C100.82 253.827 94.1436 260.491 83.9488 261.447L102.673 282.205C103.108 282.683 103.223 283.378 102.948 283.972C102.688 284.566 102.094 284.943 101.442 284.943H93.3761H93.3906ZM81.3421 252.408C87.2795 252.408 91.1026 249.221 91.1026 244.281C91.1026 239.342 87.265 236.14 81.3421 236.14H64.1672V252.408H81.3421Z"
            fill="url(#paint2_linear_135_139545)"
          />
          <path
            d="M93.3909 283.306L72.5378 259.926H62.5166V283.306H56.0869V228.709H81.3424C92.0152 228.709 99.1835 234.909 99.1835 244.267C99.1835 253.624 92.0152 259.911 81.3424 259.911H80.3577L101.457 283.291H93.3909V283.306ZM81.3424 254.059C88.1776 254.059 92.7538 250.148 92.7538 244.281C92.7538 238.414 88.1921 234.503 81.3424 234.503H62.5311V254.059H81.3424Z"
            fill="url(#paint3_linear_135_139545)"
          />
          <path
            d="M135.444 286C118.675 286 105.54 272.832 105.54 256C105.54 239.168 118.675 226 135.444 226C152.214 226 165.276 239.182 165.276 256C165.276 272.818 152.17 286 135.444 286ZM135.444 235.329C124.163 235.329 115.344 244.411 115.344 256.014C115.344 267.618 123.989 276.613 135.444 276.613C146.899 276.613 155.472 267.56 155.472 256.014C155.472 244.469 146.682 235.329 135.444 235.329Z"
            fill="url(#paint4_linear_135_139545)"
          />
          <path
            d="M163.625 256.015C163.625 271.906 151.244 284.363 135.444 284.363C119.645 284.363 107.177 271.891 107.177 256.015C107.177 240.138 119.558 227.666 135.444 227.666C151.33 227.666 163.625 240.211 163.625 256.015ZM157.123 256.015C157.123 243.47 147.594 233.692 135.459 233.692C123.323 233.692 113.708 243.47 113.708 256.015C113.708 268.559 123.237 278.25 135.459 278.25C147.681 278.25 157.123 268.632 157.123 256.015Z"
            fill="url(#paint5_linear_135_139545)"
          />
          <path
            d="M188.012 284.942C187.1 284.942 186.375 284.203 186.375 283.305V236.14H167.738C166.826 236.14 166.102 235.401 166.102 234.503V228.723C166.102 227.81 166.84 227.086 167.738 227.086H214.744C215.657 227.086 216.381 227.825 216.381 228.723V234.503C216.381 235.415 215.642 236.14 214.744 236.14H196.179V283.305C196.179 284.218 195.441 284.942 194.543 284.942H188.026H188.012Z"
            fill="url(#paint6_linear_135_139545)"
          />
          <path
            d="M194.528 234.502V283.305H188.011V234.502H167.723V228.723H214.729V234.502H194.528Z"
            fill="url(#paint7_linear_135_139545)"
          />
          <path
            d="M263.142 284.943C262.229 284.943 261.505 284.204 261.505 283.306V260.346H232.05V283.306C232.05 284.219 231.312 284.943 230.414 284.943H223.984C223.072 284.943 222.348 284.204 222.348 283.306V228.709C222.348 227.797 223.086 227.072 223.984 227.072H230.414C231.326 227.072 232.05 227.811 232.05 228.709V251.263H261.505V228.709C261.505 227.797 262.244 227.072 263.142 227.072H269.571C270.484 227.072 271.208 227.811 271.208 228.709V283.306C271.208 284.219 270.469 284.943 269.571 284.943H263.142Z"
            fill="url(#paint8_linear_135_139545)"
          />
          <path
            d="M269.585 283.306H263.156V258.695H230.413V283.306H223.983V228.709H230.413V252.915H263.156V228.709H269.585V283.306Z"
            fill="url(#paint9_linear_135_139545)"
          />
          <path
            d="M283.951 284.943C283.039 284.943 282.314 284.204 282.314 283.306V228.709C282.314 227.797 283.053 227.072 283.951 227.072H323.702C324.615 227.072 325.339 227.811 325.339 228.709V234.489C325.339 235.402 324.6 236.126 323.702 236.126H292.031V251.336H321.342C322.254 251.336 322.978 252.075 322.978 252.973V258.753C322.978 259.665 322.24 260.39 321.342 260.39H292.031V275.759H323.789C324.701 275.759 325.426 276.498 325.426 277.396V283.263C325.426 284.175 324.687 284.899 323.789 284.899H283.965L283.951 284.943Z"
            fill="url(#paint10_linear_135_139545)"
          />
          <path
            d="M323.775 283.306H283.951V228.709H323.703V234.489H290.395V252.987H321.342V258.767H290.395V277.425H323.789V283.291L323.775 283.306Z"
            fill="url(#paint11_linear_135_139545)"
          />
          <path
            d="M373.171 284.943C372.707 284.943 372.259 284.74 371.94 284.392L351.579 261.563H343.933V283.306C343.933 284.219 343.194 284.943 342.297 284.943H335.867C334.955 284.943 334.23 284.204 334.23 283.306V228.709C334.23 227.797 334.969 227.072 335.867 227.072H361.122C372.78 227.072 380.6 233.982 380.6 244.281C380.6 253.827 373.924 260.491 363.729 261.447L382.453 282.205C382.888 282.683 383.004 283.378 382.729 283.972C382.468 284.566 381.874 284.943 381.223 284.943H373.156H373.171ZM361.108 252.408C367.045 252.408 370.868 249.221 370.868 244.281C370.868 239.342 367.031 236.14 361.108 236.14H343.933V252.408H361.108Z"
            fill="url(#paint12_linear_135_139545)"
          />
          <path
            d="M373.171 283.306L352.318 259.926H342.297V283.306H335.867V228.709H361.123C371.795 228.709 378.964 234.909 378.964 244.267C378.964 253.624 371.795 259.911 361.123 259.911H360.138L381.237 283.291H373.171V283.306ZM361.108 254.059C367.943 254.059 372.52 250.148 372.52 244.281C372.52 238.414 367.958 234.503 361.108 234.503H342.297V254.059H361.108Z"
            fill="url(#paint13_linear_135_139545)"
          />
          <path
            d="M411.011 284.943C410.099 284.943 409.375 284.204 409.375 283.306V228.709C409.375 227.797 410.114 227.072 411.011 227.072H436.093C446.94 227.072 453.949 233.345 453.949 243.05C453.949 248.786 451.284 252.958 447.866 255.175C452.718 257.463 455.744 262.374 455.744 268.473C455.744 278.482 448.446 284.943 437.165 284.943H411.026H411.011ZM436.586 276.121C442.349 276.121 445.94 272.978 445.94 267.908C445.94 263.113 442.306 260.013 436.672 260.013H419.092V276.121H436.586ZM436.006 251.51C441.191 251.51 444.55 248.323 444.55 243.369C444.55 238.748 441.089 235.88 435.528 235.88H419.092V251.495H436.021L436.006 251.51Z"
            fill="url(#paint14_linear_135_139545)"
          />
          <path
            d="M443.1 255.522C449.212 256.261 454.092 260.983 454.092 268.472C454.092 277.106 448.14 283.306 437.149 283.306H411.01V228.709H436.092C446.359 228.709 452.296 234.489 452.296 243.05C452.296 249.974 447.821 254.624 443.086 255.276V255.522H443.1ZM417.439 253.161H436.005C442.116 253.161 446.185 249.25 446.185 243.383C446.185 237.922 442.029 234.257 435.512 234.257H417.425V253.161H417.439ZM436.584 277.758C443.419 277.758 447.575 273.76 447.575 267.893C447.575 262.026 443.014 258.361 436.656 258.361H417.439V277.758H436.584Z"
            fill="url(#paint15_linear_135_139545)"
          />
          <path
            d="M465.446 284.943C464.534 284.943 463.81 284.204 463.81 283.306V228.709C463.81 227.797 464.548 227.072 465.446 227.072H505.197C506.11 227.072 506.834 227.811 506.834 228.709V234.489C506.834 235.402 506.095 236.126 505.197 236.126H473.527V251.336H502.837C503.749 251.336 504.473 252.075 504.473 252.973V258.753C504.473 259.665 503.735 260.39 502.837 260.39H473.527V275.759H505.284C506.197 275.759 506.921 276.498 506.921 277.396V283.263C506.921 284.175 506.182 284.899 505.284 284.899H465.46L465.446 284.943Z"
            fill="url(#paint16_linear_135_139545)"
          />
          <path
            d="M505.269 283.306H465.445V228.709H505.197V234.489H471.889V252.987H502.836V258.767H471.889V277.425H505.284V283.291L505.269 283.306Z"
            fill="url(#paint17_linear_135_139545)"
          />
          <path
            d="M532.509 284.942C531.597 284.942 530.873 284.203 530.873 283.305V236.14H512.235C511.323 236.14 510.599 235.401 510.599 234.503V228.723C510.599 227.81 511.337 227.086 512.235 227.086H559.242C560.154 227.086 560.878 227.825 560.878 228.723V234.503C560.878 235.415 560.139 236.14 559.242 236.14H540.676V283.305C540.676 284.218 539.938 284.942 539.04 284.942H532.523H532.509Z"
            fill="url(#paint18_linear_135_139545)"
          />
          <path
            d="M539.026 234.502V283.305H532.509V234.502H512.221V228.723H559.227V234.502H539.026Z"
            fill="url(#paint19_linear_135_139545)"
          />
          <path
            d="M586.046 286C572.955 286 563.614 279.047 562.268 268.269C562.166 267.415 562.731 266.633 563.571 266.459L570.334 265.068C570.45 265.039 570.551 265.039 570.667 265.039C571 265.039 571.318 265.141 571.608 265.329C571.97 265.59 572.231 265.981 572.289 266.415C573.346 273.05 578.704 277.004 586.625 277.004C593.808 277.004 598.268 273.731 598.268 268.458C598.268 264.445 596.487 262.823 590.666 261.505L578.385 258.825C568.828 256.71 563.976 251.48 563.976 243.281C563.976 232.939 572.434 226 585.003 226C596.328 226 604.988 232.403 607.059 242.296C607.247 243.166 606.682 244.035 605.813 244.238L599.383 245.701C599.268 245.73 599.137 245.744 599.021 245.744C598.703 245.744 598.384 245.657 598.124 245.469C597.747 245.223 597.486 244.831 597.414 244.397C596.328 238.545 591.52 234.909 584.859 234.909C578.197 234.909 574.113 238.212 574.113 243.122C574.113 246.758 576.112 248.641 581.035 249.655L592.954 252.35C602.931 254.493 608 259.81 608 268.125C608 278.974 599.398 285.986 586.075 285.986L586.046 286Z"
            fill="url(#paint20_linear_135_139545)"
          />
          <path
            d="M605.438 242.643L599.008 244.106C597.792 237.515 592.332 233.271 584.831 233.271C577.329 233.271 572.449 237.254 572.449 243.135C572.449 248.017 575.548 250.219 580.675 251.276L592.564 253.956C600.137 255.593 606.336 259.417 606.336 268.138C606.336 278.075 598.269 284.347 586.047 284.347C574.476 284.347 565.194 278.394 563.891 268.051L570.653 266.66C571.87 274.323 578.155 278.64 586.612 278.64C595.069 278.64 599.891 274.483 599.891 268.456C599.891 263.328 597.125 261.286 591.014 259.895L578.72 257.201C571.711 255.651 565.599 251.986 565.599 243.266C565.599 233.893 573.332 227.621 584.99 227.621C595.909 227.621 603.555 233.647 605.438 242.614V242.643Z"
            fill="url(#paint21_linear_135_139545)"
          />
          <path
            d="M360.87 192C333.513 192 312.868 179.926 304 159.634C295.132 179.926 274.487 192 247.13 192H129.094C126.823 192 125 190.166 125 187.913V4.08726C125 1.81977 126.837 0 129.094 0H247.13C274.487 0 295.132 12.074 304 32.3659C312.868 12.0885 333.513 0 360.87 0H478.906C481.177 0 483 1.83421 483 4.08726V187.898C483 190.166 481.163 191.986 478.906 191.986H360.87V192ZM371.677 113.418C360.002 113.418 352.754 120.004 352.754 130.604C352.754 139.718 359.828 146.087 369.969 146.087H427.939V113.418H371.691H371.677ZM238.045 146.087C248.172 146.087 255.261 139.718 255.261 130.604C255.261 120.004 248.013 113.418 236.338 113.418H180.09V146.087H238.059H238.045ZM286.712 96C293.482 99.6395 299.848 105.619 304.014 113.967C308.181 105.619 314.532 99.6395 321.303 96C314.532 92.3605 308.166 86.3668 304.014 78.019C299.848 86.3668 293.497 92.3605 286.712 96ZM369.969 45.913C359.843 45.913 352.754 52.2822 352.754 61.3955C352.754 71.9964 360.002 78.5822 371.677 78.5822H427.924V45.913H369.955H369.969ZM236.338 78.5822C248.013 78.5822 255.261 71.9964 255.261 61.3955C255.261 52.2822 248.186 45.913 238.045 45.913H180.076V78.5822H236.323H236.338Z"
            fill="url(#paint22_linear_135_139545)"
          />
          <path
            d="M360.869 4.08789C329.331 4.08789 307.92 21.0435 304 47.2568C300.079 21.0435 278.668 4.08789 247.13 4.08789H129.094V95.9862V187.899H247.13C278.668 187.899 300.079 170.943 304 144.73C307.92 170.943 329.331 187.899 360.869 187.899H478.905V96.0006V4.08789H360.869ZM238.045 150.175H175.981V109.317H236.338C250.269 109.317 259.354 117.823 259.354 130.591C259.354 142.506 249.965 150.16 238.045 150.16V150.175ZM236.338 82.6845H175.981V41.8264H238.045C249.965 41.8264 259.354 49.481 259.354 61.3962C259.354 74.1634 250.255 82.6701 236.338 82.6701V82.6845ZM304.057 126.691L303.985 126.763C300.253 106.948 284.773 97.2427 273.604 96.0006C284.773 94.7586 300.238 85.0676 303.985 65.2668L304.072 65.3534C307.848 85.082 323.284 94.7441 334.424 95.9862C323.27 97.2283 307.819 106.905 304.057 126.677V126.691ZM432.032 150.175H369.969C358.048 150.175 348.659 142.52 348.659 130.605C348.659 117.838 357.759 109.331 371.676 109.331H432.032V150.189V150.175ZM432.032 82.6845H371.676C357.744 82.6845 348.659 74.1779 348.659 61.4106C348.659 49.4954 358.048 41.8409 369.969 41.8409H432.032V82.699V82.6845Z"
            fill="url(#paint23_linear_135_139545)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_135_139545"
            x1="23.1847"
            y1="264.243"
            x2="23.1847"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_135_139545"
            x1="23.185"
            y1="226.985"
            x2="23.185"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_135_139545"
            x1="78.7644"
            y1="264.243"
            x2="78.7644"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_135_139545"
            x1="78.7647"
            y1="226.985"
            x2="78.7647"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_135_139545"
            x1="135.415"
            y1="264.242"
            x2="135.415"
            y2="227.622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_135_139545"
            x1="135.415"
            y1="226.985"
            x2="135.415"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_135_139545"
            x1="191.241"
            y1="264.242"
            x2="191.241"
            y2="227.622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_135_139545"
            x1="191.24"
            y1="226.984"
            x2="191.24"
            y2="284.826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_135_139545"
            x1="246.778"
            y1="264.243"
            x2="246.778"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_135_139545"
            x1="246.777"
            y1="226.985"
            x2="246.777"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_135_139545"
            x1="303.863"
            y1="264.243"
            x2="303.863"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_135_139545"
            x1="303.863"
            y1="226.985"
            x2="303.863"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_135_139545"
            x1="358.545"
            y1="264.243"
            x2="358.545"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_135_139545"
            x1="358.545"
            y1="226.985"
            x2="358.545"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_135_139545"
            x1="432.545"
            y1="264.243"
            x2="432.545"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_135_139545"
            x1="432.544"
            y1="226.985"
            x2="432.544"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_135_139545"
            x1="485.358"
            y1="264.243"
            x2="485.358"
            y2="227.623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_135_139545"
            x1="485.357"
            y1="226.985"
            x2="485.357"
            y2="284.827"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_135_139545"
            x1="535.724"
            y1="264.242"
            x2="535.724"
            y2="227.622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_135_139545"
            x1="535.724"
            y1="226.984"
            x2="535.724"
            y2="284.826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_135_139545"
            x1="585.105"
            y1="264.242"
            x2="585.105"
            y2="227.622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_135_139545"
            x1="585.106"
            y1="226.984"
            x2="585.106"
            y2="284.825"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_135_139545"
            x1="304.333"
            y1="199.568"
            x2="303.684"
            y2="-7.26464"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_135_139545"
            x1="304.014"
            y1="-8.41941"
            x2="304.014"
            y2="206.212"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <clipPath id="clip0_135_139545">
            <rect width="608" height="360" fill="white" />
          </clipPath>
          <clipPath id="clip1_135_139545">
            <rect
              width="480"
              height="30"
              fill="white"
              transform="translate(64 310)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default BrotherBets4Logo;

import { useState } from "react";
import { termsAndContitions } from "src/utils/termsAndConditions";
import { ReactComponent as AddIcon } from "src/assets/icons/validation/Add.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Chevron Right.svg";
import { Button } from "src/components/atoms";
import { Footer, Header } from "src/components/organisms";
import ReactGA from "react-ga4";

const menu = [
  {
    id: 1,
    topic: "Terms & Conditions",
    content: termsAndContitions,
  },
  {
    id: 2,
    topic: "General Bonus Terms",
    content: termsAndContitions,
  },
  {
    id: 3,
    topic: "Privacy Policy",
    content: termsAndContitions,
  },
  {
    id: 4,
    topic: "Cookie Policy",
    content: termsAndContitions,
  },
  {
    id: 5,
    topic: "Responsivle Gambling",
    content: termsAndContitions,
  },
  {
    id: 6,
    topic: "Banking",
    content: termsAndContitions,
  },
];

export default function TermsAndContitionsPage() {
  const [selectedTopic, setSelectedTopic] = useState(1);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const MenuMobile = () => (
    <div className="md:hidden flex justify-between border-b border-secondary-100 pb-3.5 items-center w-full px-9 relative">
      <span className="font-extrabold text-base text-greyscale-100">
        {menu.find((item) => selectedTopic === item.id)?.topic}
      </span>
      <Button
        className="border border-secondary-100 text-secondary-100 p-1 rounded-md"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <ClearIcon /> : <AddIcon />}
      </Button>
      {isMobileMenuOpen && (
        <div className="absolute top-11 w-full h-fit left-0 overflow-clip border-b border-secondary-100">
          <div className="absolute bg-primary-400 opacity-95 h-screen w-full z-10" />
          <div className="z-20 relative flex flex-col gap-2 px-5 pb-6">
            {menu
              .filter((item) => item.id !== selectedTopic)
              .map((item, index) => (
                <div
                  key={`mobile_menu${index}`}
                  className="rounded-[10px] border border-greyscale-100 py-4 pl-6 pr-2.5 text-greyscale-100 text-sm flex items-center justify-between"
                >
                  <span>{item.topic}</span>
                  <div
                    className="text-secondary-100 text-[10px] flex items-center gap-4"
                    onClick={() => {
                      setSelectedTopic(item.id);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    <span>Read more</span>
                    <ArrowRightIcon />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );

  const MenuDesktop = () => (
    <div className="md:w-[231px] hidden md:block">
      <div className="text-[44px] text-secondary-100 leading-10 mb-9">
        All you need to know
      </div>
      <div className="flex flex-col gap-3.5">
        {menu.map((item, index) => (
          <span
            className={`${
              item.id === selectedTopic
                ? "text-secondary-100 underline"
                : "text-greyscale-100 opacity-50"
            } cursor-pointer`}
            onClick={() => setSelectedTopic(item.id)}
            key={`menu${index}`}
          >
            {item.topic}
          </span>
        ))}
      </div>
    </div>
  );

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Terms and Conditions Page",
  });

  return (
    <div className="font-main w-full relative">
      <Header />
      <div className="flex flex-col md:flex-row bg-custom-gradient md:gap-20 gap-10 justify-center md:px-52 md:py-40 py-6 px-6 w-full">
        <MenuMobile />
        <MenuDesktop />
        <div className="md:w-fit text-greyscale-100 break-words">
          <div className="text-[34px] text-secondary-100 font-bold mb-12">
            {menu.find((item) => selectedTopic === item.id)?.topic}
          </div>
          {menu
            .find((item) => selectedTopic === item.id)
            ?.content.map((item, index) => (
              <div key={`content_title${index}`}>
                <span className="text-xl font-semibold">{item.title}</span>
                <div className="flex flex-col my-12">
                  {item.content.map((item, index) => (
                    <span key={`content${index}`}>{item}</span>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputText_InputText__khdSM :disabled {
  opacity: 0.6;
  --tw-border-opacity: 0.1;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/inputFields/inputText/InputText.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,wBAAA;AAAJ","sourcesContent":[".InputText {\n  :disabled {\n    opacity: 0.6;\n    --tw-border-opacity: 0.1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputText": `InputText_InputText__khdSM`
};
export default ___CSS_LOADER_EXPORT___;

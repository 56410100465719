import { t } from "i18next";
import { Footer, Header } from "src/components/organisms";

export default function AboutUsPage() {
  return (
    <div className="font-main w-full relative">
      <Header />
      <div className="flex flex-col bg-custom-gradient  gap-10 justify-center md:px-52 md:py-40 py-6 px-6 w-full text-greyscale-100">
        <div className="text-2xl font-semibold">{t("button_about")}</div>
        <div className="flex flex-col gap-4">
          <div className="text-lg">{t("text_page_about-us-1")}</div>
          <div className="flex flex-col gap-2 md:pl-20">
            <div>{t("text_page_about-us-2")}</div>
            <div>{t("text_page_about-us-3")}</div>
            <div>{t("text_page_about-us-4")}</div>
            <div>{t("text_page_about-us-5")}</div>
          </div>
          <div className="font-bold">{t("text_page_about-us-6")}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

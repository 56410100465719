import { useEffect } from "react";
import { Footer, Header } from "src/components/organisms";
import { ReactComponent as CelebrateIcon } from "src/assets/icons/emoji/Celebration.svg";
import useNotificationsService from "src/services/notificationsService";
import useBackOfficeService from "src/services/backOfficeService";
import { useTranslation } from "react-i18next";

export default function ActivateAccountPage() {
  const { t } = useTranslation();
  const { addErrorNotification } = useNotificationsService();
  const { getStatuses, updatePlayerStatus } = useBackOfficeService();
  useEffect(() => {
    const activateAccount = async (): Promise<void> => {
      try {
        const playerId = localStorage.getItem("player_id");
        if (!playerId) {
          addErrorNotification(t("error_active-on-same-device"));
          throw new Error("Player ID not found");
        } else {
          const { result: statusesList } = await getStatuses();
          const activeStatusId = statusesList.find(
            (status) => status.name === "active"
          )?.id;
          if (!activeStatusId) {
            addErrorNotification(t("error_active-status-not-found"));
          } else {
            await updatePlayerStatus(playerId, {
              player_status_id: activeStatusId,
              reason: "Player registration",
            });
          }
        }
      } catch (error: any) {
        addErrorNotification(t("error_failed-to-activate"));
        console.log("Failed to activate account: ", error);
      }
    };

    activateAccount();
  }, []);

  return (
    <div className="font-main w-full">
      <Header />
      <div className="flex flex-col md:gap-20 gap-10 justify-center items-center xl:px-60 md:px-24 pt-40 pb-80 px-6 bg-custom-gradient">
        <div className="flex flex-col px-48 gap-[54px] justify-center items-center">
          <CelebrateIcon className={`mx-auto w-[85px] text-secondary-400`} />
          <div className="flex flex-col gap-16 justify-center">
            <h1 className="text-5xl font-bold text-white text-center">
              {t("text_congratulations")}!
            </h1>
            <p className="text-2xl text-white text-center">
              {t("text_account-activated")}
            </p>
            <p className="text-2xl text-white text-center">
              {t("text_close-window")}!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { Footer, Header } from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import { getToken } from "src/services/miraclService";

export default function MiraclRegisterRedirectPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { playerData, setHasMiraclAuth, setMiraclEmail, setMiraclPlayerId } =
    useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (code && playerData) {
      getToken(
        code,
        false,
        () => {
          setHasMiraclAuth(true);
          setMiraclEmail(playerData?.email);
          setMiraclPlayerId(playerData?.id);
          navigate("/");
        },
        null,
        null
      );
    } else {
    }
  }, [code]);
  return (
    <div className="font-main w-full relative">
      <Header />
      <div className="flex flex-col bg-custom-gradient  gap-10 justify-center md:px-52 md:py-40 py-6 px-6 w-full text-greyscale-100">
        <MoonLoader
          color="#fff"
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      <Footer />
    </div>
  );
}

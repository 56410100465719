import React, { createContext, ReactNode, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import withScroll, { ScrollProps } from "src/higherOrderComponents/withScroll";

interface ScrollContextProps {
  // skips the next scroll. use it e.g. before invoking navigate()
  // so it keeps the page position
  setSkipNext: (skipNext: boolean) => void;
}

const ScrollContext = createContext<ScrollContextProps>({
  setSkipNext: () => {},
});

// deliberately outside the function component. DO NOT define this inside the
// FC, or it will break skipNext with excessive re-renders
const Scroll = withScroll((props: ScrollProps) => <>{props.children}</>);

// scroll provider to avoid prop drilling just to skip the scrolling as needed
export const ScrollProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [skipNext, setSkipNext] = useState(false);
  return (
    <ScrollContext.Provider value={{ setSkipNext }}>
      {children}
      <Outlet />
      <Scroll skipNext={skipNext} />
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context;
};

import { useTranslation } from "react-i18next";
import { ReactComponent as RightArrowIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as SquareUserIcon } from "src/assets/icons/account/user.svg";
import { ReactComponent as LockIcon } from "src/assets/icons/security/Lock 1.svg";
import { Button } from "src/components/atoms";
import { GameCardSimple } from "src/components/molecules";
import {
  AccountProfileStats,
  ChangePasswordModal,
  FormEditProfile,
  ProfileSlider,
} from "src/components/organisms";
import ReactGA from "react-ga4";
import { useState } from "react";

export interface ProfilePageProps {
  isEditing: boolean;
}

export default function ProfilePage({ isEditing }: ProfilePageProps) {
  const { t } = useTranslation();
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);

  const gameCardsList = [
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <GameCardSimple
      name="Sugar Rush"
      image="/images/games/sugar_rush_simple.png"
    />,
    <div />,
    <div />,
  ];

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Profile Page",
  });

  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className="w-full bg-greyscale-100 relative">
        {isEditing && (
          <div className="w-full flex items-center bg-secondary-200 py-5 gap-2.5 px-6">
            <div className="flex gap-2 mx-auto">
              <SquareUserIcon className="text-primary-400" />
              <span className="text-xl leading-[26px] text-primary-400 font-bold">
                {t("text_customer-profile")}
              </span>
            </div>
            <div className="flex flex-col gap-3.5 justify-center w-fit">
              {/* <span className="text-primary-100 text-xs leading-[14px] mx-auto">
                Save your changes to proceed
              </span> */}
              <button
                type="button"
                onClick={() => setChangePasswordModalOpen(true)}
                className="absolute right-14 border border-primary-400 bg-transparent text-primary-400 text-sm flex py-2.5 px-6 gap-4 items-center rounded transition"
              >
                <span className="mx-auto">{t("text_change-password")}</span>
                <LockIcon className="text-right" />
              </button>
            </div>
          </div>
        )}
        <ChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
        <div className="py-16 px-14">
          {isEditing ? (
            <FormEditProfile />
          ) : (
            <div className="flex flex-col gap-14">
              <div className="min-[1760px]:w-8/12">
                <AccountProfileStats />
              </div>
              <div className="h-full min-[1760px]:w-[366px] min-[1760px]:absolute z-10 right-0 top-0">
                <div className="h-full w-full backdrop-blur-md pt-12 px-11 flex flex-col gap-6 max-[1760px]:justify-between">
                  <div className="pl-6 text-primary-400 text-xl font-bold max-[1760px]:hidden">
                    {t("text_my-bonuses")}
                  </div>
                  <div className="rounded-10 bg-primary-400 flex flex-col items-center text-white gap-2.5 p-6">
                    <div className="flex flex-col items-center text-2xl uppercase">
                      <span>{t("text_bet")} £5</span>
                      <span className="font-semibold">{t("text_get")} £5</span>
                    </div>
                    <Button className="bg-secondary-200 rounded flex items-center justify-center py-3.5 px-6 text-black text-sm w-full">
                      {t("button_claim")}
                    </Button>
                  </div>
                  <div className="rounded-10 bg-[#FF8A4A] flex flex-col items-center text-black gap-2.5 p-6">
                    <div className="flex flex-col items-center text-2xl uppercase">
                      <span>{t("text_referral-received")}</span>
                      <span className="font-semibold">
                        {t("text_claim")} £50
                      </span>
                    </div>
                    <Button className="border border-black rounded flex items-center justify-center py-3.5 px-6 text-black text-sm w-full gap-3.5">
                      {t("button_claim")}
                      <RightArrowIcon />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block 2xl:w-[calc(100vw-945px)] xl:w-[calc(100vw-575px)] lg:w-[calc(100vw-265px)]">
                <ProfileSlider contentList={gameCardsList} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

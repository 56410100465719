import { useTranslation } from "react-i18next";
import { WalletTables } from "src/components/organisms";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
import { ReactComponent as AddPaymentCardIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as GiftIcon } from "src/assets/icons/shopping/Gift.svg";
import { ReactComponent as MoneyDollarIcon } from "src/assets/icons/money&payment/Money Dollar.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useAuth } from "src/context/AuthContext";
import useTransactionService from "src/services/transactionService";
import useNotificationsService from "src/services/notificationsService";

interface PreferredWallet {
  currency_code: string;
  balance: number | string;
}

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export default function WalletPage() {
  const { t } = useTranslation();
  const [preferredWallet, setPreferredWallet] = useState<PreferredWallet>({
    currency_code: "",
    balance: 0,
  });
  const { playerData } = useAuth();
  const { getBalances, getTransactions } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Wallet Page",
  });

  useEffect(() => {
    const setWalletValues = async (): Promise<void> => {
      if (playerData?.id) {
        try {
          const { result: wallets } = await getBalances(playerData.id);
          const preferredWallet = wallets.find(
            (wallet) => wallet.currency_id === playerData.currency_id
          );
          if (!preferredWallet) {
            addErrorNotification("Error fidding preferred wallet");
            return;
          }
          setPreferredWallet({
            currency_code: preferredWallet.alphabetic_code,
            balance: preferredWallet.amount,
          });
        } catch (error) {
          addErrorNotification("Error getting wallets");
          console.error("Error getting wallets: ", error);
        }
        return;
      }
    };
    setWalletValues();
  }, []);

  useEffect(() => {
    const getUserTransactions = async () => {
      if (!playerData?.id) return;
      try {
        const { result } = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification("Error getting transactions");
        console.error("Error getting transactions: ", error);
      }
    };
    getUserTransactions();
  }, []);

  return (
    <div className="flex flex-col rounded-b border">
      <div className="w-full flex justify-center items-center bg-secondary-200 py-5 gap-2.5">
        <WalletIcon className="text-primary-400" />
        <span className="text-xl leading-[26px] text-primary-400 font-bold">
          Wallet
        </span>
      </div>
      <div className="w-full bg-greyscale-100 py-16 px-14 flex lg:flex-row flex-col gap-20">
        <div className="flex flex-col gap-3.5">
          <div className="flex gap-3.5">
            <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
              <AddPaymentCardIcon className="w-11 h-11" />
              <span className="text-primary-400 text-xl leading-[26px] font-bold">
                {preferredWallet.currency_code === "EUR" ? "€" : "$"}
                {transactions
                  .filter((transaction) => transaction.method === "deposit")
                  .reduce((sum, curr) => sum + curr.value, 0)}
              </span>
              <span className="text-primary-100 leading-[22px] font-bold">
                {t("text_deposits")}
              </span>
            </div>
            <div
              className={`rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px] `}
            >
              <MoneyDollarIcon className="w-11 h-11" />
              <span className="text-primary-400 text-xl leading-[26px] font-bold">
                {preferredWallet.currency_code === "EUR" ? "€" : "$"}
                {transactions
                  .filter((transaction) => transaction.method === "withdraw")
                  .reduce((sum, curr) => sum + Math.abs(curr.value), 0)}
              </span>
              <span className="text-primary-100 leading-[22px] font-bold">
                {t("text_withdrawals")}
              </span>
            </div>
          </div>
          <div className="flex gap-3.5">
            <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
              <GiftIcon className="w-11 h-11" />
              <span className="text-primary-400 text-xl leading-[26px] font-bold">
                {preferredWallet.currency_code === "EUR" ? "€" : "$"}
                {transactions
                  .filter((transaction) => transaction.method === "win")
                  .reduce((sum, curr) => sum + curr.value, 0)}
              </span>
              <span className="text-primary-100 leading-[22px] font-bold">
                {t("text_winnings")}
              </span>
            </div>
            <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
              <ControllerIcon className="w-11 h-11" />
              <span className="text-primary-400 text-xl leading-[26px] font-bold">
                {preferredWallet.currency_code === "EUR" ? "€" : "$"}
                {transactions
                  .filter((transaction) => transaction.method === "wager")
                  .reduce((sum, curr) => sum + curr.value, 0)}
              </span>
              <span className="text-primary-100 leading-[22px] font-bold">
                {t("text-total-bets")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3.5 flex-grow">
          <div className="flex gap-3.5">
            <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px] border-2 border-secondary-300">
              <span className="text-3xl font-bold text-primary-400">
                {preferredWallet.currency_code === "EUR" ? "€" : "$"}
              </span>
              <span className="text-primary-400 text-xl leading-[26px] font-bold">
                {preferredWallet.balance}
              </span>
              <span className="text-primary-100 leading-[22px] font-bold">
                {t("text_balance")}
              </span>
            </div>
          </div>
          <WalletTables />
        </div>
      </div>
    </div>
  );
}

export const tempGames = [
    "3517",
    "3545",
    "3597",
    "3599",
    "3601",
    "6579",
    "7085",
    "7087",
    "124645",
    "124647",
    "125137",
    "125191",
    "125193",
    "125195",
    "125197",
    "125199",
    "125201",
    "125203",
    "125205",
    "125227",
    "125229",
    "125231",
    "125233",
    "125235",
    "125237",
    "125247",
    "125249",
    "125255",
    "125257",
    "125263",
    "125265",
    "125267",
    "125269",
    "125271",
    "125273",
    "125275",
    "125277",
    "125279",
    "125281",
    "125283",
    "125285",
    "125287",
    "125289",
    "125291",
    "125293",
    "125295",
    "125297",
    "125299",
    "125301",
    "125303",
    "125305",
    "125307",
    "125309",
    "125311",
    "125313",
    "125315",
    "125317",
    "125319",
    "149987",
    "149989",
    "149991",
    "149993",
    "149995",
    "149997",
    "137147",
    "155623",
    "155625",
    "155627",
    "155629",
    "155631",
    "155633",
    "155635",
    "155637",
    "148515",
    "148517",
    "148519",
    "148521",
    "149249",
    "149251",
    "146285",
    "146287",
    "146289",
    "146291",
    "146293",
    "146295",
    "158462",
    "158463",
    "158464",
    "158465",
    "158466",
    "158467",
    "158468",
    "158469",
    "158470",
    "158471",
    "158472",
    "158473",
    "158474",
    "158475",
    "158476",
    "158477",
    "158478",
    "158479",
    "158480",
    "158481",
    "158482",
    "158483",
    "158484",
    "158485",
    "158486",
    "158487",
    "158488",
    "158489",
    "158490",
    "158491",
    "158492",
    "158493",
    "158494",
    "158495",
    "158496",
    "158497",
    "158498",
    "158499",
    "158500",
    "158501",
    "158502",
    "158503",
    "158504",
    "158505",
    "3591",
    "11925",
    "11927",
    "11929",
    "11931",
    "11933",
    "11935",
    "11937",
    "11939",
    "11941",
    "11943",
    "11945",
    "11947",
    "11949",
    "11951",
    "11953",
    "11955",
    "11957",
    "11959",
    "11961",
    "11963",
    "11965",
    "11967",
    "11969",
    "11971",
    "11975",
    "11977",
    "11979",
    "11981",
    "11983",
    "11985",
    "11987",
    "11989",
    "11991",
    "11993",
    "11995",
    "11997",
    "11999",
    "12001",
    "12003",
    "12005",
    "12007",
    "12009",
    "12011",
    "12013",
    "12015",
    "12017",
    "12019",
    "12021",
    "12023",
    "12025",
    "12027",
    "12029",
    "12031",
    "12033",
    "12035",
    "12037",
    "12039",
    "12041",
    "12043",
    "12045",
    "12047",
    "12049",
    "12051",
    "12053",
    "12055",
    "12057",
    "12059",
    "12061",
    "12063",
    "12065",
    "12067",
    "12069",
    "12071",
    "12073",
    "12075",
    "12077",
    "12079",
    "12081",
    "12083",
    "12085",
    "12087",
    "12089",
    "12091",
    "12093",
    "12095",
    "12097",
    "12099",
    "12101",
    "12103",
    "12105",
    "12107",
    "12109",
    "12111",
    "12113",
    "12115",
    "12117",
    "12119",
    "12121",
    "12123",
    "12125",
    "12127",
    "12129",
    "12131",
    "12133",
    "47169",
    "47171",
    "3519",
    "3523",
    "3529",
    "3531",
    "3535",
    "3537",
    "3539",
    "3541",
    "3595",
    "6083",
    "139941",
    "139943",
    "139945",
    "139947",
    "139949",
    "139951",
    "140237",
    "140239",
    "140241",
    "140243",
    "140245",
    "140247",
    "140249",
    "140251",
    "141249",
    "141251",
    "141253",
    "141255",
    "146297",
    "146299",
    "146301",
    "146303",
    "146305",
    "146307",
    "146309",
    "146311",
    "146313",
    "146315",
    "146317",
    "146319",
    "146321",
    "146323",
    "146325",
    "146327",
    "146329",
    "146331",
    "146333",
    "146335",
    "146337",
    "146339",
    "146341",
    "146343",
    "149999",
    "150001",
    "150003",
    "150005",
    "150007",
    "150009",
    "151083",
    "151085",
    "151087",
    "151089",
    "151091",
    "151093",
    "151127",
    "151129",
    "124649",
    "124651",
    "124653",
    "124655",
    "124657",
    "124659",
    "124661",
    "124663",
    "124665",
    "124667",
    "124669",
    "124671",
    "124673",
    "124675",
    "124677",
    "124679",
    "124681",
    "124683",
    "124685",
    "124687",
    "124689",
    "124691",
    "124693",
    "124695",
    "124697",
    "124699",
    "124701",
    "124703",
    "124705",
    "124707",
    "124709",
    "124711",
    "124713",
    "124715",
    "124717",
    "124719",
    "124721",
    "124723",
    "124725",
    "124727",
    "124729",
    "124731",
    "124733",
    "124735",
    "124737",
    "124739",
    "124741",
    "124743",
    "124745",
    "124747",
    "124749",
    "124751",
    "124753",
    "124755",
    "124757",
    "124759",
    "124761",
    "124763",
    "124765",
    "124767",
    "124769",
    "124771",
    "124773",
    "124775",
    "124777",
    "124779",
    "124781",
    "124783",
    "124785",
    "124787",
    "124789",
    "124791",
    "124793",
    "124795",
    "124797",
    "124799",
    "124801",
    "124803",
    "124805",
    "124807",
    "124809",
    "124811",
    "124813",
    "124815",
    "124817",
    "124819",
    "124821",
    "124823",
    "124825",
    "124827",
    "124829",
    "124831",
    "124833",
    "124835",
    "124837",
    "124839",
    "124841",
    "124843",
    "124845",
    "124847",
    "124849",
    "124851",
    "124853",
    "124855",
    "124857",
    "124859",
    "124861",
    "124863",
    "124865",
    "124867",
    "124869",
    "124871",
    "124873",
    "124875",
    "124877",
    "124879",
    "124881",
    "124883",
    "124885",
    "124887",
    "124889",
    "124891",
    "124893",
    "124895",
    "124897",
    "124899",
    "124901",
    "124903",
    "124905",
    "124907",
    "124909",
    "124911",
    "124913",
    "124915",
    "124917",
    "124919",
    "124921",
    "124923",
    "124925",
    "124927",
    "124929",
    "124931",
    "124933",
    "124935",
    "124937",
    "124939",
    "124941",
    "124943",
    "124945",
    "124947",
    "124949",
    "124951",
    "124953",
    "124955",
    "124957",
    "124959",
    "124961",
    "124963",
    "124965",
    "124967",
    "124969",
    "124971",
    "124973",
    "124975",
    "124977",
    "124979",
    "124981",
    "124983",
    "124985",
    "124987",
    "124989",
    "124991",
    "124993",
    "124995",
    "124997",
    "124999",
    "125001",
    "125003",
    "125005",
    "125007",
    "125009",
    "125011",
    "125013",
    "125015",
    "125017",
    "125019",
    "125021",
    "125023",
    "125025",
    "125027",
    "125029",
    "125031",
    "125033",
    "125035",
    "125037",
    "125039",
    "125041",
    "125043",
    "125045",
    "125047",
    "125049",
    "125051",
    "125053",
    "125055",
    "125057",
    "125059",
    "125061",
    "125063",
    "125065",
    "125067",
    "125069",
    "125071",
    "125073",
    "125075",
    "125077",
    "125079",
    "125081",
    "125083",
    "125085",
    "125087",
    "125089",
    "125091",
    "125093",
    "125095",
    "125097",
    "125099",
    "125101",
    "125103",
    "125105",
    "125107",
    "125109",
    "125111",
    "125113",
    "125115",
    "125117",
    "125119",
    "125121",
    "125123",
    "125125",
    "125127",
    "125129",
    "125181",
    "125183",
    "125211",
    "125213",
    "125215",
    "125217",
    "125223",
    "125225",
    "125239",
    "125241",
    "125243",
    "125245",
    "125251",
    "125253",
    "125321",
    "125323",
    "125325",
    "125327",
    "125329",
    "125331",
    "125333",
    "125335",
    "125337",
    "125339",
    "114599",
    "115137",
    "104151",
    "104153",
    "104373",
    "104515",
    "156428",
    "156429",
    "156430",
    "156431",
    "156432",
    "156433",
    "156434",
    "156435",
    "156442",
    "156443",
    "156492",
    "156493",
    "156494",
    "156495",
    "156496",
    "156497",
    "156498",
    "156499",
    "153791",
    "153793",
    "150627",
    "150629",
    "150631",
    "150633",
    "138917",
    "138919",
    "138921",
    "138923",
    "138925",
    "138927",
    "138933",
    "138935",
    "139185",
    "139187",
    "134679",
    "134681",
    "156618",
    "156619",
    "156666",
    "156667",
    "156668",
    "156669",
    "156670",
    "156671",
    "156672",
    "156673",
    "156674",
    "156675",
    "156676",
    "156677",
    "156678",
    "156679",
    "156680",
    "156681",
    "156682",
    "156683",
    "156684",
    "156685",
    "156694",
    "156695",
    "156697",
    "156698",
    "156699",
    "156700",
    "156714",
    "156715",
    "156716",
    "156717",
    "156802",
    "156803",
    "156886",
    "156887",
    "156888",
    "156889",
    "156986",
    "156987",
    "157000",
    "157001",
    "157192",
    "157193",
    "157194",
    "157195",
    "157238",
    "157239",
    "157240",
    "157241",
    "157376",
    "157377",
    "162480",
    "162481",
    "162482",
    "162483",
    "162484",
    "162485",
    "162486",
    "162487",
    "162488",
    "162489",
    "162490",
    "162491",
    "162492",
    "162493",
    "162494",
    "162495",
    "162496",
    "162497",
    "162498",
    "162499",
    "162500",
    "162501",
    "162502",
    "162503",
    "162504",
    "162505",
    "162506",
    "162507",
    "162508",
    "162509",
    "162946",
    "162947",
    "163184",
    "163185",
    "163186",
    "163187",
    "163188",
    "163189",
    "163190",
    "163191",
    "163192",
    "163193",
    "163194",
    "163195",
    "163196",
    "163197",
    "163198",
    "163199",
    "163200",
    "163201",
    "163202",
    "163203",
    "163204",
    "163205",
    "163206",
    "163207",
    "163208",
    "163209",
    "163210",
    "163211",
    "163212",
    "163213",
    "160408",
    "160409",
    "160476",
    "160477",
    "160710",
    "160711",
    "160846",
    "160847",
    "160848",
    "160849",
    "160850",
    "160851",
    "161312",
    "161313",
    "159120",
    "159121",
    "159340",
    "159341",
    "159342",
    "159343",
    "159344",
    "159345",
    "159346",
    "159347",
    "159348",
    "159349",
    "157555",
    "157556",
    "157603",
    "157604",
    "157605",
    "157606",
    "157607",
    "157608",
    "157609",
    "157610",
    "157611",
    "157612",
    "157613",
    "157614",
    "157615",
    "157713",
    "157714",
    "157715",
    "157716",
    "157912",
    "157913",
    "158020",
    "158021",
    "158022",
    "158023",
    "158024",
    "158025",
    "158026",
    "158027",
    "158242",
    "158243",
    "158244",
    "158245",
    "158332",
    "158333",
    "158344",
    "158345",
    "160022",
    "160023",
    "160024",
    "160025",
    "160026",
    "160027",
    "160028",
    "160029",
    "160030",
    "160031",
    "160132",
    "160133",
    "160134",
    "160135",
    "160136",
    "160137",
    "160138",
    "160139",
    "160140",
    "160141",
    "160170",
    "160171",
    "160198",
    "160199",
    "160200",
    "160201",
    "160202",
    "160203",
    "160204",
    "160205",
    "160206",
    "160207",
    "160208",
    "160209",
    "160210",
    "160211",
    "160212",
    "160213",
    "160214",
    "160215",
    "160216",
    "160217",
    "160260",
    "160261",
    "160262",
    "160263",
    "160264",
    "160265",
    "160266",
    "160267",
    "160268",
    "160269",
    "160270",
    "160271",
    "160272",
    "160273",
    "160274",
    "160275",
    "160276",
    "160277",
    "160336",
    "160337",
    "160338",
    "160339",
    "160340",
    "160341",
    "160342",
    "160343",
    "160344",
    "160345",
    "161420",
    "161421",
    "161608",
    "161609",
    "161614",
    "161615",
    "161860",
    "161861",
    "161862",
    "161863",
    "161864",
    "161865",
    "161866",
    "161867",
    "161868",
    "161869",
    "161870",
    "161871",
    "161872",
    "161873",
    "161874",
    "161875",
    "162132",
    "162133",
    "162244",
    "162245",
    "163310",
    "163311",
    "129919",
    "129921",
    "129927",
    "129929",
    "129931",
    "129933",
    "129935",
    "129937",
    "129939",
    "129941",
    "130007",
    "130009",
    "130035",
    "130037",
    "130043",
    "130045",
    "130047",
    "130049",
    "130079",
    "130081",
    "130083",
    "130085",
    "115287",
    "125207",
    "125209",
    "120179",
    "120219",
    "120221",
    "121213",
    "121215",
    "121225",
    "121227",
    "121233",
    "155439",
    "155441",
    "129923",
    "129925",
    "130003",
    "130005",
    "130027",
    "130029",
    "130059",
    "130061",
    "125141",
    "125143",
    "125147",
    "125151",
    "125153",
    "125155",
    "125157",
    "112325",
    "112327",
    "3461",
    "6017",
    "13953",
    "14391",
    "12229",
    "12231",
    "12407",
    "125133",
    "125135",
    "125139",
    "125642",
    "125666",
    "115289",
    "125145",
    "12213",
    "12215",
    "12217",
    "12219",
    "12221",
    "12223",
    "12225",
    "12227",
    "104967",
    "104969",
    "7463",
    "7465",
    "47173",
    "47175",
    "3515",
    "3521",
    "3533",
    "3593",
    "6081",
    "13811",
    "13813",
    "13815",
    "13817",
    "12135",
    "12137",
    "12139",
    "12141",
    "12143",
    "12145",
    "12147",
    "12149",
    "12151",
    "12153",
    "12155",
    "12157",
    "12159",
    "12161",
    "12163",
    "12165",
    "12167",
    "12169",
    "12171",
    "12173",
    "12175",
    "12177",
    "12179",
    "12181",
    "12183",
    "12185",
    "12187",
    "12189",
    "12195",
    "12197",
    "12199",
    "12201",
    "12203",
    "12205",
    "12207",
    "12209",
    "12211",
    "125149",
    "125185",
    "125187",
    "125219",
    "125221",
    "125259",
    "125261",
    "148975",
    "148977",
    "149245",
    "149247",
    "144815",
    "144817",
    "121217",
    "121219",
    "121221",
    "121223",
    "129915",
    "129917",
    "129943",
    "129945",
    "129947",
    "129949",
    "129951",
    "129953",
    "129955",
    "129957",
    "129959",
    "129961",
    "129963",
    "129965",
    "129967",
    "129969",
    "129971",
    "129973",
    "129975",
    "129977",
    "129979",
    "129981",
    "129983",
    "129985",
    "129987",
    "129989",
    "129991",
    "129993",
    "129995",
    "129997",
    "129999",
    "130001",
    "130011",
    "130013",
    "130015",
    "130017",
    "130019",
    "130021",
    "130023",
    "130025",
    "130031",
    "130033",
    "130039",
    "130041",
    "130051",
    "130053",
    "130055",
    "130057",
    "130063",
    "130065",
    "130067",
    "130069",
    "130071",
    "130073",
    "130075",
    "130077",
    "130087",
    "130089",
    "125131",
    "125159",
    "125161",
    "125163",
    "125165",
    "125167",
    "125169",
    "125171",
    "125173",
    "125175",
    "125177",
    "125179",
    "125189",
    "115861",
    "115863",
    "118307",
    "118309",
    "118311",
    "118313",
    "118315",
    "118317",
    "118319",
    "118321",
    "151159",
    "151161",
    "153543",
    "153545",
    "153547",
    "153549",
    "153551",
    "153553",
    "153555",
    "153557",
    "153559",
    "153561",
    "153563",
    "153565",
    "153567",
    "153569",
    "153571",
    "153573",
    "153575",
    "153577",
    "153579",
    "153581",
    "153583",
    "153585",
    "153587",
    "153589",
    "153591",
    "153593",
    "153595",
    "153597",
    "153599",
    "153601",
    "149145",
    "149147",
    "149605",
    "149607",
    "138897",
    "138899",
    "138901",
    "138903",
    "138905",
    "138907",
    "138909",
    "138911",
    "138913",
    "138915",
    "138941",
    "138943",
    "138945",
    "138947",
    "138949",
    "138951",
    "138953",
    "138955",
    "138957",
    "138959",
    "138961",
    "138963",
    "138965",
    "138967",
    "138969",
    "138971",
    "138973",
    "138975",
    "138977",
    "138979",
    "138981",
    "138983",
    "138985",
    "138987",
    "138989",
    "138991",
    "138993",
    "138995",
    "138997",
    "138999",
    "139001",
    "139003",
    "139005",
    "139007",
    "139009",
    "139011",
    "139013",
    "139015",
    "139017",
    "139019",
    "139021",
    "139023",
    "139025",
    "139027",
    "139029",
    "139031",
    "139033",
    "139035",
    "139037",
    "139039",
    "139041",
    "139043",
    "139045",
    "139047",
    "140741",
    "140743",
    "140745",
    "140747",
    "140749",
    "140751",
    "140753",
    "140755",
    "140757",
    "140759",
    "140761",
    "140763",
    "140765",
    "140767",
    "140769",
    "140771",
    "140773",
    "140775",
    "140777",
    "140779",
    "140781",
    "140783",
    "140785",
    "140787",
    "140789",
    "140791",
    "140793",
    "140795",
    "140797",
    "140799",
    "140961",
    "140963",
    "142693",
    "142695",
    "142697",
    "142699",
    "142701",
    "142703",
    "142705",
    "142707",
    "143441",
    "143443",
    "147659",
    "147661",
    "147663",
    "147665",
    "147667",
    "147669",
    "154859",
    "154861",
    "155943",
    "155944",
    "155945",
    "155946",
    "155947",
    "155948",
    "155949",
    "155950",
    "155951",
    "155952",
    "155953",
    "155954",
    "155955",
    "157780",
    "157781",
    "162910",
    "162911",
    "109893",
    "109895",
    "112329",
    "112331",
    "112333",
    "112335",
    "112339",
    "114589",
    "114593",
    "114595",
    "114597",
    "115121",
    "104143",
    "104145",
    "104147",
    "104149",
    "104155",
    "104157",
    "104159",
    "104161",
    "104163",
    "104165",
    "13955",
    "14389",
    "155942",
    "125341",
    "125343"
  ];
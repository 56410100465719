import { useState } from "react";
import { ReactComponent as ChevronUpIcon } from "src/assets/icons/arrows/Chevron Up.svg";
import { ReactComponent as ChevronDownIcon } from "src/assets/icons/arrows/Chevron Down.svg";
import { ReactComponent as CheckedIcon } from "src/assets/icons/validation/Check Box.svg";
import { ReactComponent as UncheckedIcon } from "src/assets/icons/shapes/Square.svg";
import { useTranslation } from "react-i18next";

export interface MultipleSelectDropdownOptionProps {
  value: string;
  label: string;
  secondaryLabel?: string;
}

export interface MultipleSelectDropdownProps {
  options: MultipleSelectDropdownOptionProps[];
  selectedOptions: MultipleSelectDropdownOptionProps[];
  onChange: (selected: MultipleSelectDropdownOptionProps[]) => void;
}

const MultipleSelect: React.FC<MultipleSelectDropdownProps> = ({
  options,
  selectedOptions,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: MultipleSelectDropdownOptionProps) => {
    const isSelected = selectedOptions.some(
      (selected) => selected.value === option.value
    );
    if (isSelected) {
      onChange(
        selectedOptions.filter((selected) => selected.value !== option.value)
      );
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const isOptionSelected = (option: MultipleSelectDropdownOptionProps) => {
    return selectedOptions.some((selected) => selected.value === option.value);
  };

  return (
    <div className="relative inline-block">
      <button
        type="button"
        className="w-full flex md:gap-12 gap-4 px-6 py-3 border border-greyscale-100 rounded-md text-greyscale-100 text-sm"
        onClick={toggleDropdown}
      >
        <p className="md:block hidden">{t("button_choose-game-provider")}</p>
        <p className="md:hidden">{t("button_game-provider")}</p>
        {isOpen ? (
          <ChevronUpIcon className="text-greyscale-100" />
        ) : (
          <ChevronDownIcon className="text-greyscale-100" />
        )}
      </button>
      {isOpen && (
        <ul className="absolute w-full mt-1 bg-greyscale-100 border rounded-xl overflow-auto z-10 p-2.5">
          {options.map((option, index) => (
            <li
              key={option.value}
              className="flex flex-col gap-3.5 p-2.5 cursor-pointer flex items-start text-greyscale-300 text-sm"
              onClick={() => handleOptionClick(option)}
            >
              <div className="flex gap-3.5">
                {isOptionSelected(option) ? (
                  <CheckedIcon className="text-greyscale-300" />
                ) : (
                  <UncheckedIcon className="text-greyscale-300" />
                )}
                <div
                  className={`flex flex-col ${
                    option.secondaryLabel ? "gap-[7px]" : ""
                  }`}
                >
                  <p>{option.label}</p>
                  <p className="text-xs text-secondary-400">
                    {option.secondaryLabel}
                  </p>
                </div>
              </div>
              {index !== options.length - 1 && (
                <div className="bg-primary-100 w-full h-[1px]" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultipleSelect;

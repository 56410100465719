import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useEffect, useRef } from "react";
import { ReactComponent as RightChevronIcon } from "../../../assets/icons/arrows/Chevron Right.svg";
import { useTranslation } from "react-i18next";

export interface SliderProps {
  className?: string;
  title?: JSX.Element | string;
  sliderTitle?: string;
  items?: Array<JSX.Element>;
  visibleItems?: number;
}

export const Slider = ({
  className = "",
  title,
  sliderTitle,
  items,
  visibleItems = 5,
}: SliderProps) => {
  const firstSliderRef = useRef<Splide>(null);
  const secondSliderRef = useRef<Splide>(null);
  const { t } = useTranslation();

  const Header = () => (
    <div className="flex flex-row justify-between items-center">
      <span className="text-[28px] font-bold">{title}</span>
      <div className="splide__arrows flex flex-row gap-2">
        <button className="rounded-[4px] bg-[#2A253A] px-2 h-6 text-xs text-greyscale-100">
          {t("button_slider")}
        </button>
        <button className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] splide__arrow--prev !left-auto group">
          <RightChevronIcon className="text-greyscale-100" />
        </button>
        <button className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] relative splide__arrow--next !right-auto">
          <RightChevronIcon className="text-greyscale-100" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    if (
      firstSliderRef.current &&
      secondSliderRef.current &&
      secondSliderRef.current.splide &&
      firstSliderRef.current.splide
    ) {
      firstSliderRef.current.sync(secondSliderRef.current.splide);
      secondSliderRef.current.sync(firstSliderRef.current.splide);
    }
  }, [firstSliderRef, secondSliderRef]);
  return (
    <div className="w-full">
      {items && (
        <>
          <Splide
            ref={firstSliderRef}
            hasTrack={false}
            options={{
              perPage: visibleItems,
              pagination: false,
              isNavigation: true,
              gap: "21px",
            }}
          >
            <Header />
            <div className="mt-[14px]" />
            <SplideTrack>
              {items
                .slice(0, items.length - visibleItems)
                .map((item, index) => (
                  <SplideSlide className="!border-none" key={index}>
                    {item}
                  </SplideSlide>
                ))}
            </SplideTrack>
          </Splide>
          <div className="mt-[14px]" />
          <Splide
            hasTrack={false}
            ref={secondSliderRef}
            options={{
              perPage: visibleItems,
              pagination: false,
              arrows: false,
              isNavigation: true,
              gap: "21px",
            }}
          >
            <SplideTrack>
              {items.slice(visibleItems).map((item, index) => (
                <SplideSlide className="!border-none" key={index}>
                  {item}
                </SplideSlide>
              ))}
            </SplideTrack>
          </Splide>
        </>
      )}
    </div>
  );
};

export default Slider;

export const BrotherBets2Logo = () => {
  return (
    <>
      <svg
        width="124"
        height="104"
        viewBox="0 0 124 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_59_4274)">
          <path
            d="M81.6978 66.615C72.2221 66.615 65.0716 62.4258 61.9999 55.3855C58.9282 62.4258 51.7777 66.615 42.302 66.615H1.41809C0.631374 66.615 0 65.9786 0 65.1969V1.41809C0 0.636385 0.636385 0 1.41809 0H42.302C51.7777 0 58.9282 4.18912 61.9999 11.2294C65.0716 4.19413 72.2221 0 81.6978 0H122.582C123.368 0 124 0.636385 124 1.41809V65.1919C124 65.9786 123.363 66.6099 122.582 66.6099H81.6978V66.615ZM85.4409 39.3506C81.3971 39.3506 78.8866 41.6356 78.8866 45.3136C78.8866 48.4755 81.337 50.6853 84.8496 50.6853H104.928V39.3506H85.4459H85.4409ZM39.1552 50.6853C42.6628 50.6853 45.1182 48.4755 45.1182 45.3136C45.1182 41.6356 42.6077 39.3506 38.5639 39.3506H19.0815V50.6853H39.1602H39.1552ZM56.0119 33.3075C58.357 34.5702 60.5618 36.6447 62.0049 39.541C63.4481 36.6447 65.6478 34.5702 67.9929 33.3075C65.6478 32.0447 63.443 29.9652 62.0049 27.0689C60.5618 29.9652 58.362 32.0447 56.0119 33.3075ZM84.8496 15.9297C81.342 15.9297 78.8866 18.1395 78.8866 21.3013C78.8866 24.9794 81.3971 27.2643 85.4409 27.2643H104.923V15.9297H84.8446H84.8496ZM38.5639 27.2643C42.6077 27.2643 45.1182 24.9794 45.1182 21.3013C45.1182 18.1395 42.6678 15.9297 39.1552 15.9297H19.0765V27.2643H38.5589H38.5639Z"
            fill="url(#paint0_linear_59_4274)"
          />
          <path
            d="M81.6975 1.41821C70.7738 1.41821 63.3576 7.30101 61.9997 16.3958C60.6417 7.30101 53.2256 1.41821 42.3018 1.41821H1.41785V33.3026V65.192H42.3018C53.2256 65.192 60.6417 59.3092 61.9997 50.2144C63.3576 59.3092 70.7738 65.192 81.6975 65.192H122.581V33.3076V1.41821H81.6975ZM39.155 52.1035H17.6582V37.9277H38.5637C43.3892 37.9277 46.536 40.8791 46.536 45.3087C46.536 49.4427 43.2839 52.0985 39.155 52.0985V52.1035ZM38.5587 28.6875H17.6532V14.5117H39.1499C43.2789 14.5117 46.531 17.1675 46.531 21.3015C46.531 25.7311 43.3791 28.6825 38.5587 28.6825V28.6875ZM62.0147 43.9558L61.9896 43.9808C60.6968 37.1059 55.3352 33.7385 51.4667 33.3076C55.3352 32.8767 60.6918 29.5143 61.9896 22.6444L62.0197 22.6745C63.3275 29.5194 68.6742 32.8716 72.5326 33.3026C68.6692 33.7335 63.3175 37.0908 62.0147 43.9508V43.9558ZM106.346 52.1035H84.8494C80.7204 52.1035 77.4683 49.4477 77.4683 45.3137C77.4683 40.8841 80.6202 37.9327 85.4407 37.9327H106.346V52.1085V52.1035ZM106.346 28.6875H85.4407C80.6152 28.6875 77.4683 25.7361 77.4683 21.3065C77.4683 17.1725 80.7204 14.5167 84.8494 14.5167H106.346V28.6926V28.6875Z"
            fill="url(#paint1_linear_59_4274)"
          />
        </g>
        <path
          d="M6.57532 82.6227C7.82748 82.774 8.82742 83.7413 8.82742 85.2753C8.82742 87.0438 7.6079 88.3138 5.3558 88.3138H0V77.1304H5.14216C7.24591 77.1304 8.46246 78.3143 8.46246 80.0679C8.46246 81.4862 7.54559 82.4387 6.57532 82.5722V82.6227ZM1.31744 82.139H5.12139C6.37355 82.139 7.20733 81.3379 7.20733 80.1361C7.20733 79.0175 6.35575 78.2668 5.0205 78.2668H1.31744V82.139ZM5.24008 87.1773C6.6406 87.1773 7.49218 86.3584 7.49218 85.1567C7.49218 83.9549 6.55752 83.2042 5.25491 83.2042H1.31744V87.1773H5.24008Z"
          fill="white"
        />
        <path
          d="M18.8003 88.3138L14.5275 83.5247H12.4742V88.3138H11.1568V77.1304H16.3316C18.5184 77.1304 19.9872 78.4003 19.9872 80.3171C19.9872 82.234 18.5184 83.5217 16.3316 83.5217H16.1298L20.453 88.3108H18.8003V88.3138ZM16.3286 82.32C17.7292 82.32 18.6668 81.5189 18.6668 80.3171C18.6668 79.1154 17.7321 78.3143 16.3286 78.3143H12.4742V82.32H16.3286Z"
          fill="white"
        />
        <path
          d="M33.1912 82.7206C33.1912 85.9757 30.6543 88.5274 27.417 88.5274C24.1798 88.5274 21.6251 85.9727 21.6251 82.7206C21.6251 79.4686 24.162 76.9138 27.417 76.9138C30.6721 76.9138 33.1912 79.4834 33.1912 82.7206ZM31.856 82.7206C31.856 80.151 29.9036 78.1482 27.417 78.1482C24.9305 78.1482 22.9603 80.151 22.9603 82.7206C22.9603 85.2902 24.9127 87.2753 27.417 87.2753C29.9214 87.2753 31.856 85.3051 31.856 82.7206Z"
          fill="white"
        />
        <path
          d="M39.523 78.3143V88.3108H38.1878V78.3143H34.0308V77.1304H43.6623V78.3143H39.523Z"
          fill="white"
        />
        <path
          d="M54.899 88.3138H53.5816V83.2725H46.8727V88.3138H45.5553V77.1304H46.8727V82.0886H53.5816V77.1304H54.899V88.3138Z"
          fill="white"
        />
        <path
          d="M66.0055 88.3138H57.8457V77.1304H65.9907V78.3143H59.1661V82.1034H65.507V83.2873H59.1661V87.1091H66.0085V88.3108L66.0055 88.3138Z"
          fill="white"
        />
        <path
          d="M76.1237 88.3138L71.8509 83.5247H69.7976V88.3138H68.4802V77.1304H73.655C75.8418 77.1304 77.3106 78.4003 77.3106 80.3171C77.3106 82.234 75.8418 83.5217 73.655 83.5217H73.4532L77.7764 88.3108H76.1237V88.3138ZM73.655 82.32C75.0555 82.32 75.9931 81.5189 75.9931 80.3171C75.9931 79.1154 75.0584 78.3143 73.655 78.3143H69.8006V82.32H73.655Z"
          fill="white"
        />
        <path
          d="M90.4521 82.6227C91.7042 82.774 92.7042 83.7413 92.7042 85.2753C92.7042 87.0438 91.4847 88.3138 89.2326 88.3138H83.8768V77.1304H89.016C91.1197 77.1304 92.3363 78.3143 92.3363 80.0679C92.3363 81.4862 91.4194 82.4387 90.4491 82.5722V82.6227H90.4521ZM85.1972 82.139H89.0011C90.2533 82.139 91.0871 81.3379 91.0871 80.1361C91.0871 79.0175 90.2355 78.2668 88.9002 78.2668H85.1942V82.139H85.1972ZM89.1168 87.1773C90.5174 87.1773 91.369 86.3584 91.369 85.1567C91.369 83.9549 90.4343 83.2042 89.1317 83.2042H85.1942V87.1773H89.1168Z"
          fill="white"
        />
        <path
          d="M103.193 88.3138H95.0334V77.1304H103.178V78.3143H96.3538V82.1034H102.695V83.2873H96.3538V87.1091H103.196V88.3108L103.193 88.3138Z"
          fill="white"
        />
        <path
          d="M110.107 78.3143V88.3108H108.772V78.3143H104.615V77.1304H114.246V78.3143H110.107Z"
          fill="white"
        />
        <path
          d="M123.715 79.9849L122.397 80.2846C122.148 78.9346 121.029 78.0652 119.492 78.0652C117.955 78.0652 116.955 78.8811 116.955 80.0858C116.955 81.0858 117.59 81.5368 118.641 81.7534L121.077 82.3023C122.629 82.6376 123.899 83.421 123.899 85.2072C123.899 87.2427 122.246 88.5275 119.741 88.5275C117.371 88.5275 115.469 87.308 115.202 85.1894L116.587 84.9046C116.837 86.4742 118.124 87.3584 119.857 87.3584C121.59 87.3584 122.578 86.5069 122.578 85.2725C122.578 84.2221 122.011 83.8037 120.759 83.5189L118.24 82.967C116.804 82.6495 115.552 81.8988 115.552 80.1125C115.552 78.1928 117.136 76.908 119.525 76.908C121.762 76.908 123.329 78.1423 123.715 79.979V79.9849Z"
          fill="white"
        />
        <path
          d="M96.1679 99.2531L96.6898 99.3801C96.2133 100.71 94.956 101.572 93.3493 101.572C91.2978 101.572 89.6819 99.9566 89.6819 97.8914C89.6819 95.8263 91.2978 94.2104 93.3493 94.2104C94.956 94.2104 96.2087 95.0728 96.6898 96.3709L96.1815 96.5298C95.7639 95.4132 94.7109 94.7097 93.3538 94.7097C91.5973 94.7097 90.2175 96.1031 90.2175 97.8914C90.2175 99.6797 91.6019 101.073 93.3538 101.073C94.7064 101.073 95.7458 100.37 96.1724 99.2531H96.1679Z"
          fill="white"
        />
        <path
          d="M102.386 98.0957V101.454H101.887V100.433C101.483 101.105 100.716 101.572 99.7351 101.572C98.6095 101.572 97.8833 100.932 97.8833 100.052C97.8833 99.1169 98.5959 98.4361 99.9984 98.4361L101.882 98.4452V98.1139C101.882 97.1471 101.256 96.5934 100.316 96.5934C99.4446 96.5934 98.7956 97.0609 98.6685 97.7825L98.1783 97.6555C98.3281 96.7522 99.1769 96.1213 100.293 96.1213C101.551 96.1213 102.377 96.8339 102.377 98.0912L102.386 98.0957ZM101.887 99.2531V98.8718H99.9938C98.9318 98.8718 98.3871 99.3711 98.3871 100.043C98.3871 100.715 98.9408 101.137 99.7941 101.137C100.952 101.137 101.891 100.32 101.891 99.2531H101.887Z"
          fill="white"
        />
        <path
          d="M104.252 99.9021C104.324 100.678 105.05 101.137 106.017 101.137C106.889 101.137 107.483 100.742 107.483 100.075C107.483 99.5209 107.057 99.2985 106.494 99.1714L105.218 98.8945C104.388 98.7129 103.934 98.3317 103.934 97.6282C103.934 96.734 104.71 96.1304 105.836 96.1304C106.961 96.1304 107.688 96.725 107.878 97.5329L107.402 97.6599C107.261 97.0018 106.689 96.5661 105.827 96.5661C104.964 96.5661 104.456 96.97 104.456 97.5964C104.456 98.1411 104.851 98.3408 105.414 98.4678L106.671 98.7447C107.352 98.9036 108.001 99.2122 108.001 100.043C108.001 101.01 107.184 101.572 105.99 101.572C104.796 101.572 103.884 101.01 103.748 100.029L104.256 99.9021H104.252Z"
          fill="white"
        />
        <path
          d="M110.347 94.6688C110.347 94.8912 110.174 95.0637 109.943 95.0637C109.711 95.0637 109.53 94.8912 109.53 94.6688C109.53 94.4464 109.698 94.2649 109.943 94.2649C110.188 94.2649 110.347 94.4464 110.347 94.6688ZM109.689 101.459V96.2574H110.197V101.459H109.689Z"
          fill="white"
        />
        <path
          d="M117.105 98.3634V101.459H116.583V98.436C116.583 97.3421 115.925 96.6159 114.945 96.6159C113.701 96.6159 112.871 97.4556 112.871 98.849V101.454H112.349V96.2528H112.871V97.3603C113.275 96.5842 114.019 96.1257 115.008 96.1257C116.261 96.1257 117.105 97.029 117.105 98.3588V98.3634Z"
          fill="white"
        />
        <path
          d="M124 98.8488C124 100.424 122.806 101.572 121.268 101.572C119.729 101.572 118.535 100.424 118.535 98.8488C118.535 97.2739 119.724 96.1392 121.268 96.1392C122.811 96.1392 124 97.2784 124 98.8488ZM123.487 98.8488C123.487 97.528 122.507 96.593 121.263 96.593C120.019 96.593 119.039 97.528 119.039 98.8488C119.039 100.17 120.019 101.105 121.263 101.105C122.507 101.105 123.487 100.156 123.487 98.8488Z"
          fill="white"
        />
        <path
          d="M75.6891 100.501C75.1127 101.214 74.2412 101.577 73.3063 101.577C71.7858 101.577 70.7645 100.769 70.7645 99.5253C70.7645 98.5903 71.4045 97.8868 72.1988 97.5237C71.7404 97.0018 71.5588 96.557 71.5588 96.0259C71.5588 94.9502 72.3758 94.2195 73.515 94.2195C74.5907 94.2195 75.3669 94.8595 75.4939 96.0078L75.0174 96.1122C74.913 95.0819 74.3048 94.6643 73.506 94.6643C72.6663 94.6643 72.0717 95.2089 72.0717 96.0259C72.0717 96.8429 72.6572 97.4602 73.9326 98.4723L75.5802 99.8249C75.8026 99.4119 75.9297 98.9081 75.9297 98.3135V97.8868H76.3972V98.3135C76.3972 99.0351 76.2383 99.6343 75.9524 100.12L77.3458 101.259L77.0508 101.622L75.6891 100.506V100.501ZM75.3306 100.202L73.5559 98.7447C73.1292 98.4133 72.7888 98.1183 72.512 97.8505C71.7449 98.1909 71.2774 98.7764 71.2774 99.5208C71.2774 100.488 72.0853 101.128 73.3108 101.128C74.0869 101.128 74.8313 100.81 75.3306 100.202Z"
          fill="white"
        />
        <path
          d="M5.28316 96.1166L4.75212 96.2437C4.57057 95.2951 3.79443 94.6778 2.66881 94.6778C1.60673 94.6778 0.826061 95.2542 0.826061 96.1665C0.826061 96.9427 1.3571 97.2422 2.09238 97.4102L3.65827 97.7823C4.55241 97.973 5.40117 98.3769 5.40117 99.5389C5.40117 100.782 4.38901 101.568 2.85944 101.568C1.32987 101.568 0.158858 100.769 0 99.4526L0.576427 99.3255C0.703513 100.433 1.65212 101.091 2.90483 101.091C4.15754 101.091 4.87013 100.483 4.87013 99.557C4.87013 98.7582 4.41171 98.4722 3.54026 98.2725L1.89268 97.8913C0.944069 97.6689 0.254173 97.2014 0.254173 96.1801C0.254173 94.9774 1.25271 94.2148 2.68697 94.2148C4.12123 94.2148 5.04714 95.0001 5.28316 96.1166Z"
          fill="white"
        />
        <path
          d="M12.4088 98.8491C12.4088 100.51 11.206 101.572 9.78079 101.572C8.81402 101.572 7.98343 101.105 7.53862 100.32V104.01H7.01666V96.2575H7.53862V97.3967C7.98343 96.5979 8.81402 96.1304 9.78079 96.1304C11.206 96.1304 12.4088 97.2061 12.4088 98.8536V98.8491ZM11.9004 98.8491C11.9004 97.4648 10.9109 96.5933 9.72178 96.5933C8.60524 96.5933 7.54316 97.4648 7.54316 98.8491C7.54316 100.233 8.60524 101.105 9.72178 101.105C10.9109 101.105 11.9004 100.243 11.9004 98.8491Z"
          fill="white"
        />
        <path
          d="M18.9219 98.8488C18.9219 100.424 17.7328 101.572 16.1896 101.572C14.6464 101.572 13.4572 100.424 13.4572 98.8488C13.4572 97.2739 14.6464 96.1392 16.1896 96.1392C17.7328 96.1392 18.9219 97.2784 18.9219 98.8488ZM18.4136 98.8488C18.4136 97.528 17.4332 96.593 16.1896 96.593C14.9459 96.593 13.9656 97.528 13.9656 98.8488C13.9656 100.17 14.9459 101.105 16.1896 101.105C17.4332 101.105 18.4136 100.156 18.4136 98.8488Z"
          fill="white"
        />
        <path
          d="M23.5743 96.2437L23.5199 96.7339C23.4109 96.7112 23.3066 96.7112 23.2112 96.7112C21.777 96.7112 21.0008 97.9003 21.0008 99.2847V101.454H20.4789V96.2528H21.0008V97.7097C21.4139 96.8473 22.1809 96.2119 23.2112 96.2119C23.3292 96.2119 23.4246 96.2119 23.5743 96.2437Z"
          fill="white"
        />
        <path
          d="M27.6819 101.159C27.3098 101.445 26.9693 101.572 26.5427 101.572C25.6168 101.572 24.9904 100.987 24.9904 99.9883V96.7023H23.8512V96.2575H24.9904V94.333H25.5124V96.2575H27.4595V96.7023H25.5124V99.9566C25.5124 100.66 25.939 101.082 26.5745 101.082C26.9058 101.082 27.2144 100.955 27.4777 100.733L27.6819 101.159Z"
          fill="white"
        />
        <path
          d="M28.9074 99.9021C28.98 100.678 29.7062 101.137 30.673 101.137C31.5445 101.137 32.139 100.742 32.139 100.075C32.139 99.5209 31.7124 99.2985 31.1496 99.1714L29.8742 98.8945C29.0436 98.7129 28.5897 98.3317 28.5897 97.6282C28.5897 96.734 29.3658 96.1304 30.4915 96.1304C31.6171 96.1304 32.3433 96.725 32.5339 97.5329L32.0528 97.6599C31.9166 97.0018 31.3402 96.5661 30.4778 96.5661C29.6155 96.5661 29.1071 96.97 29.1071 97.5964C29.1071 98.1411 29.502 98.3408 30.0648 98.4678L31.3221 98.7447C32.0029 98.9036 32.6519 99.2122 32.6519 100.043C32.6519 101.01 31.8349 101.572 30.6412 101.572C29.4475 101.572 28.5352 101.01 28.3945 100.029L28.9074 99.9021Z"
          fill="white"
        />
        <path
          d="M39.6325 98.8491C39.6325 100.51 38.4297 101.572 37.0046 101.572C36.0378 101.572 35.2072 101.105 34.7579 100.32V101.459H34.2359V93.2664H34.7579V97.3921C35.2027 96.5933 36.0333 96.1258 37.0046 96.1258C38.4297 96.1258 39.6325 97.2015 39.6325 98.8491ZM39.1196 98.8491C39.1196 97.4647 38.1302 96.5933 36.941 96.5933C35.8245 96.5933 34.7624 97.4647 34.7624 98.8491C34.7624 100.233 35.8245 101.105 36.941 101.105C38.1347 101.105 39.1196 100.242 39.1196 98.8491Z"
          fill="white"
        />
        <path
          d="M46.1454 98.8488C46.1454 100.424 44.9517 101.572 43.413 101.572C41.8744 101.572 40.6807 100.424 40.6807 98.8488C40.6807 97.2739 41.8698 96.1392 43.413 96.1392C44.9562 96.1392 46.1454 97.2784 46.1454 98.8488ZM45.6325 98.8488C45.6325 97.528 44.6566 96.593 43.4085 96.593C42.1603 96.593 41.1845 97.528 41.1845 98.8488C41.1845 100.17 42.1649 101.105 43.4085 101.105C44.6521 101.105 45.6325 100.156 45.6325 98.8488Z"
          fill="white"
        />
        <path
          d="M52.6588 98.8488C52.6588 100.424 51.4696 101.572 49.9264 101.572C48.3833 101.572 47.1941 100.424 47.1941 98.8488C47.1941 97.2739 48.3833 96.1392 49.9264 96.1392C51.4696 96.1392 52.6588 97.2784 52.6588 98.8488ZM52.1459 98.8488C52.1459 97.528 51.1655 96.593 49.9219 96.593C48.6783 96.593 47.6979 97.528 47.6979 98.8488C47.6979 100.17 48.6783 101.105 49.9219 101.105C51.1655 101.105 52.1459 100.156 52.1459 98.8488Z"
          fill="white"
        />
        <path
          d="M58.6368 101.459L56.1677 98.7674L54.7198 100.256V101.459H54.1979V93.2664H54.7198V99.5435L57.9968 96.2574H58.6368L56.5217 98.4179L59.2995 101.459H58.6414H58.6368Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_59_4274"
            x1="62.1101"
            y1="69.2407"
            x2="61.8897"
            y2="-2.52048"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_59_4274"
            x1="61.9997"
            y1="-2.92123"
            x2="61.9997"
            y2="71.5458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <clipPath id="clip0_59_4274">
            <rect width="124" height="66.615" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default BrotherBets2Logo;

import { FormikProps } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { CountrySelector } from "src/components/atoms";
import { InputText } from "src/components/molecules";
import { WalletCurrency } from "src/types";
import { isEuCountry } from "src/utils/currencyFunctions";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import { useTranslation } from "react-i18next";

export interface FormRegistrationStep3Props {
  values: FormikProps<any>["values"];
  handleBlur: FormikProps<any>["handleBlur"];
  handleChange: FormikProps<any>["handleChange"];
  validateField: FormikProps<any>["validateField"];
  setFieldValue: (field: string, value: string) => void;
  onClickNext: () => void;
  onClickBack: () => void;
  setChosenCurrency: React.Dispatch<React.SetStateAction<WalletCurrency>>;
}

export const FormRegistrationStep3 = ({
  values,
  handleBlur,
  handleChange,
  validateField,
  setFieldValue,
  setChosenCurrency,
  onClickBack,
}: FormRegistrationStep3Props) => {
  const { t } = useTranslation();
  const handleOnBlur = (e: React.FocusEvent<any>) => {
    handleBlur(e);
    validateField(e.target.name);
  };
  const handleOnChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    validateField(e.target.name);
  };

  return (
    <div>
      <div className="flex flex-col gap-[34px] mb-[54px]">
        <div className="flex flex-col gap-6">
          <span className="text-lg font-semibold text-primary-400">
            {t("text_contact-details")}
          </span>
          <InputText
            name="address1"
            label={t("text_address-1")}
            type="text"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <InputText
            name="address2"
            label={t("text_address-2")}
            type="text"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <div className="flex gap-5">
            <div className="flex-1">
              <InputText
                name="zipCode"
                label={t("text_zip-code")}
                type="text"
                onBlur={handleOnBlur}
                onChange={handleOnChange}
              />
            </div>
            <div className="flex-1 w-1/2">
              <CountrySelector
                onSelect={(country) => {
                  setFieldValue("country", country.code);
                  setChosenCurrency(isEuCountry(country.name) ? "EUR" : "USD");
                }}
                defaultCountry="PT"
              />
            </div>
          </div>
          <PhoneInput
            country={"pt"}
            value={values.phoneNumber}
            placeholder={t("placeholder_contact-number")}
            onChange={(phone) => setFieldValue("phoneNumber", phone)}
            containerClass="!w-full !text-sm"
            inputClass="!w-full react-tel-input !text-sm !border-primary-400 !rounded focus:!shadow-none focus-visible:!outline-1 !outline-black"
            specialLabel={t("placeholder_contact-number")}
          />
        </div>
        <button
          type="button"
          onClick={onClickBack}
          className="flex items-center rounded w-full py-3 px-6 bg-primary-400 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-primary-100 transition duration-300"
        >
          <ArrowLeftIcon />
          <span className="mx-auto">{t("button_back")}</span>
        </button>
      </div>
    </div>
  );
};

export default FormRegistrationStep3;

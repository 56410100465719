import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const axiosPrivateInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const axiosLocationInstance = axios.create({
  baseURL: "https://ipapi.co",
    headers: {
        "Content-Type": "application/json",
    },
});

const axiosBackOfficeInstance = axios.create({
  baseURL: process.env.REACT_APP_TEMP_BACKOFFICE_URL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + process.env.REACT_APP_TEMP_BACKOFFICE_TOKEN,
    },
});

const axiosRefreshInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const useAxiosInterceptors = () => {
    const { token, refreshToken } = useAuth();

    useEffect(() => {
        const requestInterceptor =
            axiosPrivateInstance.interceptors.request.use((config) => {
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            });

        return () => {
            axiosPrivateInstance.interceptors.request.eject(requestInterceptor);
        };
    }, [token]);
    
    useEffect(() => {
        const requestInterceptor =
            axiosRefreshInstance.interceptors.request.use((config) => {
                if (refreshToken) {
                    config.headers.Authorization = `Bearer ${refreshToken}`;
                }
                return config;
            });

        return () => {
            axiosRefreshInstance.interceptors.request.eject(requestInterceptor);
        };
    }, [refreshToken]);

  return { axiosInstance, axiosPrivateInstance, axiosLocationInstance, axiosBackOfficeInstance, axiosRefreshInstance };
};

export { useAxiosInterceptors };

import { MainContainer } from "src/components/organisms";
import { ReactComponent as CelebrateIcon } from "src/assets/icons/emoji/Celebration.svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import usePlayerService from "src/services/playerService";
import { useEffect, useState } from "react";
import NotFoundPage from "src/pages/NotFoundPage";
import useNotificationsService from "src/services/notificationsService";
import { PacmanLoader } from "react-spinners";
import { ReactComponent as HeaderSkel } from "src/assets/icons/loaders/Navigation_Desktop.svg";

export default function VerificationPage() {
  const { verifyPlayer } = usePlayerService();
  const { addErrorNotification } = useNotificationsService();
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const { email, verificationToken } = useParams<{
    email: string;
    verificationToken: string;
  }>();

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Verify Account Page",
  });

  useEffect(() => {
    // react re-renders the component twice or thrice in debug mode
    // so this check is just to avoid confusion
    if (!isLoading) {
      return;
    }
    (async () => {
      try {
        await verifyPlayer({
          email: email!,
          verificationToken: verificationToken!,
        });
        setSuccess(true);
      } catch (error: any) {
        addErrorNotification(t("error_verification"));
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    })();
  });

  return isLoading ? (
    <div className={"bg-purple-950 w-full h-screen flex flex-col items-center"}>
      <HeaderSkel className="w-full flex-initial" />
      <PacmanLoader className="p-8 w-10 flex-auto opacity-50" color="white" />
    </div>
  ) : !success ? (
    <NotFoundPage />
  ) : (
    <MainContainer>
      <div className="flex flex-col md:gap-20 gap-10 justify-center items-center xl:px-60 md:px-24 pt-40 pb-80 px-6 bg-custom-gradient">
        <div className="flex flex-col px-48 gap-[54px] justify-center items-center">
          <CelebrateIcon className={`mx-auto w-[85px] text-secondary-400`} />
          <div className="flex flex-col gap-16 justify-center">
            <h1 className="text-5xl font-bold text-white text-center">
              {t("text_congratulations")}!
            </h1>
            <p className="text-2xl text-white text-center">
              {t("text_account-activated")}
            </p>
            <p className="text-2xl text-white text-center">
              {t("text_close-window")}!
            </p>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

import { useEffect } from "react";
import { getDurationToNow } from "../../../utils/timeFunctions";

export interface NewsCardProps {
  className?: string;
  categories?: Array<string>;
  releaseTime?: Date;
  onClick?: () => void;
  image?: string;
  title?: string;
}

const NewsCard = ({
  className = "",
  categories = [],
  releaseTime,
  onClick,
  image,
  title,
}: NewsCardProps) => {
  /* const onClickHandler = () => {
        if (onClick) {
            onClick();
        } else {
            console.warn(`No click handler defined for news button.`);
        }
    }; */

  useEffect(() => {
    releaseTime && console.log(getDurationToNow(releaseTime));
  }, [releaseTime]);

  return (
    <div className="rounded-10 w-[277px] overflow-clip">
      <div className="h-[160px]">
        <img src={image} alt="" />
      </div>
      <div className="h-[144px] bg-greyscale-100 p-2 gap-[18px] flex flex-col">
        <div className="flex gap-1">
          {categories.map((item, index) => (
            <div
              key={index}
              className="bg-warning-200 rounded text-greyscale-100 font-bold p-1 text-[10px] leading-none"
            >
              {item}
            </div>
          ))}
        </div>
        <span className="text-[10px] leading-3">
          {releaseTime ? getDurationToNow(releaseTime) : ""}
        </span>
        <span className="text-sm leading-4">{title}</span>
      </div>
    </div>
  );
};

export default NewsCard;

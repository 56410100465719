import { Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as Edit2Icon } from "src/assets/icons/edit/Edit 2.svg";
import { ReactComponent as CloudIcon } from "src/assets/icons/cloud/-2.svg";
import { InputDate, InputSelect, InputText } from "src/components/molecules";
import * as Yup from "yup";
import { useAuth } from "src/context/AuthContext";
import usePlayerService from "src/services/playerService";
import { UpdatePlayerRequest } from "src/types";
import useNotificationsService from "src/services/notificationsService";
import { CountrySelector } from "src/components/atoms";
import { useTranslation } from "react-i18next";

export const FormEditProfile = () => {
  const { t } = useTranslation();
  const { playerData, setPlayerData } = useAuth();
  const { isNicknameTaken, updatePlayer } = usePlayerService();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();

  const editSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, t("error_max_20-chars"))
      .test("username", t("error_user-already-exists"), async (value) => {
        if (!value || playerData?.nickname === value) return true;
        try {
          const exists = await isNicknameTaken(value);
          if (exists) {
            return false;
          } else {
            return true;
          }
        } catch (error) {
          addErrorNotification(t("error_user-check"));
          console.error("Error checking username: ", error);
        }
        return true;
      }),
    firstName: Yup.string()
      .max(15, t("error_max_15-chars"))
      .required(t("error_required")),
    lastName: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    gender: Yup.string().required(t("error_required")),
    birthDate: Yup.date().required(t("error_required")),
    phoneNumber: Yup.string().max(20, t("error_max_20-chars")),
    country: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    address1: Yup.string().max(20, t("error_max_20-chars")),
    address2: Yup.string().max(20, t("error_max_20-chars")),
    zipCode: Yup.string().max(20, t("error_max_20-chars")),
  });

  return (
    <>
      {playerData && (
        <Formik
          validationSchema={editSchema}
          initialValues={{
            username: playerData.nickname,
            firstName: playerData.first_name,
            lastName: playerData.last_name,
            gender: playerData.gender,
            birthDate: playerData.date_of_birth,
            phoneNumber: playerData.contact_number,
            country: playerData.country,
            address1: playerData.address_1,
            address2: playerData.address_2,
            zipCode: playerData.post_code,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const formatedValues: UpdatePlayerRequest = {
                nickname: playerData.nickname,
                first_name: values.firstName,
                last_name: values.lastName,
                gender: values.gender,
                date_of_birth: values.birthDate,
                contact_number: values.phoneNumber,
                country: values.country,
                address_1: values.address1,
                address_2: values.address2,
                post_code: values.zipCode,
              };
              const newPlayerData = await updatePlayer(
                playerData.id,
                formatedValues
              );
              setPlayerData(newPlayerData);
              addSuccessNotification(t("success_player-updated"));
              setSubmitting(false);
            } catch (error: any) {
              addErrorNotification(t("error_player-update"));
              console.error("Error submitting update player: ", error);
            }
          }}
        >
          {({ handleBlur, handleChange, setFieldValue, values }) => (
            <Form className="w-full flex flex-col items-center">
              <div className="w-full justify-between flex gap-6">
                <div className="rounded-10 border border-primary-400 p-5 w-[232px] flex flex-col items-center gap-3.5">
                  <div className="text-lg font-medium text-primary-400">
                    {t("text_player-profile")}
                  </div>
                  <span className="rounded-full min-w-[74px] min-h-[74px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100 font-semibold">
                    {playerData.first_name.charAt(0).toUpperCase()}
                    {playerData.last_name.charAt(0).toUpperCase()}
                  </span>
                  <div className="flex gap-1 items-center">
                    <span className="text-[10px]">{t("text_edit")}</span>
                    <Edit2Icon />
                  </div>
                  <div className="flex flex-col text-primary-400 items-center border-b border-b-primary-400 pb-4">
                    <span className="font-bold text-xl leading-[26px]">
                      {values.firstName} {values.lastName}
                    </span>
                    <span className="text-primary-400 text-sm leading-6">
                      <span className="font-light">{t("text_player-id")}:</span>{" "}
                      <span className="font-semibold">{values.username}</span>
                    </span>
                  </div>
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_ISSUER}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI_REGISTER}&scope=openid email profile&state=sss`}
                    className="underline text-sm text-secondary-300"
                  >
                    {t("link_register-miracl")}
                  </a>
                  <div className="flex flex-col gap-3.5">
                    <div className="text-lg font-medium text-primary-400">
                      {t("text_player-id")}
                    </div>
                    <InputText
                      name="username"
                      label={t("text_player-id")}
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <span className="text-lg font-semibold text-primary-400">
                    {t("text_personal-details")}
                  </span>
                  <InputText
                    name="firstName"
                    label={t("text_first-name")}
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <InputText
                    name="lastName"
                    label={t("text_last-name")}
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputSelect name="gender" label={t("text_gender")}>
                    <option value="male">{t("option_male")}</option>
                    <option value="female">{t("option_female")}</option>
                    <option value="other">{t("option_other")}</option>
                  </InputSelect>
                  <div className="flex flex-col gap-6">
                    <span className="text-lg font-semibold text-primary-400">
                      {t("text_date-of-birth")}
                    </span>
                    <InputDate
                      initialDate={values.birthDate}
                      setValue={(date: string) =>
                        setFieldValue("birthDate", date)
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <span className="text-lg font-semibold text-primary-400">
                    {t("text_contact-details")}
                  </span>
                  <InputText
                    name="address1"
                    label={t("text_address-1")}
                    type="text"
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputText
                    name="address2"
                    label={t("text_address-2")}
                    type="text"
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="flex gap-5">
                    <div className="flex-1">
                      <InputText
                        name="zipCode"
                        label={t("text_zip-code")}
                        type="text"
                        value={values.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="flex-1 w-1/2">
                      <CountrySelector
                        onSelect={(country) =>
                          setFieldValue("country", country.code)
                        }
                        defaultCountry={values.country}
                      />
                    </div>
                  </div>
                  <PhoneInput
                    country={"pt"}
                    value={values.phoneNumber}
                    placeholder={t("placeholder_contact-number")}
                    onChange={(phone) => setFieldValue("phoneNumber", phone)}
                    containerClass="!w-full !text-sm"
                    inputClass="!w-full react-tel-input !text-sm !border-primary-400 !rounded focus:!shadow-none focus-visible:!outline-1 !outline-black"
                    specialLabel={t("placeholder_contact-number")}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center">
                <div className="flex flex-col gap-3.5 justify-center mt-24 w-full">
                  <span className="text-primary-100 text-xs leading-[14px] mx-auto">
                    {t("text_save-changes")}
                  </span>
                  <button className="bg-secondary-200 text-primary-400 text-sm flex py-2.5 pr-6 pl-[70px] gap-[34px] items-center rounded hover:bg-secondary-300 hover:text-greyscale-100 transition duration-300 disabled:opacity-40 disabled:cursor-not-allowed">
                    <span className="mx-auto">{t("button_save")}</span>
                    <CloudIcon />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormEditProfile;

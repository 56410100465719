import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { InputPassword } from "src/components/molecules/";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "src/services/notificationsService";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useBackOfficeService from "src/services/backOfficeService";
import { useTranslation } from "react-i18next";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { playerData } = useAuth();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();
  const { updatePassword } = useBackOfficeService();

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  return isOpen ? (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="bg-greyscale-100 px-6 pt-10 md:pb-28 pb-10 rounded-[20px] shadow-lg relative overflow-hidden max-w-3xl w-full md:h-[700px] h-screen"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between pb-12 items-center">
          <h1 className="text-[40px] leading-[48px] text-primary-400 mx-auto">
            {t("text_change-password")}
          </h1>
          <CloseIcon
            className="text-greyscale-300 cursor-pointer"
            onClick={handleOnClose}
          />
        </div>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            currentPassword: Yup.string().required(t("error_cur-pwd-required")),
            newPassword: Yup.string()
              .required(t("error_new-pwd-required"))
              .min(6, t("error_min_6-chars")),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("newPassword")], t("error_pwd-mismatch"))
              .required(t("error_confirm-pwd-required")),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            if (!playerData) return;
            try {
              await updatePassword({
                id: playerData.id,
                old_password: values.currentPassword,
                password: values.newPassword,
              });
              handleOnClose();
              addSuccessNotification(t("success_pwd-changed"));
            } catch (error: any) {
              addErrorNotification(t("error_pwd-change"));
              console.error(error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="md:px-48 sm:px-36">
              <div className="flex flex-col gap-5">
                <InputPassword
                  name="currentPassword"
                  label={t("text_current-pwd")}
                />
                <InputPassword name="newPassword" label={t("text_new-pwd")} />
                <InputPassword
                  name="confirmPassword"
                  label={t("text_confirm-pwd")}
                />
              </div>
              <div className="mt-12" />
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex rounded justify-center w-full py-3 px-6 mb-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 mt-2 transition duration-300"
              >
                {isSubmitting
                  ? t("button_submitting")
                  : t("text_change-password")}
              </button>
            </Form>
          )}
        </Formik>
      </motion.div>
    </div>
  ) : null;
};

export default ChangePasswordModal;

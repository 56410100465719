export const SlotsIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.12509 5C3.71615 5 1.75 6.7695 1.75 8.9375V13.7187C1.75 15.0682 2.81991 16.25 4.25005 16.25C5.05739 16.25 5.7219 15.9011 6.18241 15.4546C6.64306 15.0081 6.95225 14.4721 7.23097 13.9704C7.50971 13.4686 7.76069 12.997 8.00484 12.6992C8.24913 12.4014 8.39924 12.3125 8.62496 12.3125H12.375C12.6008 12.3125 12.7509 12.4014 12.9952 12.6992C13.2394 12.997 13.4903 13.4685 13.769 13.9704C14.0478 14.4721 14.3571 15.0082 14.8176 15.4546C15.2782 15.9011 15.9428 16.25 16.7499 16.25C18.1801 16.25 19.25 15.0682 19.25 13.7187V8.9375C19.25 6.7695 17.2839 5 14.8749 5H6.12509ZM5.50008 7.25H6.7501V8.375H8.00013V9.5L6.7501 9.49987V10.6249L5.50008 10.625V9.5L4.25005 9.49987V8.37487H5.50008V7.25ZM14.2503 7.25H15.5003V8.375H16.7503V9.5L15.5003 9.49987V10.6249L14.2503 10.625V9.5L13.0002 9.49987V8.37487H14.2503V7.25ZM14.2503 8.37487V9.5L15.5003 9.49987V8.375L14.2503 8.37487Z" />
      </svg>
    </>
  );
};

export default SlotsIcon;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDurationToNow } from "src/utils/timeFunctions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface TableProfileInfoProps {
  name: string;
  image: string;
}

export interface TablePlayerProps extends TableProfileInfoProps {
  lastPlayed: Date;
}

export interface TableGameProps extends TableProfileInfoProps {}

export interface TableItemProps {
  player: TablePlayerProps;
  game: TableGameProps;
  bet: number;
  win: number;
}

export interface TableProps {
  items: Array<TableItemProps>;
  title: string;
}

export const Table = ({ items, title }: TableProps) => {
  const { t } = useTranslation();
  const [data /* _setData */] = useState(() => [...items]);
  const [selectedTable, setSelectedTable] = useState<string>();

  const columnHelper = createColumnHelper<TableItemProps>();

  const tables = [
    t("text_latest-wins"),
    t("text_biggest-daily-prizes"),
    t("text_biggest-takes"),
  ];

  const handleSelectedTableClick = (table: string) => {
    if (selectedTable !== table) {
      setSelectedTable(table);
    }
  };

  const columns = [
    columnHelper.accessor("player", {
      header: t("text_player"),
      cell: ({ getValue }) => (
        <div className="flex gap-[14px] items-center justify-center py-3 px-2">
          <img src={getValue().image} alt="" />
          <span>{getValue().name}</span>
        </div>
      ),
    }),
    columnHelper.accessor("game", {
      header: t("text_game"),
      cell: ({ getValue }) => (
        <div className="flex gap-[14px] items-center justify-center py-3 px-2">
          <img src={getValue().image} alt="" />
          <span>{getValue().name}</span>
        </div>
      ),
    }),
    columnHelper.accessor("bet", {
      header: t("text_bet"),
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center py-3 px-2">
          ${getValue()}
        </div>
      ),
    }),
    columnHelper.accessor("win", {
      header: t("text_win"),
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center py-3 px-2">
          ${getValue()}
        </div>
      ),
    }),
    columnHelper.accessor("win", {
      header: t("text_take"),
      cell: ({ row, getValue }) => (
        <div className="flex items-center justify-center py-3 px-2">
          ${getValue() - row.original.bet}
        </div>
      ),
    }),
    columnHelper.accessor("player", {
      header: t("text_played"),
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center py-3 px-2">
          {getDurationToNow(getValue().lastPlayed)}
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className="flex flex-col gap-6 text-greyscale-100">
      <div className="flex md:flex-row flex-col justify-between">
        <div className="text-[28px] font-bold">{title}</div>
        <div className="flex items-center gap-3.5">
          {tables.map((table) => (
            <button
              key={table}
              className={`flex items-center gap-2.5 px-6 py-3 border rounded-md text-sm ${
                selectedTable === table
                  ? "bg-[#FFD600] text-primary-400 border-[#FFD600]"
                  : "text-greyscale-100"
              }`}
              onClick={() => handleSelectedTableClick(table)}
            >
              {table}
            </button>
          ))}
        </div>
      </div>
      <div className="overflow-clip bg-[#3D0C4210] rounded-[20px] [border:double_2px_transparent] [background-image:linear-gradient(#36073B,#36073B),linear-gradient(to_bottom_right,#3D0C4210,#FFF)] [background-origin:border-box] [background-clip:content-box,border-box]">
        <table className="w-full">
          <thead className="bg-[#5A1162] font-semibold text-lg">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={`${header.id}${index}`}
                    className={`py-5 border-r border-greyscale-100/20 ${
                      (header.column.columnDef.header === "Player" ||
                        header.column.columnDef.header === "Bet" ||
                        header.column.columnDef.header === "Take") &&
                      "md:table-cell hidden"
                    }`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-sm">
            {table.getRowModel().rows.map((row, index) => (
              <>
                {index > 0 && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length} className="px-6">
                      <div className="h-[1px] rounded-10 bg-greyscale-100" />
                    </td>
                  </tr>
                )}
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`${
                        (cell.column.columnDef.header === "Player" ||
                          cell.column.columnDef.header === "Bet" ||
                          cell.column.columnDef.header === "Take") &&
                        "md:table-cell hidden"
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;

import * as React from "react"
import { SVGProps } from "react"
const MastercardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#311E02"
        d="M0 6a6 6 0 0 1 6-6h60a6 6 0 0 1 6 6v36a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
      />
      <path
        fill="#FF5F00"
        d="m43.51 9.79-15.494.054.47 28.364 15.493-.053-.47-28.365Z"
      />
      <path
        fill="#EB001B"
        d="M29.037 24.084c-.096-5.77 2.488-10.896 6.536-14.22-3.056-2.398-6.886-3.84-11.038-3.826-9.838.034-17.663 8.138-17.497 18.12.166 9.984 8.26 18.034 18.097 18a17.315 17.315 0 0 0 10.91-3.9c-4.157-3.247-6.913-8.405-7.009-14.175Z"
      />
      <path
        fill="#F79E1B"
        d="M64.961 23.841c.166 9.983-7.658 18.087-17.496 18.121-4.152.014-7.983-1.427-11.038-3.825 4.097-3.325 6.632-8.451 6.536-14.22-.096-5.77-2.85-10.878-7.008-14.174a17.316 17.316 0 0 1 10.91-3.9c9.837-.035 17.931 8.065 18.096 17.998Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h72v48H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default MastercardIcon

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronUpIcon } from "src/assets/icons/arrows/Chevron Up.svg";
import { ReactComponent as ChevronDownIcon } from "src/assets/icons/arrows/Chevron Down.svg";
import { ReactComponent as UsaFlag } from "src/assets/icons/flags/united-states-of-america.svg";
import { ReactComponent as PortugalFlag } from "src/assets/icons/flags/portugal.svg";

export interface SelectProps {
  className?: string;
}

export const Select = ({ className = "" }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("EN");
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    changeLanguage(option.toLowerCase());
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`flex relative justify-center items-center text-sm ${className}`}
      ref={dropdownRef}
    >
      <button
        type="button"
        onClick={toggleDropdown}
        className="bg-transparent text-greyscale-100 pl-4 py-2 flex gap-2 items-center"
      >
        <span>{selectedOption}</span>
        {isOpen ? (
          <ChevronUpIcon className="text-greyscale-100" />
        ) : (
          <ChevronDownIcon className="text-greyscale-100" />
        )}
      </button>
      {isOpen && (
        <div className="absolute top-12 z-10 mt-2 bg-white border border-greyscale-100 rounded-lg shadow-lg">
          <button
            key="en"
            type="button"
            className="flex items-center px-4 py-2 gap-3.5 text-left w-full hover:bg-gray-100 rounded-lg transition duration-300"
            onClick={() => handleOptionClick("EN")}
          >
            <UsaFlag />
            <span>EN</span>
          </button>
          <button
            key="pt"
            type="button"
            className="flex items-center px-4 py-2 gap-3.5 text-left w-full hover:bg-gray-100 rounded-lg transition duration-300"
            onClick={() => handleOptionClick("PT")}
          >
            <PortugalFlag />
            <span>PT</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Select;

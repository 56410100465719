export const faq = [
  {
    id: 0,
    title: "General",
    content: [
      {
        question: <p>How do I register as a new user?</p>,
        answer: (
          <p>
            Registration is free. Click{" "}
            <span className="font-bold">Sign up</span> on the top right of the
            web page and provide the information requested. You will need to set
            maximum betting limits per day, week and month. Please be sure to
            enter realistic and sensible limits.
          </p>
        ),
      },
      {
        question: <p>I haven't received a confirmation email.</p>,
        answer: (
          <p>
            Please double check that the email address you entered is correct
            and that the confirmation email is not in your junk box. If you
            still cannot receive it, we recommend changing to another email
            address. You can change your email address at any time by contacting
            us via live chat on our website.
          </p>
        ),
      },
      {
        question: <p>Can I register more than one account at a time?</p>,
        answer: (
          <p>
            Customers can only open one member account with Brother Bets casino.
            If you open or attempt to open multiple accounts, Brother Bets
            reserves the right to close some or all of the accounts created and
            void all winnings from those accounts. If we decide to keep one
            account open, it will be the first account you register with Brother
            Bets and any remaining deposits will be transferred to that account.
            Brother Bets will debit an administrative fee of 10% or $35,
            whichever is higher, for every registered account, as well as other
            applicable levies in accordance with Brother Bets Terms of Use.
          </p>
        ),
      },
      {
        question: <p>Do you have the necessary licenses to operate?</p>,
        answer: (
          <p>
            This site has a Tobique Gaming license and is operated under strict
            auditing, so you can play with confidence.
          </p>
        ),
      },
      {
        question: <p>What types of games do you offer?</p>,
        answer: (
          <p>Our site offers casino games, live casino, and slot games.</p>
        ),
      },
      {
        question: <p>What should I do if I have an enquiry?</p>,
        answer: (
          <p>
            Please contact us via live chat, which is available 24 hours a day
            in English. If you click on{" "}
            <span className="font-bold">Live Chat</span> at the bottom right of
            the website, an agent will respond. Alternatively, please contact us
            by email at{" "}
            <a className="font-bold" href="mailto: support@brotherbets.com">
              support@brotherbets.com
            </a>
            .
          </p>
        ),
      },
    ],
  },
  {
    id: 1,
    title: "Account",
    content: [
      {
        question: <p>I forgot my password. How can I reset it?</p>,
        answer: (
          <p>
            Login form{" "}
            <span className="font-bold">≪Forgot your password? Click ≫</span>{" "}
            and enter your registered email address. A new password setting link
            will be sent to your registered email address, so please reset your
            password from there.
          </p>
        ),
      },
      {
        question: <p>I have a problem logging in. What should I do?</p>,
        answer: (
          <p>
            Please try entering your correct email address and password again.
            If the problem still persists, please contact our customer support.
          </p>
        ),
      },
      {
        question: (
          <p>
            Can I update my registration information once I have registered?
          </p>
        ),
        answer: (
          <p>
            To update your registration information, please click on the
            <span className="font-bold">Account Icon</span> in the upper right
            corner of the website, select{" "}
            <span className="font-bold">My Account</span>, and make changes in
            your account information. However, only your address and phone
            number can be updated. If you need to change any other information,
            please contact our customer support.
          </p>
        ),
      },
      {
        question: <p>Can I change my username?</p>,
        answer: (
          <p>
            You cannot change your user ID after creating an account, so please
            consider your user ID when registering.
          </p>
        ),
      },
      {
        question: <p>Can I change my registered e-mail address?</p>,
        answer: (
          <p>
            Yes, you can. You can change your e-mail address by contacting us
            through our live chat system.
          </p>
        ),
      },
      {
        question: <p>Is it possible to change the currency display to yen?</p>,
        answer: (
          <p>
            The currencies available are USD, Euros GBP, and major Crypto
            currencies.
          </p>
        ),
      },
      {
        question: <p>Where can I check my balance?</p>,
        answer: (
          <p>Your available balance is displayed in the upper right corner.</p>
        ),
      },
      {
        question: (
          <p>Where can I check the details of my deposits and withdrawals?</p>
        ),
        answer: (
          <p>
            If you want to check the details of your deposits and withdrawals,
            click on <span className="font-bold">Transaction History</span> in{" "}
            <span className="font-bold">My Account</span> to display the details
            of your deposits and withdrawals.
          </p>
        ),
      },
      {
        question: <p>Is my transaction/personal information secure?</p>,
        answer: (
          <p>
            Yes, Brother Bets encrypts all transactions, personal information
            and all other information using Secure Sockets Layer (SSL) 256-bit
            communication. We use only the highest level of data encryption to
            ensure that your information is safe and secure.
          </p>
        ),
      },
      {
        question: <p>Can I remove the self-regulation before the deadline?</p>,
        answer: (
          <p>
            The website will not be able to remove the self-imposed restrictions
            until the deadline you set by yourself has arrived.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Payments",
    content: [
      /* {
        question: <p>What deposit methods do you offer?</p>,
        answer: <p>We offer the following deposit methods;</p>,
      }, */
      {
        question: <p>How do I make a deposit?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                How to make a deposit on your mobile phone:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Login to your account</li>
              <li>
                Tap the "Account Icon" in the upper right corner of the website
              </li>
              <li>Tap "Deposit"</li>
              <li>Select a deposit method</li>
              <li>Enter the amount you wish to deposit</li>
              <li>Tap "Make Deposit" to complete your deposit</li>
              <li>
                Complete the procedure based on the deposit method and complete
                the deposit
              </li>
            </ol>
            <li className="mt-4">
              <span className="font-bold">How to make a deposit on a PC:</span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Login to your account</li>
              <li>Click "Deposit" in the upper right corner of the website</li>
              <li>Follow steps 4-7 above to complete your deposit</li>
            </ol>
            <li className="mt-4">
              ※ Please note that depending on your payment method, you may be
              redirected to the relevant payment site. Also, depending on the
              financial institution you use, you may be asked to verify your
              identity.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>Can I make deposits and withdrawals on weekends and holidays?</p>
        ),
        answer: (
          <p>Yes. All deposit and withdrawal methods are available 24/7</p>
        ),
      },
      /* {
        question: (<p>What is the minimum deposit amount?</p>),
        answer: "",
      }, */
      /* {
        question: (<p>Is there a fee for making a deposit?</p>),
        answer: "",
      }, */
      {
        question: <p>Is it safe to use my credit card at Brother Bets?</p>,
        answer: (
          <p>
            Yes. We do our best to protect the personal information provided by
            our customers. The security page of our website is encrypted using
            Secure Sockets Layer (SSL) 256-bit communication. Credit card
            information is encrypted and is not stored in the Brother Bets
            system, but is sent directly to the credit card processing company
            provided by Brother Bets through an encrypted channel. It is stored
            in the form of a <span className="font-bold">"token"</span> to be
            used as a reference for future transactions.
          </p>
        ),
      },
      {
        question: (
          <p>
            My account balance was not updated after I made a deposit, what
            should I do?
          </p>
        ),
        answer: (
          <p>
            Please try to log out manually and then log back in. If it still
            does not update, please contact our customer support team so that we
            can check the status of your deposit.
          </p>
        ),
      },
      {
        question: <p>How do I make a withdrawal?</p>,
        answer: (
          <p>
            You can withdraw money from your account at any time. Please note
            that you can only withdraw money using the same method you used to
            deposit money into your account, unless you are notified otherwise.
          </p>
        ),
      },
      {
        question: <p>What is the minimum withdrawal amount?</p>,
        answer: <p>The minimum withdrawal amount is $50.</p>,
      },
      {
        question: <p>How do I make a withdrawal request?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                While you are logged in, please follow the steps below.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Click on the account icon on the top right corner of the
                  website
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: <p>Will I be charged a fee for withdrawals?</p>,
        answer: (
          <p>
            No. There are no fees charged by Brother Bets. However, please note
            that depending on the payment institution you use, fees may be
            charged when transferring funds.
          </p>
        ),
      },
      {
        question: (
          <p>
            Why can't I submit a withdrawal request after clicking on{" "}
            <span className="font-bold">Withdrawal</span>?
          </p>
        ),
        answer: (
          <p>
            You may not have met the betting conditions relating to deposits and
            bonuses, in which case you will not be able to apply for a
            withdrawal. Also, if there is a withdrawal application that has not
            been completed yet, the next withdrawal cannot be made until the
            uncompleted withdrawal is completed. Although identity verification
            has been completed, if there are any errors in the registered
            information, you will not be able to apply for a withdrawal, so
            please double check that your phone number, email address, and all
            other registered information are entered correctly.
          </p>
        ),
      },
      {
        question: <p>When will my withdrawal be completed?</p>,
        answer: (
          <p>
            If all identity verification documents have been submitted, your
            application will be completed normally within 24 hours after
            receiving your application. Please note that there may be a slight
            delay depending on the withdrawal situation.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Account Verification",
    content: [
      {
        question: <p>Do I need to submit identity verification documents?</p>,
        answer: (
          <p>
            Yes, we ask for your identification documents to ensure that we can
            process your application smoothly. To expedite the process, please
            make sure that your registration information is always up-to-date
            and matches your identification documents. By submitting your
            identification documents, you help us provide a safer playing
            environment for you.
          </p>
        ),
      },
      {
        question: <p>Why do I need to verify my identity?</p>,
        answer: (
          <p>
            Apart from our obligation to fulfill our regulations, the process of
            account verification must be completed in order to enable the
            withdrawal feature on your account. The account verification
            process, or Know Your Customer, is the process by which your
            financial institution, bank, or payment platform verifies your
            identity.
          </p>
        ),
      },
      {
        question: <p>What kind of documents do I need to submit?</p>,
        answer: (
          <ul>
            <li>
              The following documents are usually required to verify your
              identity.
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  <ul>
                    <li className="mt-4">
                      <span className="font-bold">Identification card</span>
                    </li>
                    <li>✓ Driver's license (both sides)</li>
                    <li>✓ Passport (page with photo and page with address)</li>
                    <li>
                      ✓ Basic Resident Registration Card with photo (both sides)
                    </li>
                    <li>(Any one of the above)</li>
                    <li className="mt-4">
                      <span className="font-bold">
                        Required fields are as follows:
                      </span>
                    </li>
                    <li>
                      <ol className="list-disc ml-5">
                        <li>Face photo</li>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Date of birth</li>
                        <li>Expiration date</li>
                        <li>My Number digits (in case of My Number Card</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li className="mt-4">
                  <ul>
                    <li>
                      <span className="font-bold">
                        Proof of address (one document verifying the address
                        within 3 months from the date of issue)
                      </span>
                    </li>
                    <li>✓ Certificate of residence</li>
                    <li>
                      ✓ Water/electricity/gas bill/telephone bill with your
                      address and date of issue (if mailed)
                    </li>
                    <li>✓ Credit card statement (sent by mail)</li>
                    <li>
                      (Items other than those listed above will not be
                      considered)
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="mt-4">
              ※*Please take a photo of the submitted documents with a camera,
              smartphone, or mobile phone, and make sure to include all four
              corners so that the photo does not fill up the entire photo. When
              taking pictures using <span className="font-bold">flash</span>,
              please make sure that the required items are clearly readable.
              File extensions that can be uploaded are JPEG, BMP and PNG.
            </li>
            <li className="text-warning-200">
              Please upload images no larger than 5MB.
            </li>
          </ul>
        ),
      },
      {
        question: <p>How do I submit my personal identification documents?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                After logging in, please do the following:
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Tap the <span className="font-bold">Personal Center</span> in
                  the upper right corner of the website.
                </li>
                <li>
                  Tap <span className="font-bold">Identity Verification</span>.
                </li>
                <li>
                  Tap <span className="font-bold">Select File</span> and upload
                  your identification documents.
                </li>
                <li>
                  Tap <span className="font-bold">Save</span> to complete.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              <span className="font-bold">How to operate on a PC</span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Click the <span className="font-bold">Personal Center</span>{" "}
                  in the upper right corner of the website.
                </li>
                <li>
                  Select <span className="font-bold">My Account</span>.
                </li>
                <li>
                  Click on{" "}
                  <span className="font-bold">Account Verification</span>.
                </li>
                <li>
                  Click on <span className="font-bold">Select File</span> and
                  upload your personal identification documents.
                </li>
                <li>
                  Click <span className="font-bold">Save</span> to complete.
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            Do I need to submit any other documents after completing the
            identity verification?
          </p>
        ),
        answer: (
          <p>
            Please note that depending on the situation, you may be required to
            submit additional identification documents.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Bonus",
    content: [
      {
        question: <p>How do I receive my registration bonus?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Here's how to get your registration bonus.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Log in to your account and complete your account information.
                </li>
                <li>
                  Complete KYC (identity verification) by verifying your account
                  in My Account.
                </li>
                <li>
                  From the bonus menu in My Account, tap "Receive Rewards" to
                  receive your registration bonus.
                </li>
              </ol>
            </li>
            <li>
              (Once your identity has been verified, you will be able to receive
              it.)
            </li>
          </ul>
        ),
      },
      {
        question: <p>How do I receive my deposit bonus?</p>,
        answer: (
          <p>
            nce the deposit is reflected in your account, you can receive the
            first deposit quest under the bonus item of the icon in the upper
            right corner. For quests after the second deposit quest or other
            promotional quests, please apply to chat support after your deposit
            has been reflected, and we will proceed with the presentation
            procedure. The conditions for achieving each quest and how to
            receive them are also listed on each promotion page, so please check
            those as well.
          </p>
        ),
      },
      {
        question: <p>Why can't I get a new bonus?</p>,
        answer: (
          <p>
            ou will not be able to get a new bonus if you have a remaining bonus
            balance. Please make sure that your bonus balance is zero.
          </p>
        ),
      },
      {
        question: <p>Where can I check my bonus achievements?</p>,
        answer: (
          <p>
            Tap the icon in the upper right corner of the top screen, then tap{" "}
            <span className="font-bold">[Bonuses]</span>. In the active bonuses,
            there is an item called <span className="font-bold">Rollover</span>.
            The amount on the left side is the amount you have actually bet, and
            the amount on the right side is the amount you need to withdraw or
            cash out your bets. Each bonus has a different valid bet amount and
            game category, so please check the respective promotion page for
            details.
          </p>
        ),
      },
      {
        question: (
          <p>I accidentally received a deposit bonus. Can I cancel it?</p>
        ),
        answer: (
          <p>
            Please contact our customer support immediately after making a
            deposit and do not play any games. It cannot be withdrawn after you
            have started playing.
          </p>
        ),
      },
      {
        question: <p>The remaining fraction of my bonus has disappeared.</p>,
        answer: (
          <p>
            The bonus balance will be automatically deleted when the amount is
            less than 1USD. The purpose of this setting is to save you the
            trouble of having to play a game just to get your balance down to
            zero when you have a small amount of bonus left. Many other casinos
            have this feature as well.
          </p>
        ),
      },
      /* {
        question: (
          <p>
            Does the percentage that is counted as the wager amount differ
            depending on the game when fulfilling the quest conditions?
          </p>
        ),
        answer: <p>It depends on the game as shown below.</p>,
      }, */
      {
        question: <p>Are there any deadlines for bonuses or quests?</p>,
        answer: (
          <p>
            There is an expiration date for receiving and cashing in (rollover).
            Each bonus has a different expiration date, so please check the
            promotion page for details.
          </p>
        ),
      },
      {
        question: <p>Is there a specific withdrawal limit for bonuses?</p>,
        answer: (
          <p>
            Some bonuses have a specific amount that can be withdrawn. This is
            stated as the maximum withdrawal amount, please check the campaign
            terms and conditions.
          </p>
        ),
      },
      {
        question: <p>Is there a specific maximum bet for the bonus?</p>,
        answer: (
          <p>
            Each bonus has a different betting limit. You can bet over the
            betting limit, but it will not count towards your withdrawal
            requirements. However, if you use your bonus to play with a wager
            amount that is not reflected in the rollover, and your subsequent
            play is determined to be abusive or fraudulent, your account may be
            restricted, including pending withdrawal requests, until we
            investigate and know the outcome of the investigation.
          </p>
        ),
      },
    ],
  },
];

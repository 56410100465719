import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import useGameService from "src/services/gameService";
import useNotificationsService from "src/services/notificationsService";
import { GameResponse } from "src/types";
import { tempGames } from "src/utils/tempGames";

interface GamesContextProps {
  games: GameResponse[];
  isLoading: boolean;
}

const GamesContext = createContext<GamesContextProps | null>(null);

export const GamesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [games, setGames] = useState<GameResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { addErrorNotification } = useNotificationsService();
  const { getGames } = useGameService();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const { result: games } = await getGames();
        const isMobile = window.innerWidth < 1024;
        setGames(
          games
            .filter((game) => (isMobile ? game.has_mobile : !game.has_mobile))
            .filter((game) => tempGames.includes(game.id))
        );
      } catch (error) {
        addErrorNotification("Failed to fetch games");
        console.error("Error fetching games: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchGames();
  }, []);

  return (
    <GamesContext.Provider value={{ games, isLoading }}>
      {children}
    </GamesContext.Provider>
  );
};

export const useGames = (): GamesContextProps => {
  const context = useContext(GamesContext);
  if (!context) {
    throw new Error("useGames must be used within a GamesProvider");
  }
  return context;
};

import * as React from "react"
import { SVGProps } from "react"
const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      stroke="#739B33"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M23.769 26.663h-8.333"
    />
    <path
      stroke="#739B33"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.1 4.197-.05.117-4.832 11.216h-4.75a8.19 8.19 0 0 0-3.2.65l2.916-6.966.067-.167.117-.267c.033-.1.066-.2.116-.283 2.184-5.05 4.65-6.2 9.617-4.3Z"
    />
    <path
      stroke="#739B33"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30.084 15.864a7.934 7.934 0 0 0-2.35-.333H16.216L21.05 4.314l.05-.116c.25.083.483.2.733.3l3.684 1.55c2.05.85 3.483 1.733 4.35 2.8.166.2.3.383.416.6.15.233.267.466.334.716.066.15.116.3.15.434.45 1.4.183 3.116-.683 5.266Z"
    />
    <path
      stroke="#739B33"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M35.87 23.664v3.25c0 .333-.017.667-.033 1-.317 5.817-3.567 8.75-9.734 8.75h-13c-.4 0-.8-.033-1.183-.083-5.3-.35-8.133-3.184-8.483-8.484a9.197 9.197 0 0 1-.083-1.183v-3.25c0-3.35 2.033-6.233 4.933-7.483 1-.417 2.066-.65 3.2-.65h16.267a8.34 8.34 0 0 1 2.35.333c3.316 1.017 5.766 4.117 5.766 7.8Z"
    />
    <path
      stroke="#739B33"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.184 9.214 8.267 16.18c-2.9 1.25-4.933 4.134-4.933 7.484V18.78a9.748 9.748 0 0 1 7.85-9.566ZM35.864 18.78v4.883a8.131 8.131 0 0 0-5.766-7.784c.866-2.166 1.116-3.866.7-5.283a1.91 1.91 0 0 0-.15-.433c3.1 1.6 5.216 4.883 5.216 8.616Z"
    />
  </svg>
)
export default WalletIcon

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GamesSlider_SplideSlider__T2jJC .splide__slide .text-white {
  min-height: 3em;
}
.GamesSlider_SplideSlider__T2jJC .splide__slide.is-next {
  padding-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/slider/splide-slider/GamesSlider.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAGE;EACE,kBAAA;AADJ","sourcesContent":[".SplideSlider {\n  :global(.splide__slide .text-white) {\n    min-height: 3em;\n  }\n\n  :global(.splide__slide.is-next) {\n    padding-left: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SplideSlider": `GamesSlider_SplideSlider__T2jJC`
};
export default ___CSS_LOADER_EXPORT___;

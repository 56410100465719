import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import router from "./utils/routes";
import ReactGA from "react-ga4";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import CheckTokens from "./higherOrderComponents/CheckTokens";

const App: React.FC = () => {
  ReactGA.initialize("G-DWX2TW5GZT");

  return (
    <AuthProvider>
      <CheckTokens />
      <ReactNotifications isMobile={true} />
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;

import {
  LoginResponse,
  CreatePlayerResponse,
  CreatePlayerRequest,
  PlayerResponse,
  StatusesResponse,
} from "src/types";
import { useAxiosInterceptors } from "src/services/apiConfig";
import { isAxiosError } from "axios";
import { CustomError } from "src/errors/CustomError";
import {
  ChangePasswordRequest,
  UpdatePlayerRequest,
  ResetPasswordRequest,
  VerifyPlayerRequest,
} from "src/types/Player";
import { useTranslation } from "react-i18next";

const usePlayerService = () => {
  const { t } = useTranslation();
  const { axiosInstance, axiosLocationInstance } = useAxiosInterceptors();

  // Register a new player
  const registerPlayer = async (
    playerData: CreatePlayerRequest
  ): Promise<CreatePlayerResponse> => {
    try {
      const response = await axiosInstance.post("/players", playerData);
      return response.data.result;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Update player data
  const updatePlayer = async (
    playerId: string,
    newPlayerData: UpdatePlayerRequest
  ): Promise<PlayerResponse> => {
    try {
      const response = await axiosInstance.patch(
        `/players/${playerId}`,
        newPlayerData
      );
      return response.data.result;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Activate a new player account
  const verifyPlayer = async (request: VerifyPlayerRequest): Promise<void> => {
    try {
      const restRequest = {
        code: request.verificationToken,
        email: request.email,
      };
      await axiosInstance.post("/verification", restRequest);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Player password validation. Returns an authorization token.
  const login = async (
    email: string,
    password: string
  ): Promise<LoginResponse> => {
    try {
      const response = await axiosInstance.post("/login", {
        email: email,
        password: password,
      });
      return response.data;
    } catch (error: any) {
      if (
        isAxiosError(error) &&
        (error.response?.status === 404 || error.response?.status === 422)
      ) {
        throw new CustomError("Invalid", t("error_invalid-user-pwd"));
      } else {
        throw new Error(error);
      }
    }
  };

  // Get a list with all possible statuses
  const getStatuses = async (): Promise<StatusesResponse> => {
    try {
      const response = await axiosInstance.get("/players/status");
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // forward a password recovery request
  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await axiosInstance.post("/forgot-password", { email });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // reset the password of a player with a reset token
  const resetPassword = async (
    request: ResetPasswordRequest
  ): Promise<void> => {
    try {
      const restRequest = {
        code: request.resetToken,
        email: request.email,
        password: request.newPassword,
        // TODO remove this field once API is fixed
        old_password: "nothing",
      };
      await axiosInstance.post("/reset-password", restRequest);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // change the user's password
  const changePassword = async (
    request: ChangePasswordRequest
  ): Promise<void> => {
    try {
      const restRequest = {
        id: request.id,
        old_password: request.currentPassword,
        password: request.newPassword,
      };
      await axiosInstance.patch("/change-password", restRequest);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Check if an email is available for player registration
  const isEmailTaken = async (email: string): Promise<boolean> => {
    try {
      const response = await axiosInstance.get(`/players/exists/${email}`);
      if (response.status === 200) {
        return true; // Email is taken
      } else if (response.status === 404) {
        return false; // Email is not taken
      } else {
        // Handle other statuses if needed
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error: any) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return false; // Email is not taken
      } else {
        // Handle other errors (network errors, unexpected status codes)
        throw new Error(error);
      }
    }
  };

  // Check if an nickname is available for player registration
  const isNicknameTaken = async (nickname: string): Promise<boolean> => {
    try {
      const response = await axiosInstance.get(
        `/players/exists/nickname/${nickname}`
      );
      if (response.status === 200) {
        return true; // nickname is taken
      } else if (response.status === 404) {
        return false; // nickname is not taken
      } else {
        // Handle other statuses if needed
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error: any) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return false; // nickname is not taken
      } else {
        throw new Error(error);
      }
    }
  };

  // Locate user location continent using a geo-location API
  const getUserLocationContinent = async (): Promise<string> => {
    try {
      const response = await axiosLocationInstance.get("/json");
      return response.data.continent_code;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Get player data by client id
  const getPlayerData = async (
    playerId: string,
    token: string
  ): Promise<any> => {
    try {
      const response = await axiosInstance.get(`/players/${playerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    registerPlayer,
    updatePlayer,
    verifyPlayer,
    login,
    getStatuses,
    forgotPassword,
    resetPassword,
    changePassword,
    isEmailTaken,
    isNicknameTaken,
    getUserLocationContinent,
    getPlayerData,
  };
};

export default usePlayerService;

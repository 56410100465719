import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useTranslation } from "react-i18next";
import { ReactComponent as RightChevronIcon } from "../../../../assets/icons/arrows/Chevron Right.svg";
import { Link } from "react-router-dom";

export interface ProfileSliderProps {
  sliderId?: string;
  className?: string;
  contentList?: Array<JSX.Element>;
}

export const ProfileSlider = ({
  sliderId = "profile-slider",
  className = "",
  contentList,
}: ProfileSliderProps) => {
  const { t } = useTranslation();

  return contentList ? (
    <>
      <Splide
        id={sliderId}
        hasTrack={false}
        tag="section"
        options={{
          pagination: false,
          autoWidth: true,
          arrows: true,
          gap: "0.875rem",
          classes: {
            pagination: "splide__pagination flex justify-center gap-2",
          },
        }}
        className={className}
      >
        <SplideTrack>
          {contentList.map((content, index) => (
            <SplideSlide key={index}>{content}</SplideSlide>
          ))}
        </SplideTrack>
        <div
          className={`splide__arrows flex flex-row justify-between gap-2 pt-4 min-[1760px]:w-9/12 items-center`}
        >
          <button className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] splide__arrow--prev !left-auto group">
            <RightChevronIcon className="text-greyscale-100" />
          </button>
          <Link
            to="/games/hot"
            className="text-primary-400 hover:underline text-xs leading-[14px] transition duration-300"
          >
            {t("button_slider")}
          </Link>
          <button className="rounded-[4px] bg-[#2A253A] h-6 w-6 disabled:bg-[#130D25] relative splide__arrow--next !right-auto">
            <RightChevronIcon className="text-greyscale-100" />
          </button>
        </div>
      </Splide>
    </>
  ) : (
    <p>No items</p>
  );
};

export default ProfileSlider;

import { PlayerRequest } from "src/types";
import usePlayerService from "./playerService";
import useBackOfficeService from "./backOfficeService";
import { CustomError } from "src/errors/CustomError";
import useTransactionService from "./transactionService";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "./notificationsService";
import { useTranslation } from "react-i18next";

interface Limits {
    daily: number;
    weekly: number;
    monthly: number;
}

const useRegisterService = () => {
    const { t } = useTranslation();
    
    const { registerPlayer, login } = usePlayerService();
    const { setTransactionLimits } = useBackOfficeService(); 
    const { getPlayerWallets } = useTransactionService();
    const {
        setToken,
        setRefreshToken,
        setPlayerData,
      } = useAuth();
    const { addErrorNotification } = useNotificationsService();

    const register = async (playerData: PlayerRequest, limitsData: Limits): Promise<void> => {
        try {
            // Register a new player
            const { result: { player_id }} = await registerPlayer(playerData);
            localStorage.setItem("player_id", player_id); 
            // Login the player
            try {
                const {
                    player,
                    token,
                    refresh_token: refreshToken,
                  } = await login(playerData.email, playerData.password);
                  setPlayerData(player);
                  setToken(token);
                  setRefreshToken(refreshToken);
            } catch (error: any) {
                addErrorNotification(t("error_login"));
                console.log("Error logging in player: ", error);
            }
            // Set the player wallet limits
            setTimeout(async () => {
                try {
                    const { result: userWallets } = await getPlayerWallets(player_id);
                    const favouriteWalletId = userWallets.find(
                        (item) => item.currency_id === playerData.currency_id
                    )?.id;
                    if (!favouriteWalletId) {
                        addErrorNotification(t("error_wallet-not-found"));
                        throw new CustomError( "unknown", "Player wallet not found" );
                    }
                    if(!playerData.currency_id){
                        addErrorNotification(t("error_player-curr-not-found"));
                        throw new CustomError("uknown", "Player favourite currency not found");
                    }
                    // Set the transaction limits
                    const { result: depositLimitsId } = await setTransactionLimits({
                        type: "deposit",
                        player_id,
                        wallet_id: favouriteWalletId,
                        currency_code: playerData.currency_id,
                        daily: limitsData.daily,
                        weekly: limitsData.weekly,
                        monthly: limitsData.monthly,
                    });
                    console.log("Successfully created a deposit limit: ", depositLimitsId);
                    // NOTE: MAXIMUM DEPOSIT LIMITS ADDED AT REGISTER FOR NOW
                    const { result: withdrawalLimitsId } = await setTransactionLimits({
                        type: "withdraw",
                        player_id,
                        wallet_id: favouriteWalletId,
                        currency_code: playerData.currency_id,
                        daily: 99997,
                        weekly: 99998,
                        monthly: 99999,
                    });
                    console.log("Successfully created a withdrawal limit: ", withdrawalLimitsId);
                } catch (error: any) {
                    addErrorNotification(t("error_set_transaction-limits"));
                    console.log("Error setting transaction limits: ", error);
                }},
            1000);
        } catch (error: any) {
            addErrorNotification(t("error_register"));
            console.log("Error registering player: ", error);
        }
    }

    return { register };
}

export default useRegisterService;
import { Button } from "src/components/atoms";

export interface GameCardProps {
  className?: string;
  onClickInfo?: () => void;
  onClickPlay?: () => void;
  image?: string;
  name?: string;
  description?: string;
}

const GameCard = ({
  className = "",
  onClickInfo,
  onClickPlay,
  image,
  name,
  description,
}: GameCardProps) => {
  const onClickInfoHandler = () => {
    if (onClickInfo) {
      onClickInfo();
    } else {
      console.warn(`No click handler defined for ${name} more info button.`);
    }
  };

  const BodyOnHover = () => (
    <div className="invisible group-hover:visible p-6 flex flex-col h-full items-center justify-end gap-3 text-white transition duration-300">
      <span className="text-base font-bold">{name}</span>
      <span className="text-[10px]">{description}</span>
      <Button
        onClick={onClickPlay}
        className="flex justify-center items-center px-8 py-2 bg-secondary-200 text-black rounded-10"
      >
        Play game
      </Button>
      <span
        className="underline cursor-pointer text-[10px]"
        onClick={onClickInfoHandler}
      >
        More info
      </span>
    </div>
  );

  const GradientOnHover = () => (
    <div className="invisible group-hover:visible w-full h-full bg-gradient-to-t from-black from-30% to-transparent absolute -z-10 transition duration-300" />
  );

  return (
    <div className="rounded-lg flex flex-col relative w-[194px] h-[300px] group overflow-clip">
      <img src={image} alt="" className="absolute w-full -z-20" />
      <BodyOnHover />
      <GradientOnHover />
    </div>
  );
};

export default GameCard;

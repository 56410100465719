import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components/atoms";
import {
  ForgotPwdModal,
  InputText,
  RegisterInstead,
} from "src/components/molecules";
import InputPassword from "src/components/molecules/inputFields/inputPassword/InputPassword.component";
import { LoginContainer } from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import usePlayerService from "src/services/playerService";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import useNotificationsService from "src/services/notificationsService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function LoginPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setToken, setRefreshToken, setPlayerData, hasMiraclAuth } = useAuth();
  const { login } = usePlayerService();
  const navigate = useNavigate();
  const { addErrorNotification } = useNotificationsService();
  const [redirectUrl, setRedirectUrl] = useState("/");
  const [isForgotPwdModalOpen, setForgotPwdModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const gameTypeId = searchParams.get("gameTypeId");
    const gameId = searchParams.get("gameId");
    if (gameTypeId) {
      setRedirectUrl("/games/" + gameTypeId + "/" + (gameId || ""));
    } else {
      setRedirectUrl("/");
    }
  }, [searchParams]);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Login Page",
  });

  return (
    <LoginContainer>
      <ForgotPwdModal
        isOpen={isForgotPwdModalOpen}
        onClose={() => setForgotPwdModalOpen(false)}
      />

      <div className="h-full flex justify-center pt-32 pb-52 text-primary-400 px-6">
        <div className="w-[360px]">
          <div className="flex flex-col gap-[34px]">
            <div>
              <span className="text-[38px] font-bold ">
                {t("button_header_login")}
              </span>
              <div className="flex justify-between text-[14px]">
                <span>{t("text_ready-to-play")}</span>
              </div>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email(t("error_invalid-email"))
                  .required(t("error_required")),
                password: Yup.string().required(t("error_required")),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const {
                    player,
                    token,
                    refresh_token: refreshToken,
                  } = await login(values.email, values.password);
                  setPlayerData(player);
                  setToken(token);
                  setRefreshToken(refreshToken);
                  navigate(redirectUrl);
                } catch (error: any) {
                  addErrorNotification(error.description);
                  console.error("Error logging in: ", error.description);
                }
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-5">
                    <InputText
                      name="email"
                      label={t("placeholder_email")}
                      type="email"
                    />
                    <div className="flex flex-col gap-1 items-start">
                      <InputPassword name="password" label="Password" />
                      <button
                        type="button"
                        onClick={() => setForgotPwdModalOpen(true)}
                        className="text-xs text-[#1AC4E1] hover:text-primary-400 transition duration-300"
                      >
                        {t("link_forgot-pwd")}
                      </button>
                    </div>
                  </div>
                  <div className="mt-12" />
                  {hasMiraclAuth && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        `${process.env.REACT_APP_MIRACL_ISSUER}/authorize` +
                        `?response_type=code` +
                        `&client_id=${process.env.REACT_APP_MIRACL_CLIENT_ID}` +
                        `&redirect_uri=${process.env.REACT_APP_MIRACL_REDIRECT_URL_LOGIN}` +
                        `&scope=openid email profile` +
                        `&state=sss`
                      }
                      className="text-base underline text-secondary-300"
                    >
                      {t("link_login-miracl")}
                    </a>
                  )}
                  <Button
                    isDisabled={!values?.email || !values?.password}
                    type="button"
                    className="flex rounded justify-center w-full py-3 px-6 mb-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 mt-2 transition duration-300"
                    onClick={() => handleSubmit()}
                  >
                    {t("button_header_login")}
                  </Button>
                  <RegisterInstead />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
}

import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import { HeartEmptyIcon, HeartIcon } from "src/assets/svg";
import { Button } from "src/components/atoms";
import { useAuth } from "src/context/AuthContext";

export interface GameModalProps {
  className?: string;
  onRequestClose: () => void;
  onClickPlay?: () => void;
  isModalOpen: boolean;
  isFavourite: boolean;
  onClickFavourite?: () => void;
  game?: GameProps;
}

export interface GameProps {
  image: string;
  name: string;
  description: JSX.Element;
  bets: {
    minimum: string;
    maximum: string;
  };
  game_id: string;
  game_name: string;
  is_demo: boolean;
  is_mobile: boolean;
  provider_id: string;
}

ReactModal.setAppElement("#root");

const GameModal = ({
  className = "",
  onRequestClose,
  onClickFavourite,
  isModalOpen,
  isFavourite,
  onClickPlay,
  game,
}: GameModalProps) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      className="p-[34px] rounded-10 bg-primary-400 lg:w-[849px] w-10/12 absolute max-h-fit lg:top-1/2 !top-20"
      style={{
        overlay: {
          zIndex: 50,
          backgroundColor: "#1A1A1ACC",
          overflowY: "auto",
        },
        content: {},
      }}
    >
      {game ? (
        <div className="flex flex-col">
          <div className="flex gap-1 justify-end items-center w-full text-greyscale-100">
            <div
              className="cursor-pointer select-none"
              onClick={onClickFavourite}
            >
              {isFavourite ? <HeartIcon /> : <HeartEmptyIcon />}
            </div>
            <ClearIcon
              onClick={() => {
                onRequestClose();
              }}
              className="cursor-pointer w-8 h-8"
            />
          </div>
          <div className="flex lg:flex-row flex-col gap-11">
            <div className="flex lg:hidden justify-between items-center w-full text-greyscale-100">
              <div className="text-[44px] leading-[48px] font-semibold">
                {game.name}
              </div>
              {/* <div className="flex gap-1 items-center">
                <div
                  className="cursor-pointer select-none"
                  onClick={onClickFavourite}
                >
                  {isFavourite ? <HeartIcon /> : <HeartEmptyIcon />}
                </div>
                <ClearIcon
                  onClick={() => {
                    onRequestClose();
                  }}
                  className="cursor-pointer w-8 h-8"
                />
              </div> */}
            </div>
            <div className="lg:flex-1 self-center">
              <img src={game.image} alt="" className="rounded-10 w-[432px]" />
            </div>
            <div className="lg:flex-1 flex flex-col gap-6 text-greyscale-100 h-full">
              <div className="lg:flex hidden justify-between items-center w-full">
                <div className="lg:text-[44px] leading-[48px] text-[40px] font-semibold">
                  {game.name}
                </div>
              </div>
              <div className="flex flex-col gap-11 h-full">
                <Button
                  type="button"
                  className={`flex lg:hidden md:w-5/12 w-full self-center rounded w-[188px] text-greyscale-400 justify-between py-3 px-6 bg-secondary-200 ${
                    token ? "opacity-100" : "opacity-40"
                  }`}
                  onClick={onClickPlay}
                  isDisabled={!token}
                >
                  <div className="w-6" />
                  <span className="whitespace-nowrap">
                    {t("button_play-now")}
                  </span>
                  <ArrowRightIcon />
                </Button>
                <div className="break-words">{game.description}</div>
                {/* <div className="flex gap-6 self-center lg:self-start">
                <Button className="flex flex-col p-2.5 gap-1 justify-center items-center border border-greyscale-100 rounded-10">
                  <span className="font-bold break-keep">Minimum bet</span>
                  <span>${game.bets.minimum}</span>
                </Button>
                <Button className="flex flex-col p-2.5 gap-1 justify-center items-center border border-greyscale-100 rounded-10">
                  <span className="font-bold break-keep">Maximum bet</span>
                  <span>${game.bets.maximum}</span>
                </Button>
              </div> */}
                <Button
                  type="button"
                  className={`lg:flex hidden rounded w-[188px] text-greyscale-400 justify-between py-3 px-6 bg-secondary-200 ${
                    token ? "opacity-100" : "opacity-40"
                  }`}
                  onClick={onClickPlay}
                  isDisabled={!token}
                >
                  <div className="w-6" />
                  {t("button_play-now")}
                  <ArrowRightIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No game info to show</p>
      )}
    </ReactModal>
  );
};

export default GameModal;

export interface GameCardSimpleProps {
  className?: string;
  image?: string;
  name?: string;
}

const GameCardSimple = ({
  className = "",
  image,
  name,
}: GameCardSimpleProps) => {
  return (
    <div className="rounded-2xl p-3.5 gap-3.5 flex flex-col w-[185px] h-[240px] text-greyscale-100 font-semibold bg-primary-300">
      <img src={image} alt="" />
      {name}
    </div>
  );
};

export default GameCardSimple;

import { Link } from "react-router-dom";
import { ReactComponent as BrotherBetsLogo } from "src/assets/icons/logos/BB_logo_block_white.svg";
import { ReactComponent as LiveIcon } from "src/assets/icons/nav/Live.svg";
import { ReactComponent as CasinoIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as PokerIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { ReactComponent as PromotionIcon } from "src/assets/icons/nav/Promos.svg";
import { NavLink } from "src/components/atoms";
import { EmailSubmitField, NavBar } from "src/components/molecules";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col bg-black px-6 py-10 xl:px-52 lg:px-24 md:py-20 gap-20">
      {/* Logo Get product updates button, NavBar, links, social and Second NavBar section */}
      <div className="flex flex-col md:flex-row 2xl:justify-between xl:gap-10 gap-4">
        {/* Logo and Get product updates button section */}
        <div className="flex flex-col gap-[66px] sm:px-44 md:px-0">
          <div className="md:w-56 self-center">
            <BrotherBetsLogo width={"124px"} />
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-greyscale-100 text-sm w-56">
              {t("text_footer_join-community")}
            </p>
            <EmailSubmitField />
          </div>
        </div>
        {/* NavBar, links and social section */}
        <div className="flex flex-col items-start justify-center gap-[66px] md:px-0 px-4">
          <NavBar className="justify-center items-center py-4 md:gap-8 gap-11 flex-wrap lg:flex-nowrap">
            {/* <Link to={"/games/live"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <LiveIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_live")}
              </NavLink>
            </Link> */}
            <Link to={"/games/casino"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <CasinoIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_casino")}
              </NavLink>
            </Link>
            <Link to={"/games/poker"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <PokerIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_poker")}
              </NavLink>
            </Link>
            <Link to={"/games/slots"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <SlotsIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_slots")}
              </NavLink>
            </Link>
            <span className="bg-greyscale-100 w-px h-6"></span>
            <Link to={"/under-construction"}>
              <NavLink className="gap-2 group text-tertiary-400 hover:text-secondary-200 transition duration-300">
                <PromotionIcon className="text-tertiary-400 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_promotions")}
              </NavLink>
            </Link>
          </NavBar>
          <div className="flex xl:gap-[158px] gap-20 md:justify-normal justify-between flex-wrap xl:flex-nowrap">
            <div className="flex flex-col gap-6 text-sm">
              <h4 className="font-bold text-greyscale-100">
                {t("text_about")}
              </h4>
              <Link to={"/about-us"} className="text-greyscale-200">
                {t("button_about")}
              </Link>
              <Link to={"/responsible-gaming"} className="text-greyscale-200">
                {t("button_responsibility")}
              </Link>
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_affiliates")}
              </Link>
            </div>
            <div className="flex flex-col gap-6 text-sm">
              <h4 className="font-bold text-greyscale-100">{t("text_help")}</h4>
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_faqs")}
              </Link>
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_contact")}
              </Link>
            </div>
            <div className="flex flex-col gap-6 text-sm">
              <h4 className="font-bold text-greyscale-100">
                {t("text_regulations")}
              </h4>
              <Link to={"/terms-and-conditions"} className="text-greyscale-200">
                {t("button_general")}
              </Link>
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_privacy")}
              </Link>
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_aml")}
              </Link>
            </div>
            <div className="md:flex flex-col gap-6 text-sm hidden">
              <h4 className="font-bold text-greyscale-100">Social</h4>
              <NavLink className="text-greyscale-100 group">
                <Link to={"/under-construction"} className="text-greyscale-200">
                  {t("button_facebook")}
                </Link>
              </NavLink>
              <NavLink className="text-greyscale-100 group">
                <Link to={"/under-construction"} className="text-greyscale-200">
                  {t("button_twitter")}
                </Link>
              </NavLink>
              <NavLink className="text-greyscale-100 group">
                <Link to={"/under-construction"} className="text-greyscale-200">
                  {t("button_instagram")}
                </Link>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* Second NavBar section */}
      <div className="flex flex-col md:justify-between md:items-center gap-8 md:px-20 px-4">
        <div className="flex justify-between">
          <NavBar className="justify-center md:gap-[90px] gap-6 text-sm flex-col md:flex-row items-start md:items-center flex-wrap lg:flex-nowrap">
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_reviews")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_bonuses")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>
                {t("button_payment-methods")}
              </Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_learn")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_news")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("text_about")}</Link>
            </NavLink>
          </NavBar>
          <div className="flex flex-col gap-6 text-sm md:hidden">
            <h4 className="font-bold text-greyscale-100">{t("text_social")}</h4>
            <NavLink className="text-greyscale-100 group">
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_facebook")}
              </Link>
            </NavLink>
            <NavLink className="text-greyscale-100 group">
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_twitter")}
              </Link>
            </NavLink>
            <NavLink className="text-greyscale-100 group">
              <Link to={"/under-construction"} className="text-greyscale-200">
                {t("button_instagram")}
              </Link>
            </NavLink>
          </div>
        </div>
        <hr className="w-full border-greyscale-200" />
        <h4 className="text-xs text-greyscale-200 self-center">
          2024 © BrotherBets. {t("text_all-rights")}
        </h4>
        <h3 className="text-greyscale-100 self-center">
          BB - v{process.env.REACT_APP_VERSION}
        </h3>
      </div>
    </footer>
  );
};

export default Footer;

export interface OddsBadgeProps {
    indicator?: "1" | "X" | "2";
    odd?: string;
    onClick?: () => void;
}

export const OddsBadge = ({ indicator, odd, onClick }: OddsBadgeProps) => {
    const onClickHandler = () => {
        if (onClick) {
            onClick();
        } else {
            console.warn(`No click handler defined for odd button.`);
        }
    };

    return (
        <button
            type="button"
            className="flex flex-row text-white justify-between rounded-md bg-[#2A253A] py-2 px-3 w-20"
            onClick={onClickHandler}
        >
            <span className="opacity-60">{indicator}</span>
            <span>{odd}</span>
        </button>
    );
};

export default OddsBadge;

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { Footer, Header } from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import { getToken } from "src/services/miraclService";
import usePlayerService from "src/services/playerService";

export default function MiraclLoginRedirectPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { miraclEmail, miraclPlayerId, setPlayerData, setToken } = useAuth();
  const navigate = useNavigate();
  const { getPlayerData } = usePlayerService();

  useEffect(() => {
    if (code && miraclEmail && miraclPlayerId) {
      getToken(
        code,
        true,
        async (token) => {
          setToken(token);
          const { result } = await getPlayerData(miraclPlayerId, token);
          setPlayerData(result);
          navigate("/");
        },
        miraclEmail,
        miraclPlayerId
      );
    }
  }, [code, miraclEmail, miraclPlayerId]);
  return (
    <div className="font-main w-full relative">
      <Header />
      <div className="flex flex-col bg-custom-gradient items-center justify-center h-screen w-full">
        <MoonLoader
          color="#fff"
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      <Footer />
    </div>
  );
}

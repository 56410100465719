import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Banner, GameCard2, NavBar } from "src/components/molecules";
import { Button, NavLink } from "src/components/atoms";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as NewGamesIcon } from "src/assets/icons/nav/newGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
import { ReactComponent as LiveNowIcon } from "src/assets/icons/nav/liveNOW.svg";
import { ReactComponent as FavouritesIcon } from "src/assets/icons/nav/favourtites.svg";
import { ReactComponent as SkeletonSliderFilter } from "src/assets/icons/loaders/tabs_filterGames_slider.svg";
import { ReactComponent as SkeletonSlider } from "src/assets/icons/loaders/games_casino_popular.svg";
import { FAQ, Footer, Header, SplideSlider } from "src/components/organisms";
import ReactGA from "react-ga4";
import useGameService from "src/services/gameService";
import useNotificationsService from "src/services/notificationsService";
import { GameResponse } from "src/types";
import GameModal, {
  GameProps,
} from "src/components/molecules/gameModal/GameModal.component";
import { useAuth } from "src/context/AuthContext";
import useTransactionService from "src/services/transactionService";
import { tempGames } from "src/utils/tempGames";
import { motion } from "framer-motion";
import { useGames } from "src/context/GamesContext";

export default function HomePage() {
  const { t } = useTranslation();
  const [selectedSliderFilter, setSelectedSliderFilter] = useState<
    number | null
  >(null);
  const [sliderGameCards, setSliderGameCards] = useState<JSX.Element[]>([]);
  const [loadedGames, setLoadedGames] = useState<GameResponse[]>([]);
  const [gameModalData, setGameModalData] = useState<GameProps | undefined>(
    undefined
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { addErrorNotification } = useNotificationsService();
  const { getGames, launchGame } = useGameService();
  const { getCurrencyById } = useTransactionService();
  const { playerData } = useAuth();
  const { games } = useGames();

  const handleButtonClick = (buttonIndex: number): void => {
    if (selectedSliderFilter === buttonIndex) {
      setSelectedSliderFilter(null);
      /* setSliderGameCards(
        loadedGames.slice(0, 30).map((game, index) => (
          <GameCard2
            id={game.id}
            key={`game-card-${index}`}
            name={game.name}
            image={game.image}
            isFavourite={false}
            onClickFavourite={() => handleFavoriteToggle(game.id)}
            isHot={false}
            playersOnline={0}
            openModal={() => {
              setGameModalData({
                name: game.name,
                description: (
                  <div className="break-words flex flex-col">
                    <span>
                      {game.has_mobile
                        ? t("text_playable-on-mobile")
                        : t("text_playable-on-desktop")}
                    </span>
                    <span>
                      {game.has_demo
                        ? t("text_game-has-demo")
                        : t("text_no-game-description")}
                    </span>
                  </div>
                ),
                image: game.image,
                bets: {
                  minimum: "2",
                  maximum: "250",
                },
                game_id: game.id,
                game_name: game.name,
                is_demo: game.has_demo,
                is_mobile: game.has_mobile,
                provider_id: game.provider_id,
              });
              setModalOpen(true);
            }}
          />
        ))
      ); */
    } else {
      setSelectedSliderFilter(buttonIndex);
    }
  };

  const handleFavoriteToggle = (id: string | number): void => {
    alert(
      "The favourites service is still under development. Thank for your comprehension."
    );
    console.log(`Favourite toggle for game with id: ${id}`);
  };

  const handlePlayGame = async (game: GameProps): Promise<void> => {
    try {
      if (!playerData) {
        return addErrorNotification(t("error_please-login"));
      } else {
        const { result: currency } = await getCurrencyById(
          playerData.currency_id
        );
        if (!currency) {
          return addErrorNotification(t("error_get-currency"));
        }
        const {
          result: { play_for_real: url },
        } = await launchGame({
          game_id: game.game_id,
          game_name: game.game_name,
          is_demo: game.is_demo,
          is_mobile: game.is_mobile,
          player_id: playerData.id,
          provider_id: game.provider_id,
          user_currency: currency.alphabetic_code,
          user_language: "en",
        });
        // Open the external URL in a new window centered on the screen
        const width = 1024;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          url,
          "_blank",
          `width=${width},height=${height},top=${top},left=${left}`
        );
      }
    } catch (error: any) {
      addErrorNotification(t("error_launch-game"));
      console.log("Failed to launch game: ", error);
    }
  };

  const applyFilter = (gameType: string): void => {
    const filteredGames = loadedGames
      .filter((game) => game.type === gameType)
      .map((game, index) => (
        <GameCard2
          id={game.id}
          key={`game-card-${index}`}
          name={game.name}
          image={game.image}
          isFavourite={false}
          onClickFavourite={() => handleFavoriteToggle(game.id)}
          isHot={false}
          playersOnline={0}
          openModal={() => {
            setGameModalData({
              name: game.name,
              description: (
                <div className="break-words flex flex-col">
                  <span>
                    {game.has_mobile
                      ? t("text_playable-on-mobile")
                      : t("text_playable-on-desktop")}
                  </span>
                  <span>
                    {game.has_demo
                      ? t("text_game-has-demo")
                      : t("text_no-game-description")}
                  </span>
                </div>
              ),
              image: game.image,
              bets: {
                minimum: "2",
                maximum: "250",
              },
              game_id: game.id,
              game_name: game.name,
              is_demo: game.has_demo,
              is_mobile: game.has_mobile,
              provider_id: game.provider_id,
            });
            setModalOpen(true);
          }}
        />
      ));
    setSliderGameCards([]);
    setTimeout(() => setSliderGameCards(filteredGames), 500);
    setTimeout(() => setSliderGameCards(filteredGames), 500);
  };

  const sliderFilters = (
    <div className="overflow-x-auto px-2 py-4">
      <NavBar
        orientation="horizontal"
        className="items-center text-primary-400 gap-3.5 text-base py-10 inline-flex md:flex-wrap"
      >
        {/* <Button
          key={0}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            selectedSliderFilter === 0 ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => handleButtonClick(0)}
        >
          <HotGamesIcon />
          {t("link_hot-games")}
        </Button> */}
        <Button
          key={1}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            selectedSliderFilter === 1 ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => {
            handleButtonClick(1);
            applyFilter("table-games");
          }}
        >
          <PokerGamesIcon />
          {t("link_poker-games")}
        </Button>
        <Button
          key={2}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            selectedSliderFilter === 2 ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => {
            handleButtonClick(2);
            applyFilter("live-casino-table");
          }}
        >
          <CasinoGamesIcon />
          {t("link_casino-games")}
        </Button>
        <Button
          key={3}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            selectedSliderFilter === 3 ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => {
            handleButtonClick(3);
            applyFilter("video-slots");
          }}
        >
          <SlotsColouredIcon />
          {t("button_header_slots")}
        </Button>
      </NavBar>
    </div>
  );

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home Page",
  });

  const handleSetGameModalData = (game: GameResponse) => {
    setGameModalData({
      name: game.name,
      description: (
        <div className="break-words flex flex-col">
          <span>
            {game.has_mobile
              ? t("text_playable-on-mobile")
              : t("text_playable-on-desktop")}
          </span>
          <span>
            {game.has_demo
              ? t("text_game-has-demo")
              : t("text_no-game-description")}
          </span>
        </div>
      ),
      image: game.image,
      bets: {
        minimum: "2",
        maximum: "250",
      },
      game_id: game.id,
      game_name: game.name,
      is_demo: game.has_demo,
      is_mobile: game.has_mobile,
      provider_id: game.provider_id,
    });
    setModalOpen(true);
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoadedGames(games);
        const { result: homepageGames } = await getGames();
        const isMobile = window.innerWidth < 1024;
        setSliderGameCards(
          homepageGames
            .filter((game) => (isMobile ? game.has_mobile : !game.has_mobile))
            .filter((game) => tempGames.includes(game.id))
            //.slice(0, 30)
            .map((game, index) => (
              <GameCard2
                id={game.id}
                key={`game-card-${index}`}
                name={game.name}
                image={game.image}
                isFavourite={false}
                onClickFavourite={() => handleFavoriteToggle(game.id)}
                isHot={false}
                playersOnline={0}
                openModal={() => {
                  setGameModalData({
                    name: game.name,
                    description: (
                      <div className="break-words flex flex-col">
                        <span>
                          {game.has_mobile
                            ? t("text_playable-on-mobile")
                            : t("text_playable-on-desktop")}
                        </span>
                        <span>
                          {game.has_demo
                            ? t("text_game-has-demo")
                            : t("text_no-game-description")}
                        </span>
                      </div>
                    ),
                    image: game.image,
                    bets: {
                      minimum: "2",
                      maximum: "250",
                    },
                    game_id: game.id,
                    game_name: game.name,
                    is_demo: game.has_demo,
                    is_mobile: game.has_mobile,
                    provider_id: game.provider_id,
                  });
                  setModalOpen(true);
                }}
              />
            ))
        );
      } catch (error: any) {
        addErrorNotification(t("error_fetching-games"));
        console.log("Failed to fetch games: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, []);

  return (
    <div className="font-main w-full">
      <Header onSearchResultItemClick={handleSetGameModalData} />
      {/* Main section */}
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient w-full">
        <div className="flex gap-10 md:py-12 py-6 w-full">
          <div className="hidden lg:block">
            <NavBar
              orientation="vertical"
              className="justify-center items-center text-primary-400 gap-3.5 text-base sticky top-32 left-0 shadow-md z-40"
            >
              {/* <Link to={"/games/hot"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <HotGamesIcon />
                  {t("link_hot-games")}
                </NavLink>
              </Link>
              <Link to={"/games/new"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <NewGamesIcon />
                  {t("link_new-games")}
                </NavLink>
              </Link> */}
              <Link to={"/games/casino"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <CasinoGamesIcon />
                  {t("link_casino-games")}
                </NavLink>
              </Link>
              <Link to={"/games/poker"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <PokerGamesIcon />
                  {t("link_poker-games")}
                </NavLink>
              </Link>
              <Link to={"/games/slots"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <SlotsColouredIcon />
                  {t("button_header_slots")}
                </NavLink>
              </Link>
              {/* <Link to={"/games/live"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <LiveNowIcon />
                  {t("link_live-now")}!
                </NavLink>
              </Link>
              <Link to={"/games/favourites"}>
                <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
                  <FavouritesIcon />
                  {t("link_favourites")}
                </NavLink>
              </Link> */}
            </NavBar>
          </div>
          <div className="flex flex-col gap-10 w-full">
            <GameModal
              isModalOpen={isModalOpen}
              onRequestClose={() => setModalOpen(false)}
              game={gameModalData}
              isFavourite={false}
              onClickFavourite={() => handleFavoriteToggle(0)}
              onClickPlay={() =>
                gameModalData
                  ? handlePlayGame(gameModalData)
                  : console.warn("Error")
              }
            />
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.7 }}
              className="flex flex-col gap-10"
            >
              <Banner
                className="md:flex-row flex-col"
                image="images/banner1.png"
                background="images/banner1_bg.png"
                size="w-full xl:h-[430px] h-[450px]"
                buttonOnClick={() => alert("Banner 1")}
                text={
                  <div className="flex flex-col gap-3.5 items-center lg:items-start">
                    <span className="md:text-sm text-[10px] font-normal">
                      {t("banner1_header1")}
                    </span>
                    <span className="2xl:text-[44px] xl:text-3xl font-normal text-2xl">
                      {t("banner1_header2")}
                    </span>
                    <span className="2xl:text-[44px] xl:text-3xl font-bold text-2xl">
                      {t("banner1_header3")}
                    </span>
                  </div>
                }
              />
              <div className="md:flex flex-row gap-10 hidden">
                <Banner
                  image="images/banner2.png"
                  background="images/banner2_bg.png"
                  size="w-full h-[430px]"
                  buttonOnClick={() => alert("Banner 2")}
                  text={
                    <div className="flex flex-col gap-2">
                      <span className="text-white text-[34px] font-normal">
                        {t("banner2_header1")}
                      </span>
                      <span className="text-white text-[34px] font-bold">
                        {t("banner2_header2")}
                      </span>
                    </div>
                  }
                />
                <Banner
                  image="images/banner3.png"
                  background="images/banner3_bg.png"
                  size=" w-full h-[430px]"
                  text={
                    <div className="flex flex-col gap-2">
                      <span className="text-white text-[34px] font-normal">
                        {t("banner3_header1")}
                      </span>
                      <span className="text-white text-[34px] font-bold">
                        {t("banner3_header2")}
                      </span>
                    </div>
                  }
                />
              </div>
            </motion.div>
            <div className="2xl:w-[calc(100vw-652px)] xl:w-[calc(100vw-450px)] lg:w-[calc(100vw-320px)] md:w-[calc(100vw-54px)] w-[calc(100vw-32px)]">
              {!isLoading ? (
                <SplideSlider
                  sliderId="games-slider"
                  sliderHeaderContent={sliderFilters}
                  contentList={sliderGameCards}
                />
              ) : (
                <div className="flex flex-col gap-10">
                  <SkeletonSliderFilter />
                  <SkeletonSlider />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-10 md:hidden">
              <Banner
                className="md:flex-row flex-col"
                image="images/banner2.png"
                background="images/banner2_bg.png"
                size="w-full h-[400px]"
                buttonOnClick={() => alert("Banner 2")}
                text={
                  <div className="flex flex-col md:gap-2 gap-3.5 items-center md:items-start">
                    <span className="text-white text-[34px] font-normal">
                      {t("banner2_header1")}
                    </span>
                    <span className="text-white text-[34px] font-bold">
                      {t("banner2_header2")}
                    </span>
                  </div>
                }
              />
              <Banner
                className="flex-row"
                image="images/banner3.png"
                background="images/banner3_bg.png"
                size="w-full h-[234px]"
                text={
                  <div className="flex flex-col md:gap-2 gap-3.5 items-center md:items-start">
                    <span className="text-white text-[34px] font-normal">
                      {t("banner3_header1")}
                    </span>
                    <span className="text-white text-[34px] font-bold">
                      {t("banner3_header2")}
                    </span>
                  </div>
                }
              />
            </div>
            <div className="md:px-24">
              <FAQ />
            </div>
            <section className="flex flex-col md:px-64 md:py-20 text-sm items-center text-center text-greyscale-100 gap-8">
              <b>{t("text_gambling-aware-2")}</b>
              <p>{t("text-copyright")}</p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

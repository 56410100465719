// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleSelect_SingleSelect__GPkBK input[type=radio] {
  appearance: none;
}
.SingleSelect_SingleSelect__GPkBK input[type=radio]::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 2em 2em #65236c;
  transform: scale(0);
}
.SingleSelect_SingleSelect__GPkBK input[type=radio]:checked::before {
  transform: scale(0.7);
}
.SingleSelect_SingleSelect__GPkBK input[type=radio]:disabled, .SingleSelect_SingleSelect__GPkBK input[type=radio]:disabled + label {
  opacity: 0.3;
  cursor: default;
}
.SingleSelect_SingleSelect__GPkBK .inputOption:not(:first-child) {
  border-top: 1px #efe5ef solid;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/singleSelect/SingleSelect.module.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ;AAGI;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uCAAA;EACA,iCAAA;EACA,mBAAA;AADN;AAII;EACE,qBAAA;AAFN;AAKI;EACE,YAAA;EACA,eAAA;AAHN;AAOE;EACE,6BAAA;AALJ","sourcesContent":[".SingleSelect :global {\n\n  input[type=\"radio\"] {\n    appearance: none;\n\n    &::before {\n      content: \"\";\n      display: block;\n      width: 100%;\n      height: 100%;\n      border-radius: 50%;\n      transition: 120ms transform ease-in-out;\n      box-shadow: inset 2em 2em #65236c;\n      transform: scale(0);\n    }\n\n    &:checked::before {\n      transform: scale(0.7);\n    }\n\n    &:disabled, &:disabled+label {\n      opacity: 0.3;\n      cursor: default;\n    }\n  }\n\n  .inputOption:not(:first-child) {\n    border-top: 1px #efe5ef solid;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SingleSelect": `SingleSelect_SingleSelect__GPkBK`
};
export default ___CSS_LOADER_EXPORT___;

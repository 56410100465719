import Button, { ButtonProps } from "./button/Button.component";
import NavLink, { NavLinkProps } from "./link/NavLink.component";
import OddsBadge, { OddsBadgeProps } from "./oddsBadge/OddsBadge.component";
import Option, { OptionProps } from "./option/Option.component";
import PrivateRoute from "./privateRoute/PrivateRoute.component";
import CountrySelector from "../molecules/inputFields/countrySelector/CountrySelector.component";
import Markdown, { MarkdownOptions } from "./markdown/Markdown.component";
import TCLink from "./link/TCLink.component";

export {
  Button,
  CountrySelector,
  Markdown,
  NavLink,
  OddsBadge,
  Option,
  PrivateRoute,
  TCLink,
};
export type {
  ButtonProps,
  NavLinkProps,
  MarkdownOptions,
  OddsBadgeProps,
  OptionProps,
};

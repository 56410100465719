import { VisaIcon } from "src/assets/svg";
import { Button } from "src/components/atoms";
import { ReactComponent as DeleteIcon } from "src/assets/icons/edit/Delete.svg";

export interface PaymentMethodCardProps {
    type?: string;
    lastDigits: string;
    expDate: string;
    isDefault: boolean;
}

const PaymentMethodCard = ({
    type,
    lastDigits,
    expDate,
    isDefault,
}: PaymentMethodCardProps) => {
    return (
        <div className="w-[415px] p-3.5 border border-primary-400 rounded-10 flex justify-between">
            <div className="flex w-full gap-2.5">
                <div>
                    <VisaIcon className="w-[72px] h-12" />
                </div>
                <div className="w-full">
                    <div className="w-full flex justify-between">
                        <span className="text-primary-400 text-sm">
                            {type} ending in {lastDigits}
                        </span>
                        <div className="flex gap-2.5">
                            <div className="rounded bg-primary-400 text-greyscale-100 text-xs leading-[14px] px-3.5 py-1">
                                Default
                            </div>
                            <DeleteIcon />
                        </div>
                    </div>
                    <span className="text-greyscale-200 text-xs">
                        Exp.date {expDate}
                    </span>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default PaymentMethodCard;

import { FormikProps } from "formik";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import {
  InputPassword,
  InputText,
  LoginInstead,
} from "src/components/molecules";
import { Button } from "src/components/atoms";
import { useTranslation } from "react-i18next";

export interface FormRegistrationStep2Props {
  values: FormikProps<any>["values"];
  errors: FormikProps<any>["errors"];
  handleBlur: FormikProps<any>["handleBlur"];
  handleChange: FormikProps<any>["handleChange"];
  validateField: FormikProps<any>["validateField"];
  onClickNext: () => void;
  onClickBack: () => void;
}

export const FormRegistrationStep2 = ({
  values,
  errors,
  handleBlur,
  handleChange,
  validateField,
  onClickNext,
  onClickBack,
}: FormRegistrationStep2Props) => {
  const { t } = useTranslation();
  const handleOnBlur = (e: React.FocusEvent<any>) => {
    handleBlur(e);
    validateField(e.target.name);
  };
  const handleOnChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    validateField(e.target.name);
  };

  return (
    <div>
      <div className="flex flex-col gap-[34px] mb-[54px]">
        <div className="flex flex-col gap-6">
          <span className="text-lg font-semibold text-primary-400">
            {t("text_account-details")}
          </span>
          <InputText
            name="username"
            label={t("text_username")}
            type="text"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <InputPassword
            name="password"
            label={t("text_password")}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <InputText
            name="email"
            label={t("text_email")}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex justify-between gap-10 mb-6">
        <button
          type="button"
          onClick={onClickBack}
          className="flex items-center rounded w-full py-3 px-6 bg-primary-400 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-primary-100 transition duration-300"
        >
          <ArrowLeftIcon />
          <span className="mx-auto">{t("button_back")}</span>
        </button>
        <Button
          isDisabled={
            !values.password ||
            !values.email ||
            errors?.username !== undefined ||
            errors?.password !== undefined ||
            errors?.email !== undefined
          }
          type="button"
          className="flex rounded justify-between w-full py-3 px-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 transition duration-300"
          onClick={() => onClickNext()}
        >
          <div className="w-6" />
          {t("button_next")}
          <ArrowRightIcon />
        </Button>
      </div>
      <LoginInstead />
    </div>
  );
};

export default FormRegistrationStep2;

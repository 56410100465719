import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntNumericInputExcludes } from "src/utils/inputConstants";
import styles from "./InputDate.module.scss";

export interface InputDateProps {
  yearMin?: number;
  yearMax?: number;
  setValue: (date: string) => void;
  initialDate?: string;
  disabled?: boolean;
}

const InputDate = ({
  yearMin = 1900,
  yearMax = moment(moment.now()).year(),
  setValue,
  initialDate,
  disabled,
}: InputDateProps) => {
  const { t } = useTranslation();
  const [dayValue, setDayValue] = useState<string | undefined>(undefined);
  const [monthValue, setMonthValue] = useState<string | undefined>(undefined);
  const [yearValue, setYearValue] = useState<string | undefined>(undefined);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>(
    t("error_date-not-exist")
  );

  const months = [
    t("text_january"),
    t("text_february"),
    t("text_march"),
    t("text_april"),
    t("text_may"),
    t("text_june"),
    t("text_july"),
    t("text_august"),
    t("text_september"),
    t("text_october"),
    t("text_november"),
    t("text_december"),
  ];

  const monthRef = useRef<HTMLInputElement | null>(null);
  const yearRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (initialDate) {
      const date = moment(initialDate);
      if (date.isValid()) {
        setDayValue(date.format("DD"));
        setMonthValue(date.format("MM"));
        setYearValue(date.format("YYYY"));

        if (monthRef.current) {
          monthRef.current.type = "text";
          monthRef.current.value = months[Number(date.format("MM")) - 1] || "";
        }
      }
    }
  }, [initialDate]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    IntNumericInputExcludes.includes(e.key) && e.preventDefault();

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    updateState: (value: string) => void
  ) => {
    const value = parseInt(e.target.value);
    const min = parseInt(e.target.min);
    const max = parseInt(e.target.max);
    if (e.target.value && value < min) {
      updateState(`${min}`);
    }
    if (e.target.value && value > max) {
      updateState(`${max}`);
    }

    checkIfDateIsValid();
  };

  const handleBlurMonth = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const min = parseInt(e.target.min);
    const max = parseInt(e.target.max);
    let correctedValue: number = value;
    e.target.type = "text";

    if (e.target.value && value < min) {
      correctedValue = min;
    }
    if (e.target.value && value > max) {
      correctedValue = max;
    }
    e.target.value = months[correctedValue - 1] || "";
    setMonthValue(`${correctedValue}`);

    checkIfDateIsValid();
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement>,
    isMonth = false
  ) => {
    e.target.type = "text";
    if (isMonth) {
      e.target.value = monthValue || "";
    }
    e.target.setSelectionRange(0, e.target.value.length);
    e.target.type = "number";
  };

  const checkIfDateIsValid: () => void = () => {
    if (dayValue && monthValue && yearValue) {
      const completeDate = moment(
        `${dayValue}-${monthValue}-${yearValue}`,
        "DD-MM-YYYY"
      );

      setIsDateValid(completeDate.isValid());
      setErrorMessage(t("error_date-not-exist"));

      if (moment().diff(completeDate, "years") < 18) {
        setIsDateValid(false);
        setErrorMessage(t("error-too-young"));
        return;
      }

      if (completeDate.isValid()) {
        setValue(completeDate.toDate().toISOString());
      }
    }
  };

  return (
    <div className={`${styles.InputDate} flex flex-col gap-1`}>
      <div className="flex gap-[10px]">
        <div className="relative min-w-14 w-full bg-none">
          <span className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10">
            {t("text_day")}
          </span>
          <input
            className="w-full h-14 border bg-transparent border-primary-400 rounded px-4"
            placeholder={t("placeholder_day")}
            type="number"
            disabled={disabled}
            value={dayValue}
            onChange={(e) => setDayValue(e.target.value)}
            min={1}
            max={31}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDown(e)
            }
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (`${e.currentTarget.value}`.length > 1) {
                monthRef.current?.focus();
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(e, setDayValue)
            }
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleFocus(e)}
          />
        </div>
        <div className="relative min-w-28 w-full bg-none">
          <span className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10">
            {t("text_month")}
          </span>
          <input
            className="w-full h-14 border bg-transparent border-primary-400 rounded px-4 md:text-base text-sm"
            placeholder={t("placeholder_month")}
            type="number"
            disabled={disabled}
            ref={monthRef}
            min={1}
            max={12}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDown(e)
            }
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (`${e.currentTarget.value}`.length > 1) {
                yearRef.current?.focus();
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlurMonth(e)
            }
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(e, true)
            }
          />
        </div>
        <div className="relative min-w-20 w-full bg-none">
          <span className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10">
            {t("text_year")}
          </span>
          <input
            className="w-full h-14 border bg-transparent border-primary-400 rounded px-4"
            placeholder={t("placeholder_year")}
            type="number"
            disabled={disabled}
            ref={yearRef}
            value={yearValue}
            onChange={(e) => setYearValue(e.target.value)}
            min={yearMin}
            max={yearMax}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDown(e)
            }
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (`${e.currentTarget.value}`.length > 3) {
                yearRef.current?.blur();
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(e, setYearValue)
            }
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleFocus(e)}
          />
        </div>
      </div>
      {!isDateValid && (
        <span className="pl-5 text-[10px] text-warning-100">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default InputDate;

import { useTranslation } from "react-i18next";
import { Footer, Header } from "src/components/organisms";

export default function ResponsibleGamingPage() {
  const { t } = useTranslation();
  return (
    <div className="font-main w-full relative">
      <Header />
      <div className="flex flex-col bg-custom-gradient  gap-10 justify-center md:px-52 md:py-40 py-6 px-6 w-full text-greyscale-100">
        <div className="text-2xl font-semibold">
          {t("text_responsible-gaming")}
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <div>
              <a href="https://gamblersanonymous.org.uk/" className="underline">
                Gamblers Anonymous – England, Wales & Ulster
              </a>
            </div>
            <div className="text-lg">{t("text_gamblers-anonymous")}</div>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <a href="https://www.gamstop.co.uk/" className="underline">
                VGAMSTOP - Gambling Self-Exclusion Scheme
              </a>
            </div>
            <div className="text-lg">{t("text_vgamstop")}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

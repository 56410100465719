import { useCallback, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import { ReactComponent as SearchIcon } from "src/assets/icons/edit/Search.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear Circle.svg";
import { ReactComponent as DiceIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as ChipIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { ReactComponent as BrokenHeartIcon } from "src/assets/icons/emoji/Broken Heart.svg";
import _ from "lodash";
import { GameResponse } from "src/types";
import { useTranslation } from "react-i18next";

export interface SearchFieldProps {
  onSearch: (query: string) => void;
  results: GameResponse[];
  isMobile?: boolean;
  onSearchResultItemClick?: (game: GameResponse) => void;
}

const SearchField = ({
  onSearch,
  results = [],
  isMobile = false,
  onSearchResultItemClick = () => {},
}: SearchFieldProps) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>("");

  const debouncedSearch = useCallback(
    _.debounce((searchTerm: string) => {
      onSearch(searchTerm);
    }, 300), // Adjust the debounce delay as needed
    []
  );

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
    index: number
  ) => {
    const parentElement = e.currentTarget.parentElement;
    if (parentElement) {
      parentElement.innerHTML = "";
      const fallbackContent = (
        <div className="grid grid-cols-2 grid-rows-2 gap-1 place-items-center w-[50px] h-[50px] bg-[#36073B] text-greyscale-100">
          <DiceIcon className="w-7/12 h-full" />
          <ChipIcon className="w-7/12 h-full" />
          <ControllerIcon className="w-7/12 h-full" />
          <SlotsIcon className="w-7/12 h-full" />
        </div>
      );

      // Render the fallback component inside the parent element
      const div = document.createElement("div");
      const root = createRoot(div);
      flushSync(() => {
        root.render(fallbackContent);
      });
      parentElement.appendChild(div);
    }
  };

  return (
    <div className="relative w-full max-w-md mx-auto">
      <input
        type="text"
        className="w-full px-2.5 py-3.5 border border-primary-400 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 placeholder-greyscale-200 text-primary-400 text-sm leading-6"
        placeholder={t("placeholder_search-field")}
        value={query}
        onChange={handleInputChange}
      />
      {query ? (
        <button
          className="absolute inset-y-0 right-0 flex items-center pr-3"
          onClick={() => setQuery("")}
        >
          <ClearIcon className="text-primary-400" />
        </button>
      ) : (
        <button className="absolute inset-y-0 right-0 flex items-center pr-3">
          <SearchIcon className="text-primary-400" />
        </button>
      )}
      {results.length > 0 && (
        <ul
          className={
            isMobile
              ? "fixed left-0 mt-4 z-10 w-full h-full bg-greyscale-400 text-greyscale-100 border border-greyscale-400 text-sm overflow-y-auto"
              : "mt-5 w-96 max-h-[334px] absolute max-w-md bg-greyscale-400 text-greyscale-100 border border-greyscale-400 rounded-lg shadow-lg text-sm overflow-y-auto"
          }
        >
          {results.map((result, index) => (
            <li
              key={index}
              className={
                isMobile
                  ? "flex items-center gap-3.5 py-2 border-b last:border-b-0 last:rounded-b-lg first:rounded-t-lg border-greyscale-300 hover:bg-greyscale-300 hover:cursor-pointer transition duration-300 px-6 md:px-60 lg:px-80 xl:px-96"
                  : "flex items-center gap-3.5 px-4 py-2 border-b last:border-b-0 last:rounded-b-lg first:rounded-t-lg border-greyscale-300 hover:bg-greyscale-300 hover:cursor-pointer transition duration-300"
              }
              onClick={() => onSearchResultItemClick({ ...result })}
            >
              <div>
                <img
                  src={result.image}
                  alt=""
                  className="w-[50px] h-[50px] rounded-md cursor-pointer"
                  onError={(e) => handleImageError(e, index)}
                />
              </div>
              {result.name}
            </li>
          ))}
        </ul>
      )}
      {query && isMobile && results.length === 0 && (
        <div className="fixed left-0 mt-4 z-10 w-full h-full bg-greyscale-400 text-greyscale-100 border border-greyscale-400 text-sm overflow-y-auto flex flex-col gap-3.5 items-center justify-start py-4 px-6 md:px-60 lg:px-80 xl:px-96">
          <BrokenHeartIcon className="w-12 h-12 text-tertiary-400" />
          <p className="text-tertiary-400 text-lg leading-6 font-bold ">
            {t("text_search-field_no-results")}
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchField;

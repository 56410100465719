import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import Button from "src/components/atoms/button/Button.component";

export interface InputPinpadProps {
  input: string;
  setInput: (code: string) => void;
  codeLength: number;
  isVisible?: boolean;
}

const InputPinpad = ({
  input,
  setInput,
  codeLength = 4,
  isVisible = false,
}: InputPinpadProps) => {
  const { t } = useTranslation();
  const refs = useRef<(HTMLInputElement | null)[]>([]);

  const [focused, setFocused] = useState<boolean>(false);
  const handleInput = (newChar: string) => {
    if (input.length < codeLength) {
      (input + newChar).length > 0
        ? refs?.current[input.length]?.focus()
        : refs?.current[0]?.focus();
      setInput(input + newChar);
    }
  };
  function handleKeyUp(ev: React.KeyboardEvent<HTMLInputElement>): any {
    if (
      (ev.keyCode >= 48 && ev.keyCode <= 57) ||
      (ev.keyCode >= 96 && ev.keyCode <= 105)
    ) {
      const key = ev.key;
      handleInput(key);
    }

    if (ev.keyCode === 8) {
      input.length - 1 > 0
        ? refs?.current[input.length - 1]?.focus()
        : refs?.current[0]?.focus();
      setInput(input.slice(0, -1));
    }
  }
  const pinpadKeys = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    {
      label: (
        <div className="text-primary-100 flex gap-2.5 items-center px-4">
          <ClearIcon />
          <span className="text-greyscale-300">{t("button_cancel")}</span>
        </div>
      ),
      onClick: () => setInput(""),
    },
    "0",
    {
      label: (
        <div className="text-primary-100 flex gap-2.5 items-center px-4">
          <ArrowLeftIcon />
          <span className="text-greyscale-300">{t("button_delete")}</span>
        </div>
      ),
      onClick: () => setInput(input.slice(0, -1)),
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex w-full flex-row justify-between">
        {Array.from({ length: codeLength }).map((_item, index) => {
          return (
            <input
              ref={(element) => refs.current.push(element)}
              type={isVisible ? "text" : "password"}
              pattern="[0-9]*"
              inputMode="numeric"
              className="border max-w-20  rounded border-primary-400 h-12 text-center"
              id={`box${index}-1`}
              placeholder="*"
              value={input[index] || ""}
              onKeyUp={(e) => {
                handleKeyUp(e);
              }}
              onBlur={() => {
                setFocused(false);
                console.log(focused);
              }}
              onClick={() => {
                input.length > 0
                  ? refs?.current[input.length - 1]?.focus()
                  : refs?.current[0]?.focus();
              }}
              onChange={() => {}}
              maxLength={1}
            />
          );
        })}
      </div>
      {true && (
        <div className="w-full md:grid grid-cols-3 gap-2 p-8 hidden">
          {pinpadKeys.map((item, index) =>
            typeof item === "string" ? (
              <Button
                key={`padKey-${index}`}
                onClick={() => handleInput(item)}
                className="flex flex-auto justify-center items-center h-[46px] border border-primary-100 rounded-[5px] text-2xl font-bold text-greyscale-300"
              >
                {item}
              </Button>
            ) : (
              <Button onClick={item.onClick}>{item.label}</Button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default InputPinpad;

import * as React from "react"
import { SVGProps } from "react"
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      stroke="#2F0249"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M4.824 20.542h22.959M14.49 39.875h4.834M25.365 39.875h9.667"
    />
    <path
      stroke="#2F0249"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M53.158 29.073v9.86c0 8.482-2.151 10.609-10.73 10.609H15.554c-8.579 0-10.73-2.127-10.73-10.61V19.067c0-8.482 2.151-10.609 10.73-10.609h19.479"
    />
    <path
      stroke="#2F0249"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m46.1 9.98-8.966 8.967c-.338.338-.676 1.015-.749 1.498l-.483 3.432c-.17 1.232.7 2.102 1.933 1.933l3.432-.483c.483-.073 1.16-.411 1.498-.75l8.966-8.965c1.547-1.547 2.272-3.335 0-5.607-2.296-2.296-4.084-1.57-5.63-.024Z"
    />
    <path
      stroke="#2F0249"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M44.82 11.262c.774 2.73 2.9 4.857 5.607 5.606"
    />
  </svg>
)
export default EditIcon

import AddCardForm from "./form/AddCardForm.component";
import AccountProfileStats from "./account/profile/accountProfileStats/AccountProfileStats.component";
import EditProfileForm from "./form/EditProfileForm.component";
import TransactionModal from "./account/profile/transactionModal/TransactionModal.component";
import FAQ from "./faqSection/FAQ.component";
import GamesFilter from "./gamesFilter/GamesFilter.component";
import Footer from "./footer/Footer.component";
import PersonalDetailsForm, {
  PersonalDetailsFormProps,
} from "./form/PersonalDetailsForm.component";
import AccountDetailsForm, {
  AccountDetailsFormProps,
} from "./form/AccountDetailsForm.component";
import ContactDetailsForm, {
  ContactDetailsFormProps,
} from "./form/ContactDetailsForm.component";
import DepositLimitsForm, {
  DepositLimitsFormProps,
} from "./form/DepositLimitsForm.component";
import GameList, { GameListProps } from "./gameList/GameList.component";
import Header from "./header/Header.component";
import LoginContainer, {
  LoginContainerProps,
} from "./loginContainer/LoginContainer.component";
import LoginHeader, {
  LoginHeaderProps,
} from "./loginHeader/LoginHeader.component";
import MainContainer, {
  MainContainerProps,
} from "./mainContainer/MainContainer.component";
import MobileMenu, { MobileMenuProps } from "./mobileMenu/MobileMenu.component";
import Slider, { SliderProps } from "./slider/Slider.component";
import GamesSlider, {
  GamesSliderProps,
} from "./slider/splide-slider/GamesSlider.component";
import WalletTables from "./account/wallet/walletTables/WalletTables.component";
import ChangePasswordModal from "./account/profile/changePasswordModal/ChangePasswordModal.component";

export {
  AccountDetailsForm,
  AccountProfileStats,
  AddCardForm,
  ContactDetailsForm,
  DepositLimitsForm,
  EditProfileForm,
  FAQ,
  GamesFilter,
  Footer,
  GameList,
  Header,
  LoginContainer,
  LoginHeader,
  MainContainer,
  MobileMenu,
  PersonalDetailsForm,
  Slider,
  GamesSlider,
  TransactionModal,
  WalletTables,
  ChangePasswordModal,
};

export type {
  AccountDetailsFormProps,
  ContactDetailsFormProps,
  DepositLimitsFormProps,
  GameListProps,
  LoginContainerProps,
  LoginHeaderProps,
  MainContainerProps,
  MobileMenuProps,
  SliderProps,
  GamesSliderProps,
  PersonalDetailsFormProps,
};

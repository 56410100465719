import { useEffect } from "react";
import { MainContainer } from "src/components/organisms";
import ReactGA from "react-ga4";

export default function RedirectClosePage() {
  useEffect(() => {
    // Use localStorage to send a message
    const sendMessage = () => {
      localStorage.setItem("paymentStatus", "success");
      // Add purchase event
      ReactGA.event("purchase", {
        currency: sessionStorage.getItem("currency"),
        value: Number(sessionStorage.getItem("value")),
        transaction_id: sessionStorage.getItem("transaction_id"),
        items: sessionStorage.getItem("items"),
      });
      sessionStorage.clear();
      window.close();
    };

    sendMessage();
  }, []);

  return (
    <MainContainer>
      <div className="flex flex-col md:gap-20 gap-10 justify-center items-center xl:px-60 md:px-24 pt-40 pb-80 px-6 bg-custom-gradient">
        REDIRECT
      </div>
    </MainContainer>
  );
}

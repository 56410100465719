import { OddsBadge } from "../../atoms/oddsBadge/OddsBadge.component";

interface TeamProps {
  name?: string;
  image?: string;
}

interface OddsProps {
  teamLeft?: string;
  draw?: string;
  teamRight?: string;
}

interface CompetitionProps {
  name?: string;
  image?: string;
}

export interface MatchCardProps {
  className?: string;
  isLive?: boolean;
  isSelected?: boolean;
  teamLeft?: TeamProps;
  teamRight?: TeamProps;
  odds?: OddsProps;
  competition?: CompetitionProps;
  time?: string;
}

const MatchCard = ({
  className = "",
  isLive,
  isSelected,
  teamLeft,
  teamRight,
  odds,
  competition,
  time,
}: MatchCardProps) => {
  const Header = () => (
    <div className="flex flex-row justify-between opacity-60">
      <div className="flex flex-row items-center gap-2">
        {competition?.image ? (
          <img src={competition.image} alt="" />
        ) : (
          <div className="w-5 h-5 rounded-full bg-red-500" />
        )}
        {competition?.name}
      </div>
      <span>{time}</span>
    </div>
  );

  const Body = () => (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col gap-2">
        <div className="flex justify-center bg-[#2A253A] items-center h-10 w-10 rounded-full">
          {teamLeft?.image && <img src={teamLeft.image} alt="" />}
        </div>
        <span>{teamLeft?.name}</span>
      </div>
      <span className="opacity-60 flex items-center">VS</span>
      <div className="flex flex-col items-end gap-2">
        <div className="flex justify-center bg-[#2A253A] items-center h-10 w-10 rounded-full">
          {teamRight?.image && <img src={teamRight.image} alt="" />}
        </div>
        <span>{teamRight?.name}</span>
      </div>
    </div>
  );

  const Footer = () => (
    <div className="flex flex-row justify-between gap-4">
      <OddsBadge
        indicator="1"
        odd={odds?.teamLeft}
        onClick={() => alert("1")}
      />
      <OddsBadge indicator="X" odd={odds?.draw} onClick={() => alert("x")} />
      <OddsBadge
        indicator="2"
        odd={odds?.teamRight}
        onClick={() => alert("2")}
      />
    </div>
  );

  return (
    <div
      className={`flex flex-col rounded-lg relative p-6 text-white gap-6 ${
        isSelected ? "bg-[#365CA6]" : "bg-[#213967]"
      } 
            ${className}`}
    >
      {isLive && (
        <div className="absolute rounded-md p-2 right-5 -top-6 bg-red-500">
          Live Now!
        </div>
      )}
      <Header />
      <Body />
      <Footer />
    </div>
  );
};

export default MatchCard;

import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import Card from "src/components/organisms/withdrawal/Card";
import { MemorizedInput } from "src/components/organisms/withdrawal/Input";
import useCardNumber from "src/hooks/useCardNumber";
import { DEFAULT_CARD_LENGTH } from "src/utils/card";
import usePaymentService from "src/services/paymentsService";
import useNotificationsService from "src/services/notificationsService";
import { useTranslation } from "react-i18next";

export default function WithdrawalPage() {
  const { t } = useTranslation();

  const { displayCardNumber, updateCardNumber } = useCardNumber();
  const [data, setData] = useState({
    amount: 0,
    currency: "",
    playerId: "",
    providerId: "",
    walletId: "",
  });

  const handleCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
    updateCardNumber(event.target.value);
  };

  const { createWithdrawal } = usePaymentService();
  const { addErrorNotification } = useNotificationsService();

  useEffect(() => {
    const amount = sessionStorage.getItem("amount");
    const currency = sessionStorage.getItem("currency");
    const playerId = sessionStorage.getItem("playerId");
    const providerId = sessionStorage.getItem("providerId");
    const walletId = sessionStorage.getItem("walletId");

    setData({
      amount: Number(amount),
      currency: currency || "",
      playerId: playerId || "",
      providerId: providerId || "",
      walletId: walletId || "",
    });
  }, []);

  return (
    <div className="wrapper font-montserrat">
      <Formik
        initialValues={{
          cardNumber: "",
        }}
        validationSchema={Yup.object().shape({
          cardNumber: Yup.string()
            .required(" ")
            .min(DEFAULT_CARD_LENGTH, t("error_invalid-card"))
            .max(DEFAULT_CARD_LENGTH, t("error_invalid-card")),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { result } = await createWithdrawal({
              amount: data.amount,
              currency: data.currency,
              receiver: values.cardNumber,
              provider_id: data.providerId,
              wallet_id: data.walletId,
              description: "Withdrawal",
            });
            console.log("Withdrawal created: ", result);
            setSubmitting(false);
            sessionStorage.clear();
            const sendMessage = () => {
              localStorage.setItem("paymentStatus", "success");
              window.close();
            };
            sendMessage();
          } catch (error: any) {
            addErrorNotification(t("error_create-withdrawal"));
            console.error("Error creating withdrawal: ", error);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form className="card-form">
            <div className="card-list">
              <Card cardNumber={displayCardNumber} />
            </div>
            <div className="card-form__inner">
              <div className="card-input">
                <label className="card-input__label">{t("text_amount")}</label>
                <span className="text-[22px] font-bold">
                  {data.amount} {data.currency}
                </span>
              </div>
              <Field
                name="cardNumber"
                maxLength={DEFAULT_CARD_LENGTH}
                label="Card Number"
                as={MemorizedInput}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleCardNumber(e);
                  setFieldValue("cardNumber", e.target.value);
                }}
                autoComplete="off"
              />
              <button
                type="submit"
                className="card-form__button hover:opacity-80 transition duration-300"
              >
                {t("button_submit")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export const RightArrowIcon = ({ className = "fill-white" }) => {
  return (
    <>
      <svg
        width="9"
        height="18"
        viewBox="0 0 9 18"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.909912 16.9201L7.42991 10.4001C8.19991 9.63008 8.19991 8.37008 7.42991 7.60008L0.909912 1.08008"
          stroke="#2A2A2A"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default RightArrowIcon;

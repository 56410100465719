import { CustomError } from "src/errors/CustomError";
import { useAxiosInterceptors } from "src/services/apiConfig";
import { CurrencyResponse, GetCurrencyListResponse, GetBalanceListResponse, GetWalletListResponse, GetTransactionListResponse } from "src/types";

const useTransactionService = () => {
    const { axiosInstance, axiosPrivateInstance } = useAxiosInterceptors();

    // Get wallet balances by player ID
    const getBalances = async (playerId: string): Promise<GetBalanceListResponse> => {
        try {
            const response = await axiosPrivateInstance.get(`/balances/player/${playerId}`);
            return response.data;
        }  catch (error: any) {
            const { code, description } = error.response.data.error;
                throw new CustomError(code, description);
        }
    }

    // Get a list of all supported currencies
    const getCurrencies = async (): Promise<GetCurrencyListResponse> => {
        try {
            const response = await axiosInstance.get("/currencies");
            return response.data;
        } catch (error: any) {
            const { code, description } = error.response.data.error;
                throw new CustomError(code, description);
        }
    }

    // Return a currency by ID
    const getCurrencyById = async (currencyId: string): Promise<CurrencyResponse> => {
        try {
            const response = await axiosInstance.get(`/currencies/${currencyId}`);
            return response.data;
        } catch (error: any) {
            const { code, description } = error.response.data.error;
            throw new CustomError(code, description);
        }
    }

    // Return list of wallets by player ID
    const getPlayerWallets = async (playerId: string): Promise<GetWalletListResponse> => {
        try {
            const response = await axiosPrivateInstance.get(`/wallets/player/${playerId}`);
            return response.data;
        } catch (error: any) {
            const { code, description } = error.response.data.error;
            throw new CustomError(code, description);
        }
    }

    // Return a list of all transactions by player ID
    const getTransactions = async (playerId: string): Promise<GetTransactionListResponse> => {
        try {
            const response = await axiosPrivateInstance.get(`/transactions/player/${playerId}`);
            return response.data;
        } catch (error: any) {
            const { code, description } = error.response.data.error;
            throw new CustomError(code, description);
        }
    }

    return { getBalances, getCurrencies, getCurrencyById, getPlayerWallets, getTransactions };

}

export default useTransactionService;
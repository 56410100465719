import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import { ReactComponent as SuccessIcon } from "src/assets/icons/validation/Check Circle.svg";
import { ReactComponent as ValidatingIcon } from "src/assets/icons/time/Update.svg";
import { ReactComponent as FailedIcon } from "src/assets/icons/validation/Clear Circle.svg";
import { useTranslation } from "react-i18next";

export interface TableProfileInfoProps {
  name: string;
  image: string;
}

export interface TablePlayerProps extends TableProfileInfoProps {
  lastPlayed: Date;
}

export interface TableGameProps extends TableProfileInfoProps {}

export interface TableItemProps {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export interface TableProps {
  items: Array<TableItemProps>;
}

export const WalletTable = ({ items }: TableProps) => {
  const { t } = useTranslation();
  const [data /* _setData */] = useState(() => [...items]);
  //const [selectedTable, setSelectedTable] = useState<string>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<TableItemProps>();

  /* const handleSelectedTableClick = (table: string) => {
    if (selectedTable !== table) {
      setSelectedTable(table);
    }
  }; */

  const columns = [
    columnHelper.accessor("dateTime", {
      header: t("table_header_date-time"),
      cell: ({ getValue }) => (
        <div className="p-2.5 flex justify-between text-sm text-primary-400">
          <span>
            {moment(new Date(getValue())).format("DD.MM.YYYY [-] HH:mm")}
          </span>
          <span className="text-right text-primary-100/20">|</span>
        </div>
      ),
    }),
    columnHelper.accessor("method", {
      header: t("table_header_method"),
      cell: ({ getValue }) => (
        <div className="p-2.5 flex justify-between text-sm text-primary-400">
          <span>{getValue()}</span>
          <span className="text-right text-primary-100/20">|</span>
        </div>
      ),
    }),
    columnHelper.accessor("value", {
      header: t("table_header_amount"),
      cell: ({ getValue, row }) => (
        <div className="p-2.5 flex justify-between text-sm text-primary-400">
          {row.original.currency === "USD" ? "$ " : "€ "}
          {getValue()}
          <span className="text-right text-primary-100/20">|</span>
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      header: t("table_header_status"),
      cell: ({ getValue }) => (
        <div className="p-2.5 text-xs leading-[14px] text-primary-400 flex gap-1 items-center">
          {getValue() === "success" && (
            <>
              <SuccessIcon className="w-4 h-4 text-secondary-400" />
              <span className="text-secondary-400">{getValue()}</span>
            </>
          )}
          {getValue() === "validating" && (
            <>
              <ValidatingIcon className="w-4 h-4 text-tertiary-400" />
              <span className="text-tertiary-400">{getValue()}</span>
            </>
          )}
          {getValue() === "failed" && (
            <>
              <FailedIcon className="w-4 h-4 text-warning-200" />
              <span className="text-warning-200">{getValue()}</span>
            </>
          )}
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <table className="w-full border-separate border-spacing-y-1.5">
      <thead
        className="text-sm bg-greyscale-100"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <th
                key={`${header.id}${index}`}
                className={`py-2 font-medium text-left px-2.5 ${
                  (header.column.columnDef.header === "Player" ||
                    header.column.columnDef.header === "Bet" ||
                    header.column.columnDef.header === "Take") &&
                  "md:table-cell hidden"
                }`}
              >
                <div
                  onClick={header.column.getToggleSortingHandler()}
                  className="select-none cursor-pointer flex gap-2.5 justify-between items-center w-full text-primary-300"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {headerGroup.headers.length - 1 !== index && (
                    <span className="text-right">|</span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="text-sm">
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, index, array) => (
              <td
                key={cell.id}
                className={`h-full border-primary-100 ${
                  index === 0
                    ? "border border-r-0 rounded-s-md"
                    : index + 1 === array.length
                    ? "border border-l-0 !rounded-e-md"
                    : "border-b border-t"
                }`}
              >
                <div>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WalletTable;

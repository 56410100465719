import { useEffect, useState } from "react";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import InputDepositLimit, {
  textThemeEnum,
} from "src/components/molecules/inputFields/inputDepositLimit/InputDepositLimit.component";
import { ReactComponent as WarningIcon } from "src/assets/icons/validation/Warning.svg";
import { CelebrationIcon } from "src/assets/svg";
import { Button } from "src/components/atoms";
import { LoginInstead } from "src/components/molecules";
import { WalletCurrency } from "src/types";
import { useTranslation } from "react-i18next";
export interface FormRegistrationStep3Part2Props {
  values: {
    address1?: string;
    address2?: string;
    zipCode?: string;
    country?: string;
    areaCode?: string;
    phoneNumber?: string;
    dailyLimit?: string;
    weeklyLimit?: string;
    monthlyLimit?: string;
  };
  errors?: {
    dailyLimit?: string;
    weeklyLimit?: string;
    monthlyLimit?: string;
  };
  touched: {
    dailyLimit?: boolean;
    weeklyLimit?: boolean;
    monthlyLimit?: boolean;
  };
  setFieldValue: (field: string, value: string) => void;
  onClickNext: () => void;
  chosenCurrency: WalletCurrency;
}

export const FormRegistrationStep3Part2 = ({
  values,
  errors,
  touched,
  onClickNext,
  setFieldValue,
  chosenCurrency,
}: FormRegistrationStep3Part2Props) => {
  const { t } = useTranslation();
  const [openedInput, setOpenedInput] = useState<number>(0);
  const [prefix, setPrefix] = useState<string>("$");

  useEffect(() => {
    const setWalletCurrency = () => {
      if (chosenCurrency === ("EUR" as WalletCurrency)) {
        setPrefix("€");
      } else {
        setPrefix("$");
      }
    };

    setWalletCurrency();
  }, [chosenCurrency]);

  return (
    <div className="lg:w-[360px] w-[335px]">
      <div className="text-[#B9FFB7]">
        {values.dailyLimit !== "" &&
        values.weeklyLimit !== "" &&
        values.monthlyLimit !== "" ? (
          <CelebrationIcon className="w-[75px] h-[75px] ml-2.5" />
        ) : (
          <WarningIcon className="w-[75px] h-[75px] ml-2.5" />
        )}
        <span className="font-semibold text-lg">
          {t("text_deposit-limits")}
        </span>
      </div>
      <div className="mt-[15px] text-greyscale-100 text-sm">
        <span>{t("text_set-limits")}</span>
      </div>
      <div className="flex flex-col gap-[15px] mt-[34px] mb-[104px] md:mb-[94px]">
        <div className={openedInput !== 0 && openedInput !== 1 ? "hidden" : ""}>
          <InputDepositLimit
            title={t("text_daily-limit")}
            description={t("text_daily-limit-desc")}
            values={["10", "25", "100"]}
            inputValue={values.dailyLimit || ""}
            setInputValue={(value) => setFieldValue("dailyLimit", value)}
            isOpened={openedInput === 1}
            onRequestClose={() => setOpenedInput(0)}
            onRequestOpen={() => setOpenedInput(1)}
            prefix={prefix}
          />
          <span className="text-warning-100 text-sm">{errors?.dailyLimit}</span>
        </div>
        <div className={openedInput !== 0 && openedInput !== 2 ? "hidden" : ""}>
          <InputDepositLimit
            title={t("text_weekly-limit")}
            description={t("text_weekly-limit-desc")}
            values={["100", "250", "500"]}
            inputValue={values.weeklyLimit || ""}
            setInputValue={(value) => setFieldValue("weeklyLimit", value)}
            isOpened={openedInput === 2}
            onRequestClose={() => setOpenedInput(0)}
            onRequestOpen={() => setOpenedInput(2)}
            backgroundClass="bg-[#FCE762]"
            prefix={prefix}
          />
          <span className="text-warning-100 text-sm">
            {errors?.weeklyLimit}
          </span>
        </div>
        <div className={openedInput !== 0 && openedInput !== 3 ? "hidden" : ""}>
          <InputDepositLimit
            title={t("text_monthly-limit")}
            description={t("text_monthly-limit-desc")}
            values={["500", "1000", "2500"]}
            inputValue={values.monthlyLimit || ""}
            setInputValue={(value) => setFieldValue("monthlyLimit", value)}
            isOpened={openedInput === 3}
            onRequestClose={() => setOpenedInput(0)}
            onRequestOpen={() => setOpenedInput(3)}
            backgroundClass="bg-primary-200"
            textTheme={textThemeEnum.white}
            prefix={prefix}
          />
          <span className="text-warning-100 text-sm">
            {errors?.monthlyLimit}
          </span>
        </div>
      </div>
      <Button
        type="button"
        className="flex rounded justify-between w-full py-3 px-6 bg-greyscale-100 disabled:opacity-50 disabled:text-[#BDBDBD] text-primary-400 hover:text-greyscale-300 hover:bg-tertiary-100 transition duration-300 mb-6"
        onClick={() => onClickNext()}
        isDisabled={
          // values.address1 === "" ||
          // values.areaCode === "" ||
          values.country === "" ||
          values.dailyLimit === "" ||
          values.monthlyLimit === "" ||
          // values.phoneNumber === "" ||
          values.weeklyLimit === ""
          // values.zipCode === ""
        }
      >
        <div className="w-6" />
        {t("button_all-set")}!
        <ArrowRightIcon />
      </Button>
      <LoginInstead theme={textThemeEnum.white} lastStep={true} />
    </div>
  );
};

export default FormRegistrationStep3Part2;

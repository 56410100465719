import { Link } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { motion } from "framer-motion";

export interface GameListProps {
  className?: string;
  title?: JSX.Element | string;
  buttonTitle?: string;
  items?: Array<JSX.Element>;
  rows?: number;
  perRow?: number;
  gameType?: string;
}

export const GameList = ({
  className = "",
  title,
  buttonTitle,
  items,
  rows = 1,
  perRow = 5,
  gameType = "",
}: GameListProps) => {
  const Header = () => (
    <div className="flex flex-row justify-between items-center">
      <span className="text-[28px] font-bold text-greyscale-100">{title}</span>
      {gameType && (
        <Link
          to={`/all-games/${gameType}`}
          className="flex flex-row gap-2 text-[#FFD600] cursor-pointer"
        >
          <span>{buttonTitle}</span>
          <ArrowRightIcon />
        </Link>
      )}
    </div>
  );

  return (
    <div className={`w-full ${className}`}>
      <Header />
      <div className="flex gap-2 flex-wrap">
        {items?.map((item, index) => (
          <motion.div
            key={`game-card-${index}`}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7 }}
          >
            {item}
          </motion.div>
        ))}
        {/* {Array.from(Array(rows).keys()).map((row) => (
          <div key={row} className="flex gap-2 justify-between flex-wrap">
            {items?.slice(row * perRow, (row + 1) * perRow).map((item) => item)}
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default GameList;

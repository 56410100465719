import { ReactComponent as FacebookIcon } from "src/assets/icons/social/Facebook.svg";
import { ReactComponent as InstagramIcon } from "src/assets/icons/social/Instagram.svg";
import { ReactComponent as TwitterIcon } from "src/assets/icons/social/Twitter.svg";
import { Link } from "react-router-dom";
import NavLink from "src/components/atoms/navLink/NavLink.component";
import { Footer, Header } from "src/components/organisms";
import { ReactComponent as BrotherBetsLogo } from "src/assets/icons/logos/BB_logo_stacked_gold.svg";
import { EmailSubmitField } from "src/components/molecules";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

export default function UnderConstructionPage() {
  const { t } = useTranslation();

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Under Construction Page",
  });

  return (
    <div className="font-main w-full">
      <Header />
      <div className="flex flex-col md:gap-20 gap-10 justify-center items-center xl:px-52 md:px-24 md:py-40 py-20 px-6 bg-custom-gradient w-full">
        <div className="flex flex-col md:flex-row items-center gap-20">
          <BrotherBetsLogo className="flex-1" />
          <div className="flex flex-col gap-11 text-greyscale-100 flex-1">
            <h1 className="2xl:text-[74px] 2xl:leading-[80px] text-[44px] leading-[48px] font-bold text-center">
              {t("page_under-construction_text1")}
            </h1>
            <p className="md:text-2xl md:leading-10 text-center">
              {t("page_under-construction_text2")}
            </p>
            <EmailSubmitField theme="dark" buttonStyle="floating" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-8 text-greyscale-100">
          <p className="text-2xl text-center font-bold">
            {t("text_follow-us")}
          </p>
          <div className="flex gap-6 md:text-2xl text-xs items-center">
            <Link to={"/"}>
              <NavLink className="flex gap-3.5">
                <FacebookIcon className="text-greyscale-100 w-8 h-auto" />
                <p className="text-greyscale-200">Facebook</p>
              </NavLink>
            </Link>
            <Link to={"/"}>
              <NavLink className="flex gap-3.5">
                <TwitterIcon className="text-greyscale-100 w-8 h-auto" />
                <p className="text-greyscale-200">Twitter</p>
              </NavLink>
            </Link>
            <Link to={"/"}>
              <NavLink className="flex gap-3.5">
                <InstagramIcon className="text-greyscale-100 w-8 h-auto" />
                <p className="text-greyscale-200">Instagram</p>
              </NavLink>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const GameType: {
  [key: string]: { type: string; title: string; showFilter: boolean };
} = {
  casino: {
    type: "live-casino-table",
    title: "button_header_casino",
    showFilter: true,
  },
  poker: {
    type: "table-games",
    title: "button_header_poker",
    showFilter: true,
  },
  slots: {
    type: "video-slots",
    title: "button_header_slots",
    showFilter: true,
  },
  hot: { type: "hot", title: "link_hot-games", showFilter: false },
  new: { type: "new", title: "link_new-games", showFilter: false },
  favourites: {
    type: "favourites",
    title: "link_favourites",
    showFilter: false,
  },
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as HeartIcon } from "src/assets/icons/emoji/Heart/Filled.svg";
import { Button } from "src/components/atoms";
import {
  /* MultipleSelect, */
  /* MultipleSelectDropdownOptionProps, */
  SingleSelect,
  SingleSelectDropdownOptionProps,
} from "src/components/molecules";

/* const options: MultipleSelectDropdownOptionProps[] = [
  { value: "playtech", label: "Playtech" },
  {
    value: "evolution",
    label: "Evolution",
    secondaryLabel: "Exclusive games available",
  },
  { value: "pragmatic_play", label: "Pragmatic Play" },
  { value: "auth_gaming", label: "Authentic Gaming" },
  {
    value: "amusnet",
    label: "Amusnet Interactive Ltd",
    secondaryLabel: "Exclusive games available",
  },
  { value: "hacksaw", label: "Hacksaw Gaming" },
]; */

export const Filter = () => {
  const { t } = useTranslation();
  /* const [selectedOptions, setSelectedOptions] = useState<
    MultipleSelectDropdownOptionProps[]
  >([]); */
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<string[]>(
    []
  );

  const mobileOptions: SingleSelectDropdownOptionProps[] = [
    { value: "all_slots_games", label: t("option_all-slots-games") },
    { value: "most_popular", label: t("option_most-popular") },
    /* { value: "biggest_winners", label: t("option_biggest-winners") }, */
    {
      value: "recently_played",
      label: t("option_recently-played"),
    },
    { value: "my_favourites", label: t("option_my-fav") },
  ];

  const quickFilters = [
    t("option_all-slots-games"),
    t("option_most-popular"),
    /* t("option_biggest-winners"), */
    t("option_recently-played"),
    t("option_my-fav"),
  ];

  /* const handleSelectionChange = (
    selected: MultipleSelectDropdownOptionProps[]
  ) => {
    alert(
      "The filter service is still under development. Thank for your comprehension."
    );
    setSelectedOptions(selected);
  }; */

  const [selectedOption, setSelectedOption] =
    useState<SingleSelectDropdownOptionProps>(mobileOptions[0]);

  const handleChange = (option: SingleSelectDropdownOptionProps) => {
    alert(
      "The filter service is still under development. Thank for your comprehension."
    );
    setSelectedOption(option);
  };

  const handleQuickFilterClick = (filter: string) => {
    alert(
      "The filter service is still under development. Thank for your comprehension."
    );
    setSelectedQuickFilters((prevSelected) => {
      if (prevSelected.includes(filter)) {
        return prevSelected.filter((quickFilter) => quickFilter !== filter);
      } else {
        return [...prevSelected, filter];
      }
    });
  };

  return (
    <div className="flex gap-8 md:justify-normal justify-between">
      {/* <MultipleSelect
        options={options}
        selectedOptions={selectedOptions}
        onChange={handleSelectionChange}
      />
      <div className="w-[0.5px] h-[auto] bg-greyscale-100 md:block hidden" /> */}
      <div className="xl:flex hidden items-center gap-3.5">
        {quickFilters.map((filter, index) => (
          <Button
            key={filter}
            className={`flex items-center gap-2.5 px-6 py-3 border rounded-md text-sm ${
              selectedQuickFilters.includes(filter)
                ? "bg-[#FFD600] text-primary-400 border-[#FFD600]"
                : "text-greyscale-100"
            }`}
            onClick={() => handleQuickFilterClick(filter)}
          >
            {filter}
            {index === quickFilters.length - 1 && (
              <HeartIcon
                className={`${
                  selectedQuickFilters.includes(filter)
                    ? "text-primary-400"
                    : "text-greyscale-100"
                }`}
              />
            )}
          </Button>
        ))}
      </div>
      <div className="flex xl:hidden items-center gap-3.5">
        <SingleSelect
          options={mobileOptions}
          selectedOption={selectedOption}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Filter;

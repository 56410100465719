import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MoonLoader } from "react-spinners";
import { WalletTable } from "src/components/molecules";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "src/services/notificationsService";
import useTransactionService from "src/services/transactionService";

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export const WalletTables = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTable, setSelectedTable] = useState<string>("transactions");
  const [orders, setOrders] = useState<Transaction[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { playerData } = useAuth();
  const { getOrders, getTransactions } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();

  const tables = ["deposits", "transactions", "withdrawals", "wins", "wagers"];

  const handleSelectedTableClick = (table: string) => {
    if (selectedTable !== table) {
      setSelectedTable(table);
    }
  };

  useEffect(() => {
    (async () => {
      if (!playerData?.id) return;
      try {
        setLoading(true);
        const ordersResult = await getOrders(playerData.id);
        setOrders(
          ordersResult.map((order) => ({
            dateTime: new Date(order.created_at),
            method: order.type,
            value: order.amount,
            status: order.status,
            currency: order.currency_code,
          }))
        );
        const transactionsResult = await getTransactions(playerData.id);
        setTransactions(
          transactionsResult.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification(t("error_get-transactions"));
        console.error("Error getting transactions: ", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return loading ? (
    <MoonLoader
      size={48}
      color="#65236c"
      loading={loading}
      speedMultiplier={0.7}
      className="self-center"
    />
  ) : transactions.length ? (
    <div className="bg-greyscale-100">
      <div className="flex md:flex-row flex-col justify-between pb-5">
        <div className="flex items-center gap-11 border-b border-greyscale-200 md:w-full w-[calc(100vw-50px)] overflow-x-auto">
          {tables.map((table) => (
            <button
              key={table}
              className={`flex items-center leading-[22px] pb-2.5 border-b-4 ${
                selectedTable === table
                  ? "border-tertiary-400 text-tertiary-400"
                  : "border-none text-greyscale-200"
              }`}
              onClick={() => handleSelectedTableClick(table)}
            >
              {t("text_" + table)}
            </button>
          ))}
        </div>
      </div>
      {selectedTable === "transactions" ? (
        <div className="pr-2">
          <WalletTable key="1" items={orders} />
        </div>
      ) : selectedTable === "deposits" ? (
        <div className="pr-2">
          <WalletTable
            key="2"
            items={orders.filter((order) => order.method === "deposit")}
          />
        </div>
      ) : selectedTable === "withdrawals" ? (
        <div className="pr-2">
          <WalletTable
            key="3"
            items={orders.filter((order) => order.method === "withdraw")}
          />
        </div>
      ) : selectedTable === "wins" ? (
        <div className="pr-2">
          <WalletTable
            key="4"
            items={transactions.filter(
              (transaction) => transaction.method === "win"
            )}
          />
        </div>
      ) : selectedTable === "wagers" ? (
        <div className="pr-2">
          <WalletTable
            key="5"
            items={transactions.filter(
              (transaction) => transaction.method === "wager"
            )}
          />
        </div>
      ) : (
        <p>{t("text_no-transactions")}</p>
      )}
    </div>
  ) : (
    <p>{t("text_no-transactions")}</p>
  );
};

export default WalletTables;
